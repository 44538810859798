import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import classNames from 'classnames';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import moment from 'moment-timezone';
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogAction from '~/components/common/custom-dialog-actions';
import CustomInput from '~/components/common/custom-input';
import CustomDatePicker from '~/components/common/custom-date-picker';
import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';
import {
  dimGrayColor,
  romanColor,
  whiteSmokeColor,
  pattensBlueColor,
} from '~/styles/themes/common-styles/color';
import { IStore } from '~/stores/configure-store';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
import { momentDateConvert } from '~/utilities/moment-utils';
import { IAccountSummary } from '~/types/admin-types';
import * as SummaryActions from '~/stores/actions/summary-action';
import * as AccountActions from '~/stores/actions/account-action';
import SubmitButton from '~/components/common/custom-submit-button';

export type Item = {
  id: string;
  createAt: string;
  name: string;
  expiredAt: string;
  balance: number;
};

interface StateProps {}

interface DispProps {
  editCouponToAccount: (
    args: AccountActions.MutationEditCouponToAccountArgs,
  ) => Promise<AccountActions.EDIT_COUPON_TO_ACCOUNT_RESULT_TYPE>;
  listAccountCouponsSummary: (
    args: SummaryActions.QueryListAccountCouponsSummaryArgs,
  ) => Promise<SummaryActions.LIST_ACCOUNT_COUPONS_SUMMARY_RESULT_TYPE>;
}

interface Props extends StateProps, DispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
  account: IAccountSummary;
  coupon: Item;
}

interface State {}

class EditCouponDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes, open, coupon, onClose } = this.props;

    const validateSchema = Yup.object().shape<FormValues>({
      name: Yup.string(),
      expired: Yup.date()
        .required('required_field')
        .test('check-date-format', 'invalid_date_format', function (date: Date) {
          return moment(date).isValid();
        }),
    });

    const initialValues: FormValues = {
      name: coupon.name,
      expired: moment(coupon.expiredAt).utc().toDate(),
    };

    return (
      <CustomDialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={this.onSubmit}
          render={({ isValid, isSubmitting }) => (
            <Form>
              <CustomDialogTitle className={classes.dialogTitle}>
                {t('edit_coupon_dialog_title')}
              </CustomDialogTitle>
              <CustomDialogContent classes={{ root: classes.dialogContent }}>
                <div title="name-field">
                  <Field name="name" render={this.nameField} />
                </div>
                <div title="expired-field">
                  <Field name="expired" render={this.expiredField} />
                </div>
              </CustomDialogContent>
              <CustomDialogAction classes={{ root: classes.dialogAction }}>
                <Button
                  variant="outlined"
                  disabled={isSubmitting}
                  onClick={onClose}
                  className={classes.cancelBtn}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  type="submit"
                  id="confirm-edit-coupon"
                  isLoading={isSubmitting}
                  label={t('update')}
                  submittingLabel={t('updating')}
                  disabled={isSubmitting}
                />
              </CustomDialogAction>
            </Form>
          )}
        />
      </CustomDialog>
    );
  }

  private nameField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t, coupon } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('edit_coupon_dialog_name_label')}</div>
          {!!form.errors.name && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.name)}
            </div>
          )}
        </div>
        <div>
          <CustomInput {...field} disabled={true} value={coupon.name} />
        </div>
      </>
    );
  };

  private expiredField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('edit_coupon_dialog_expired_label')}</div>
          {!!form.errors.expired && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.expired as string)}
            </div>
          )}
        </div>
        <div>
          <CustomDatePicker
            value={new Date(field.value)}
            onChange={(date) => form.setFieldValue('expired', moment(date).toDate())}
            inputFormat={'YYYY/MM/DD'}
            classes={{ textField: classes.datePickerInput }}
          />
        </div>
      </>
    );
  };

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { onClose, account, coupon, editCouponToAccount, listAccountCouponsSummary } = this.props;
    const { setSubmitting } = formikActions;

    try {
      await editCouponToAccount({
        input: {
          accountUuid: account.accountUuid,
          couponId: coupon.id,
          expireDate: moment(values.expired).utc().endOf('day').toISOString(),
        },
      });

      // reload coupon list
      await listAccountCouponsSummary({ accountUuid: account.accountUuid }).catch((_) => void 0);
      onClose();
    } catch (err) {
      setSubmitting(false);
    }
  };
}

const styles = createStyles({
  dialogPaper: {
    maxWidth: 440,
  },
  dialogTitle: {},
  dialogContent: {
    paddingLeft: 80,
    paddingRight: 80,
    overflow: 'unset',
  },
  dialogAction: {
    paddingLeft: 80,
    paddingRight: 80,
    paddingBottom: 50,
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
    marginTop: 12,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  expiredDatePicker: {},
  cancelBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
    marginLeft: 0,
    width: 120,
  },
  expiredFieldDatePicker: {
    width: '100%',
  },
  expiredFieldDatePickerInput: {
    ...defaultFont,
    border: `1px solid ${pattensBlueColor}`,
    padding: '0 15px',
    borderRadius: 4,
    backgroundColor: whiteSmokeColor,
    width: '100%',
    height: 38,
    fontSize: 13,
    cursor: 'pointer',
  },
  datePickerInput: {
    '& .MuiInputBase-root': {
      width: '100%',
      height: 40,
    },
  },
});

const mapStateToProps = (store: IStore): StateProps => ({});

const mapDispatchToProps = (dispatch): DispProps => ({
  editCouponToAccount: (args: AccountActions.MutationEditCouponToAccountArgs) =>
    dispatch(AccountActions.editCouponToAccount(args)),
  listAccountCouponsSummary: (args: SummaryActions.QueryListAccountCouponsSummaryArgs) =>
    dispatch(SummaryActions.listAccountCouponsSummary(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation('admin')(EditCouponDialog)),
);

type FormValues = {
  name: string;
  expired: Date;
};
