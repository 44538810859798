import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';
import CustomDataGridToolbar from '~/components/common/custom-data-grid-toolbar';
import { IAccountNetworkSummary, IAccountSummary } from '~/types/admin-types';
import { denimColor, whiteColor } from '~/styles/themes/common-styles/color';

import { IAdminUser } from '~/types/admin-types';
import { IStore } from '~/stores/configure-store';
import { connect } from 'react-redux';
interface IStateProps {
  profile?: IAdminUser;
}

interface Props
  extends RouteComponentProps<{ accountUuid: string }>,
    WithStyles<typeof styles>,
    WithTranslation,
    IStateProps {
  isLoading: boolean;
  account: IAccountSummary;
  networks?: Array<IAccountNetworkSummary>;
  listAccountNetworks: () => void;
}

interface State {}

class NetworksTab extends React.Component<Props, State> {
  constructor(props) {
    super(props);
  }

  public async componentDidMount() {
    const { networks } = this.props;
    if (!networks) {
      this.props.listAccountNetworks();
    }
  }

  public render() {
    const { classes, t, account, networks = [], isLoading, profile, history } = this.props;

    const columns: GridColumns = [
      {
        field: DataGridFieldEnum.NETWORK_UUID,
        disableColumnMenu: true,
        filterable: true,
        sortable: false,
        headerName: t('network_uuid'),
        width: 350,
        renderCell: (params: GridCellParams) => {
          const uuid = params.row.uuid;
          return (
            <div
              className={classes.networkUuid}
              onClick={() => {
                history.push(`/networks/${uuid}/overview`);
              }}
            >
              {uuid}
            </div>
          );
        },
      },
      {
        field: DataGridFieldEnum.NAME,
        disableColumnMenu: true,
        sortable: false,
        filterable: true,
        headerName: t('network_name'),
        width: 300,
      },
      {
        field: DataGridFieldEnum.ACCOUNT,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        headerName: t('num_accounts'),
        width: 230,
      },
      {
        field: DataGridFieldEnum.CLUSTER,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        headerName: t('num_clusters'),
        width: 230,
      },
      {
        field: DataGridFieldEnum.NODE,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        headerName: t('num_nodes'),
        width: 230,
      },
    ];

    const items: NetworkItem[] = networks.map((network) => ({
      name: network.name,
      account: network.count.account,
      cluster: network.count.cluster,
      node: network.count.node,
      id: network.uuid,
      uuid: network.uuid,
    }));

    return (
      <div className={classes.dataGridWrapper}>
        <DataGrid
          isRowSelectable={() => false}
          columns={columns}
          rows={items}
          classes={{ root: classes.gridRoot }}
          components={{
            Toolbar: CustomDataGridToolbar,
          }}
          localeText={{
            toolbarColumns: t('data_grid.columns'),
            toolbarFilters: t('data_grid.filters'),
            toolbarDensity: t('data_grid.density'),
            filterPanelColumns: t('data_grid.columns'),
            filterPanelOperators: t('data_grid.operators'),
            columnsPanelTextFieldLabel: t('data_grid.find_column'),
            columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
            columnsPanelDragIconLabel: t('data_grid.reorder_column'),
            columnsPanelShowAllButton: t('data_grid.show_all'),
            columnsPanelHideAllButton: t('data_grid.hide_all'),
            toolbarDensityCompact: t('data_grid.density_compact'),
            toolbarDensityStandard: t('data_grid.density_standard'),
            toolbarDensityComfortable: t('data_grid.density_comfortable'),
            filterOperatorContains: t('data_grid.contains'),
            filterOperatorEquals: t('data_grid.equals'),
            filterOperatorStartsWith: t('data_grid.startsWith'),
            filterOperatorEndsWith: t('data_grid.endsWith'),
            filterOperatorIsEmpty: t('data_grid.isEmpty'),
            filterOperatorIsNotEmpty: t('data_grid.isNotEmpty'),
            filterOperatorIsAnyOf: t('data_grid.isAnyOf'),
            filterOperatorIs: t('data_grid.is'),
            filterOperatorNot: t('data_grid.isNot'),
            noRowsLabel: t('data_grid.no_rows'),
          }}
          loading={isLoading}
        />
      </div>
    );
  }
}

const styles = (theme) =>
  createStyles({
    root: {},
    dataGridWrapper: {
      height: 500,
      width: '100%',
      backgroundColor: whiteColor,
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(0.5),
      },
    },
    cellAction: {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
    },
    gridRoot: {
      border: 'none',
      '& .MuiDataGrid-cell': {
        whiteSpace: 'pre-wrap',
        maxHeight: 'none !important',
        outline: 'none !important',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    networkUuid: {
      cursor: 'pointer',
      color: denimColor,
    },
  });

export interface Network {
  networkUUID: string;
  networkName: string;
  listAccounts: string[];
  listClusters: Array<{
    clusterId: string;
    blockchainNodeCount: number;
    approvalStatus: number;
    endpoint: string;
    blockExplore: string;
  }>;
  numNodes: number;
}

const mapStateToProps = (store: IStore): IStateProps => ({
  profile: store.appState.profile,
});

export default withStyles(styles)(
  connect(mapStateToProps)(withTranslation('admin')(withRouter(NetworksTab))),
);

export type NetworkItem = {
  id: string;
  uuid: string;
  name: string;
  account: number;
  cluster: number;
  node: number;
};

enum DataGridFieldEnum {
  NAME = 'name',
  NETWORK_UUID = 'uuid',
  ACCOUNT = 'account',
  CLUSTER = 'cluster',
  ACTION = 'action',
  NODE = 'node',
}
