import { createTypeReducer } from '@gu-corp/redux-async-lib';
import {
  ISalesWithCond,
  IAdminUser,
  IMemberUser,
  IAdminProfile,
  INetworkSummary,
  IAccountLicenseSummary,
  ICouponSummary,
  IAccountSummaryList,
  IAWSAccountList,
  IAdminAlertList,
  ICountStats,
  IAccountAlertList,
  ISummaryAdmin,
  AllCouponSummary,
  IAdminAlert,
} from '~/types/admin-types';
import * as AppActions from '~/stores/actions/app-action';
import * as AccountActions from '~/stores/actions/account-action';
import * as AwsAccountActions from '~/stores/actions/aws-account-action';
import * as ProfileActions from '~/stores/actions/profile-action';
import * as NetworkActions from '~/stores/actions/network-action';
import * as SummaryActions from '~/stores/actions/summary-action';
import * as AlertActions from '~/stores/actions/alert-action';
import * as StatsActions from '~/stores/actions/stats-action';
import * as UserActions from '~/stores/actions/user-action';
import { ISnackBarState } from '~/types/app-types';
import {
  ACCOUNTS_PAGE_INDEX_DEFAULT,
  ACCOUNTS_PAGE_SIZE_DEFAULT,
  ACCOUNTS_ORDER_BY_DEFAULT,
  getNotificationsStartDateDefault,
  getNotificationsEndDateDefault,
  NOTIFICATIONS_PAGE_INDEX_DEFAULT,
  NOTIFICATIONS_PAGE_SIZE_DEFAULT,
  NOTIFICATIONS_ORDER_DEFAULT,
  AWS_ACCOUNTS_FILTER,
  ADMIN_NOTIFICATIONS_PAGE_INDEX_DEFAULT,
  ADMIN_NOTIFICATIONS_PAGE_SIZE_DEFAULT,
  getAdminNotificationsStartDateDefault,
  getAdminNotificationsEndDateDefault,
  ACCOUNT_STATS_YEAR_DEFAULT,
  USER_STATS_YEAR_DEFAULT,
  ADMIN_NOTIFICATIONS_FOR_ADMIN,
  ADMIN_NOTIFICATIONS_FOR_USER,
  PROPOSALS_PAGE_INDEX_DEFAULT,
  PROPOSALS_PAGE_SIZE_DEFAULT,
  USERS_PAGE_INDEX_DEFAULT,
  USERS_PAGE_SIZE_DEFAULT,
  USERS_ORDER_BY_DEFAULT,
  NETWORKS_PAGE_INDEX_DEFAULT,
  NETWORKS_PAGE_SIZE_DEFAULT,
  NETWORKS_ORDER_BY_DEFAULT,
} from '~/constants/store';
import { INetworkList, ListProposalHistory } from '~/types/network-types';
import { IUserList } from '~/types/user-types';
import { SummaryStats } from '~/gapi/gtypes';

export type IState = {
  profile?: IAdminProfile;
  administrators: IAdminUser[];
  accountList: IAccountSummaryList;
  awsAccountList: IAWSAccountList;
  sales: ISalesWithCond;
  networkSummary?: INetworkSummary;
  licenseSummary: IAccountLicenseSummary;
  adminSummaryList: ISummaryAdmin;
  couponList: AllCouponSummary;
  snackBarState: ISnackBarState;
  coupons: ICouponSummary;
  memberUsers: IMemberUser[];
  accountAlertList: IAccountAlertList;
  adminNotificationList: IAdminAlertList;
  userNotificationList: IAdminAlertList;
  accountStats: ICountStats;
  userStats: ICountStats;
  summaryStats?: SummaryStats;
  adminNotificationStats?: Array<IAdminAlert>;
  userNotificationStats?: Array<IAdminAlert>;
  listProposalHistory: ListProposalHistory;
  userList: IUserList;
  networkList: INetworkList;
};

export const initialState: IState = {
  profile: undefined,
  administrators: [],
  memberUsers: [],
  accountList: {
    pageInfo: {
      pageIndex: ACCOUNTS_PAGE_INDEX_DEFAULT,
      pageSize: ACCOUNTS_PAGE_SIZE_DEFAULT,
    },
    order: ACCOUNTS_ORDER_BY_DEFAULT,
  },
  networkList: {
    pageInfo: {
      pageIndex: NETWORKS_PAGE_INDEX_DEFAULT,
      pageSize: NETWORKS_PAGE_SIZE_DEFAULT,
    },
    order: NETWORKS_ORDER_BY_DEFAULT,
  },
  userList: {
    pageInfo: {
      pageIndex: USERS_PAGE_INDEX_DEFAULT,
      pageSize: USERS_PAGE_SIZE_DEFAULT,
    },
    order: USERS_ORDER_BY_DEFAULT,
  },
  awsAccountList: {
    filter: AWS_ACCOUNTS_FILTER,
    awsAccounts: undefined,
  },
  sales: {
    summary: {
      totalPrice: 0,
      totalTax: 0,
      totalCoupon: 0,
      licenses: [],
    },
    condition: {
      startDate: '',
      endDate: '',
    },
  },
  networkSummary: void 0,
  licenseSummary: {
    active: void 0,
    history: void 0,
  },
  adminSummaryList: {
    startPeriod: '',
    endPeriod: '',
    details: [],
  },
  couponList: {
    startPeriod: '',
    endPeriod: '',
    details: [],
  },
  snackBarState: {
    type: 'info',
    open: false,
    message: '',
  },
  coupons: {
    startPeriod: '',
    endPeriod: '',
    details: [],
  },
  accountAlertList: {
    pageInfo: {
      pageIndex: NOTIFICATIONS_PAGE_INDEX_DEFAULT,
      pageSize: NOTIFICATIONS_PAGE_SIZE_DEFAULT,
    },
    filter: {
      startDate: getNotificationsStartDateDefault(),
      endDate: getNotificationsEndDateDefault(),
    },
    order: NOTIFICATIONS_ORDER_DEFAULT,
  },
  adminNotificationList: {
    pageInfo: {
      pageIndex: ADMIN_NOTIFICATIONS_PAGE_INDEX_DEFAULT,
      pageSize: ADMIN_NOTIFICATIONS_PAGE_SIZE_DEFAULT,
    },
    filter: {
      forAll: [],
      forAdmin: ADMIN_NOTIFICATIONS_FOR_ADMIN,
      forUser: [],
      startDate: getAdminNotificationsStartDateDefault(),
      endDate: getAdminNotificationsEndDateDefault(),
    },
  },
  userNotificationList: {
    pageInfo: {
      pageIndex: ADMIN_NOTIFICATIONS_PAGE_INDEX_DEFAULT,
      pageSize: ADMIN_NOTIFICATIONS_PAGE_SIZE_DEFAULT,
    },
    filter: {
      forAll: [],
      forAdmin: [],
      forUser: ADMIN_NOTIFICATIONS_FOR_USER,
      startDate: getAdminNotificationsStartDateDefault(),
      endDate: getAdminNotificationsEndDateDefault(),
    },
  },
  accountStats: {
    year: ACCOUNT_STATS_YEAR_DEFAULT,
  },
  userStats: {
    year: USER_STATS_YEAR_DEFAULT,
  },
  listProposalHistory: {
    pageInfo: {
      pageIndex: PROPOSALS_PAGE_INDEX_DEFAULT,
      pageSize: PROPOSALS_PAGE_SIZE_DEFAULT,
    },
    filter: {},
  },
};

const getSnackbarMessage = (state: IState, error?: Error, defaultValue?: string) =>
  error
    ? (error as any).localizedMessages
      ? (error as any).localizedMessages[state.profile?.preference.language || 'en']
      : error.message
    : defaultValue;

export const closeSnackBarReducer = AppActions.closeSnackBar.reducer<IState>((state, action) => ({
  snackBarState: {
    ...state.snackBarState,
    open: false,
  },
}));

export const openSnackBarReducer = AppActions.openSnackBar.reducer<IState>((state, action) => ({
  snackBarState: {
    ...action.payload,
    open: true,
  },
}));

export const listAdminUsersReducer = AccountActions.listAdminUsers.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not get administrator list'),
        },
      };
    }
    return {
      administrators: action.payload.listAdminUsers,
    };
  },
);

export const listMemberUsersReducer = AccountActions.listMemberUsers.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not get list member users'),
        },
      };
    }
    return {
      memberUsers: action.payload.listMemberUsers,
    };
  },
);

export const listAccountsReducer = AccountActions.listAccounts.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        type: 'error',
        open: true,
        message: getSnackbarMessage(state, action.payload, 'Can not get account list'),
      },
    };
  }
  return {
    accountList: action.payload.listAccounts,
  };
});

export const listAccountMembersReducer = AccountActions.listAccountMembers.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not get account member list'),
        },
      };
    }

    return {
      accountList: {
        ...state.accountList,
        accounts:
          state.accountList.accounts &&
          state.accountList.accounts.map((account) =>
            account.accountUuid === action.meta.accountUuid
              ? {
                  ...account,
                  members: action.payload.listAccountMembers,
                }
              : account,
          ),
      },
    };
  },
);

export const listAccountNetworksReducer = AccountActions.listAccountNetworks.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not get account network list'),
        },
      };
    }
    return {
      accountList: {
        ...state.accountList,
        accounts:
          state.accountList.accounts &&
          state.accountList.accounts.map((account) =>
            account.accountUuid === action.meta.accountUuid
              ? {
                  ...account,
                  networks: action.payload.listAccountNetworks,
                }
              : account,
          ),
      },
    };
  },
);

export const listSalesSummaryReducer = SummaryActions.listSalesSummary.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not get sales summary'),
        },
      };
    }
    return {
      sales: {
        summary: action.payload.listSalesSummary,
        condition: {
          startDate: action.meta.startDate || '',
          endDate: action.meta.endDate || '',
        },
      },
    };
  },
);

export const getNetworkSummaryReducer = SummaryActions.getNetworkSummary.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not get network summary'),
        },
      };
    }
    return {
      networkSummary: action.payload.getNetworkSummary,
    };
  },
);

export const getAdminProfileReducer = ProfileActions.getAdminProfile.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not get profile'),
        },
      };
    }
    return {
      profile: action.payload.getAdminProfile,
    };
  },
);

export const updateAdminProfileReducer = ProfileActions.updateAdminProfile.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not update profile'),
        },
      };
    }
    return {
      profile: state.profile && {
        ...state.profile,
        ...action.payload.updateAdminProfile,
      },
    };
  },
);

export const createAdminReducer = AccountActions.createAdminUser.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not create administrator'),
        },
      };
    }
    const { administrators } = state;
    administrators.push(action.payload.createAdminUser);

    return {
      administrators: administrators,
      snackBarState: {
        type: 'success',
        open: true,
        message: 'admin_is_created_successfully',
      },
    };
  },
);

export const updateAdminReducer = AccountActions.updateAdminUser.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not update administrator'),
        },
      };
    }

    return {
      snackBarState: {
        type: 'success',
        open: true,
        message: 'admin_is_updated_successfully',
      },
    };
  },
);

export const deleteAdminReducer = AccountActions.deleteAdminUser.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not delete administrator'),
        },
      };
    }

    if (!action.payload.deleteAdminUser) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: 'Can not delete administrator',
        },
      };
    }

    return {
      administrators: state.administrators.filter(
        (admin) => admin.adminUuid !== action.meta.adminUuid,
      ),
      snackBarState: {
        type: 'success',
        open: true,
        message: 'admin_is_deleted_successfully',
      },
    };
  },
);

export const listAccountLicensesSummaryReducer =
  SummaryActions.listAccountLicensesSummary.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(
            state,
            action.payload,
            'Can not list active licenses summary',
          ),
        },
      };
    }
    return {
      licenseSummary: {
        ...state.licenseSummary,
        active: action.payload.listAccountLicensesSummary,
      },
    };
  });

export const listAccountBillingsSummaryReducer =
  SummaryActions.listAccountBillingsSummary.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'Can not list license usage summary'),
        },
      };
    }
    return {
      licenseSummary: {
        ...state.licenseSummary,
        history: action.payload.listAccountBillingsSummary,
      },
    };
  });

export const listAdminSummaryReducer = SummaryActions.listAdminSummary.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'Can not list license usage summary'),
        },
      };
    }

    return {
      adminSummaryList: action.payload.listAdminSummary,
    };
  },
);

export const listAllCouponsSummaryReducer = SummaryActions.listAllCouponsSummary.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'Can not list coupon summary'),
        },
      };
    }

    return {
      couponList: action.payload.listAllCouponsSummary,
    };
  },
);

export const listAccountCouponsSummaryReducer =
  SummaryActions.listAccountCouponsSummary.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'Can not list coupons'),
        },
      };
    }
    return {
      coupons: action.payload.listAccountCouponsSummary,
    };
  });

export const listAWSAccountsReducer = AwsAccountActions.listAWSAccounts.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not get AWS account list'),
        },
      };
    }

    return {
      awsAccountList: {
        filter: action.meta.filter || state.awsAccountList.filter,
        awsAccounts: action.payload.listAWSAccounts,
      },
    };
  },
);

export const removeAWSAccountReducer = AwsAccountActions.removeAWSAccountAction.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not remove AWS account'),
        },
      };
    }

    if (!action.payload.removeAWSAccount) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: 'Can not remove AWS account',
        },
      };
    }

    return {
      snackBarState: {
        type: 'success',
        open: true,
        message: 'Remove AWS account successfully',
      },
    };
  },
);

export const changeAccountPlanReducer = AccountActions.changeAccountPlan.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not change account plan'),
        },
      };
    }

    return {
      accountList: {
        ...state.accountList,
        accounts:
          state.accountList.accounts &&
          state.accountList.accounts.map((account) =>
            account.accountUuid === action.payload.changeAccountPlan.accountUuid
              ? {
                  ...account,
                  ...action.payload.changeAccountPlan,
                }
              : account,
          ),
      },
    };
  },
);

export const removeEnterpriseAccountReducer =
  AccountActions.removeEnterpriseAccount.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not delete enterprise account'),
        },
      };
    }

    return {};
  });

export const addCouponToAccountReducer = AccountActions.addCouponToAccount.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not add coupon to account'),
        },
      };
    }
    return {};
  },
);

export const editCouponToAccountReducer = AccountActions.editCouponToAccount.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not update coupon to account'),
        },
      };
    }
    return {
      snackBarState: {
        type: 'success',
        open: true,
        message: 'coupon-updated-successfully',
      },
    };
  },
);

export const destroyNetworkReducer = NetworkActions.destroyNetwork.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_delete_network'),
        },
      };
    }
    if (!action.payload.destroyNetwork) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: 'network_not_found_or_denied_to_delete',
        },
      };
    }

    // find target network
    const { networkUuid } = action.meta.input;
    const { networkSummary } = state;

    const result: any = {
      snackBarState: {
        open: true,
        type: 'success',
        message: 'delete_network_started',
      },
    };

    if (networkSummary && networkSummary.network.networkUuid === networkUuid) {
      // update status
      networkSummary.network.clusters.forEach((cluster) => {
        if (cluster.explorer) {
          cluster.explorer.serverInfo.status = 'removing';
        }
        cluster.status = 'removing';
        cluster.nodes.forEach((node) => {
          node.nodeInfo.status = 'removing';
          node.serverInfo.status = 'removing';
        });
      });

      result.networkSummary = networkSummary;
    }

    return result;
  },
);

export const deleteClusterReducer = NetworkActions.deleteCluster.reducer<IState>(
  (state, action) => {
    const { clusterUuid } = action.meta.input;

    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_delete_cluster'),
        },
      };
    }

    switch (action.payload.deleteCluster) {
      case 'failed':
        return {
          snackBarState: {
            open: true,
            type: 'error',
            message: 'cluster_not_found_or_not_empty',
          },
        };

      case 'stopping':
      case 'success':
        return {
          snackBarState: {
            open: true,
            type: 'success',
            message: 'delete_cluster_started',
          },
          networkSummary: state.networkSummary && {
            ...state.networkSummary,
            network: {
              ...state.networkSummary.network,
              clusters: state.networkSummary.network.clusters.map((cluster) =>
                cluster.clusterUuid === clusterUuid
                  ? {
                      ...cluster,
                      status: 'removing',
                    }
                  : cluster,
              ),
            },
          },
        };

      default:
        return {};
    }
  },
);

export const listAccountAlertsReducer = AlertActions.listAccountAlerts.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'can_not_list_alerts'),
        },
      };
    }
    return {
      accountAlertList: action.payload.listAccountAlerts,
    };
  },
);

export const listAdminNotificationsReducer = AlertActions.listAdminNotifications.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'can_not_list_alerts'),
        },
      };
    }
    return {
      adminNotificationList: action.payload.listAdminAlerts,
    };
  },
);

export const listUserNotificationsReducer = AlertActions.listUserNotifications.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'can_not_list_alerts'),
        },
      };
    }
    return {
      userNotificationList: action.payload.listAdminAlerts,
    };
  },
);

export const getAccountStatsReducer = StatsActions.getAccountStats.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {};
    }
    return {
      accountStats: {
        year: action.meta,
        stats: action.payload.getAccountStats,
      },
    };
  },
);

export const getUserStatsReducer = StatsActions.getUserStats.reducer<IState>((state, action) => {
  if (action.error) {
    return {};
  }
  return {
    userStats: {
      year: action.meta,
      stats: action.payload.getUserStats,
    },
  };
});

export const deleteNodeReducer = NetworkActions.deleteNode.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_delete_node'),
      },
    };
  }
  if (!action.payload.deleteNode) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: 'node_not_found_or_validator',
      },
    };
  }

  const { clusterUuid, nodeUuid } = action.meta.input;
  const networkSummary = state.networkSummary ? { ...state.networkSummary } : undefined;

  const cluster = networkSummary?.network?.clusters.find(
    (cluster) => cluster.clusterUuid === clusterUuid,
  );
  if (!cluster) {
    return {};
  }
  const node = cluster.nodes.find((node) => node.nodeUuid === nodeUuid);
  if (node) {
    node.nodeInfo.status = 'removing';
    node.serverInfo.status = 'removing';
  }

  return {
    networkSummary,
    snackBarState: {
      open: true,
      type: 'success',
      message: 'delete_node_started',
    },
  };
});

export const listProposalHistoryReducer = NetworkActions.listProposalHistory.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_list_proposals'),
        },
      };
    }
    return {
      listProposalHistory: action.payload.listProposalHistory,
    };
  },
);

export const controlNodeReducer = NetworkActions.controlNode.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        open: true,
        type: 'error',
        message: getSnackbarMessage(state, action.payload, 'can_not_control_node'),
      },
    };
  }

  const { action: command, networkUuid, clusterUuid, nodeUuids } = action.meta.input;
  let display: string = 'control';

  switch (command) {
    case 'reboot':
    case 'rebuild':
      display = command;
      break;
  }

  const networkSummary = state.networkSummary ? { ...state.networkSummary } : undefined;

  if (networkSummary?.network.networkUuid != networkUuid) {
    return {
      snackBarState: {
        open: true,
        type: 'success',
        message: `${display}_command_sent`,
      },
    };
  }

  const cluster = networkSummary?.network?.clusters.find(
    (cluster) => cluster.clusterUuid === clusterUuid,
  );
  if (!cluster) {
    return {};
  }
  nodeUuids.forEach((nodeUuid) => {
    const node = cluster.nodes.find((node) => node.nodeUuid === nodeUuid);
    if (node) {
      node.nodeInfo.status = 'pending';
      node.serverInfo.status = 'pending';
    }
  });

  return {
    networkSummary,
    snackBarState: {
      open: true,
      type: 'success',
      message: `${display}_command_sent`,
    },
  };
});

export const updateNodeVersionReducer = NetworkActions.updateNodeVersion.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_update_node_version'),
        },
      };
    }
    if (!action.payload.updateNodeVersion) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: 'update_node_version_failed',
        },
      };
    }
    const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
    const networkSummary = state.networkSummary ? { ...state.networkSummary } : undefined;
    if (networkSummary?.network.networkUuid != networkUuid) {
      return {
        snackBarState: {
          open: true,
          type: 'success',
          message: 'update_node_version_started',
        },
      };
    }
    const cluster = networkSummary?.network?.clusters.find(
      (cluster) => cluster.clusterUuid === clusterUuid,
    );
    if (!cluster) {
      return {};
    }
    const node = cluster.nodes.find((node) => node.nodeUuid === nodeUuid);
    if (node) {
      node.nodeInfo.status = 'pending';
    }

    return {
      networkSummary,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'update_node_version_started',
      },
    };
  },
);

export const updateBlockGasLimitToNodeReducer = NetworkActions.updateBlockGasLimit.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_update_block_gas_limit'),
        },
      };
    }

    const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
    const networkSummary = state.networkSummary ? { ...state.networkSummary } : undefined;
    if (networkSummary?.network.networkUuid != networkUuid) {
      return {
        snackBarState: {
          open: true,
          type: 'success',
          message: 'update_gas_limit_start',
        },
      };
    }
    const cluster = networkSummary?.network?.clusters.find(
      (cluster) => cluster.clusterUuid === clusterUuid,
    );
    if (!cluster) {
      return {};
    }
    const node = cluster.nodes.find((node) => node.nodeUuid === nodeUuid);
    if (node) {
      node.nodeInfo.status = 'pending';
    }

    return {
      networkSummary,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'update_gas_limit_start',
      },
    };
  },
);

export const updateLatestHardForkReducer = NetworkActions.applyHardForkToNode.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_update_latest_hard_fork'),
        },
      };
    }

    const { networkUuid, clusterUuid, nodeUuid } = action.meta.input;
    const networkSummary = state.networkSummary ? { ...state.networkSummary } : undefined;
    if (networkSummary?.network.networkUuid != networkUuid) {
      return {
        snackBarState: {
          open: true,
          type: 'success',
          message: 'update_latest_hard_fork_start',
        },
      };
    }
    const cluster = networkSummary?.network?.clusters.find(
      (cluster) => cluster.clusterUuid === clusterUuid,
    );
    if (!cluster) {
      return {};
    }
    const node = cluster.nodes.find((node) => node.nodeUuid === nodeUuid);
    if (node) {
      node.nodeInfo.status = 'pending';
    }

    return {
      networkSummary,
      snackBarState: {
        open: true,
        type: 'success',
        message: 'update_latest_hard_fork_start',
      },
    };
  },
);

export const getGenesisJsonReducer = NetworkActions.getGenesisJson.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_get_genesis_json'),
        },
      };
    }
    return {};
  },
);

export const getSummaryStatsReducer = StatsActions.getSummaryStats.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(state, action.payload, 'Can not get summary stats'),
        },
      };
    }
    return {
      summaryStats: action.payload.getSummaryStats,
    };
  },
);

export const listAdminNotificationStatsReducer =
  AlertActions.listAdminNotificationStats.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(
            state,
            action.payload,
            'Can not get admin notifications stats',
          ),
        },
      };
    }
    return {
      adminNotificationStats: action.payload.listAdminAlerts.alerts,
    };
  });

export const listUserNotificationStatsReducer =
  AlertActions.listUserNotificationStats.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          type: 'error',
          open: true,
          message: getSnackbarMessage(
            state,
            action.payload,
            'Can not get user notifications stats',
          ),
        },
      };
    }
    return {
      userNotificationStats: action.payload.listAdminAlerts.alerts,
    };
  });

export const listUsersReducer = UserActions.listUsers.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        type: 'error',
        open: true,
        message: getSnackbarMessage(state, action.payload, 'Can not get users list'),
      },
    };
  }
  return {
    userList: action.payload.listUsers,
  };
});

export const listNetworksReducer = NetworkActions.listNetworks.reducer<IState>((state, action) => {
  if (action.error) {
    return {
      snackBarState: {
        type: 'error',
        open: true,
        message: getSnackbarMessage(state, action.payload, 'Can not get network list'),
      },
    };
  }
  return {
    networkList: action.payload.listNetworks,
  };
});

export const addAccountMemberReducer = AccountActions.addAccountMember.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_add_member'),
        },
      };
    }

    return {
      snackBarState: {
        open: true,
        type: 'success',
        message: 'add_member_success',
      },
    };
  },
);

export const removeAccountMemberReducer = AccountActions.removeAccountMember.reducer<IState>(
  (state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_remove_member'),
        },
      };
    }

    return {
      snackBarState: {
        open: true,
        type: 'success',
        message: 'remove_member_success',
      },
    };
  },
);

export const changeAccountPrimaryOwnerMutationReducer =
  AccountActions.changeAccountPrimaryOwner.reducer<IState>((state, action) => {
    if (action.error) {
      return {
        snackBarState: {
          open: true,
          type: 'error',
          message: getSnackbarMessage(state, action.payload, 'can_not_change_primary_owner'),
        },
      };
    }

    return {
      snackBarState: {
        open: true,
        type: 'success',
        message: 'change_primary_owner_success',
      },
    };
  });

export const reducer = createTypeReducer(
  initialState,
  openSnackBarReducer,
  closeSnackBarReducer,
  listAdminUsersReducer,
  listMemberUsersReducer,
  listAccountsReducer,
  listAccountMembersReducer,
  listAccountNetworksReducer,
  listSalesSummaryReducer,
  getNetworkSummaryReducer,
  getAdminProfileReducer,
  updateAdminProfileReducer,
  createAdminReducer,
  deleteAdminReducer,
  listAccountLicensesSummaryReducer,
  listAccountBillingsSummaryReducer,
  listAdminSummaryReducer,
  listAllCouponsSummaryReducer,
  listAccountCouponsSummaryReducer,
  listAWSAccountsReducer,
  removeAWSAccountReducer,
  changeAccountPlanReducer,
  removeEnterpriseAccountReducer,
  addCouponToAccountReducer,
  editCouponToAccountReducer,
  destroyNetworkReducer,
  deleteClusterReducer,
  listAccountAlertsReducer,
  listAdminNotificationsReducer,
  listUserNotificationsReducer,
  getAccountStatsReducer,
  getUserStatsReducer,
  deleteNodeReducer,
  listProposalHistoryReducer,
  updateAdminReducer,
  controlNodeReducer,
  updateNodeVersionReducer,
  updateBlockGasLimitToNodeReducer,
  updateLatestHardForkReducer,
  getGenesisJsonReducer,
  listUsersReducer,
  listNetworksReducer,
  getSummaryStatsReducer,
  listAdminNotificationStatsReducer,
  listUserNotificationStatsReducer,
  addAccountMemberReducer,
  removeAccountMemberReducer,
  changeAccountPrimaryOwnerMutationReducer,
);
