export default {
  english: 'English',
  japanese: '日本語',
  an_error_occured: 'An error occured',
  dashboard: 'Dashboard',
  accounts: 'Accounts',
  sales: 'Sales',
  notifications: 'Notifications',
  contact: 'Contact',
  admin_users: 'Admin Users',
  notification: 'Notification',
  accounts_search_placeholder: 'Search by Account UUID, AWS Account ID, DisplayName, ContactEmail',
  search_placeholder: 'Search by Account UUID, AWS Account ID, DisplayName or ContactEmail',
  search_account_member_placeholder: 'Search by DisplayName, ContactEmail',
  search_aws_account_placeholder: 'Search by AWS Account, AWS Account ID, DisplayName, UUID',
  search_node_placeholder: 'Search by ClusterName, NodeName',
  search_header_input_placeholder: 'Search transactions, invoices or help',
  granted_date: 'Granted Date',
  granted_date_value: '{{date, YYYY/MM/DD}}',
  coupon_name: 'Coupon Name',
  expired_date: 'Expired Date',
  expired_date_value: '{{date, YYYY/MM/DD}}',
  coupon_balance: 'Coupon Balance',
  account_UUID: 'Account UUID',
  display_name: 'Display Name',
  contact_email: 'Contact Email',
  monthly_license_fee: 'Monthly License Fee',
  balance: 'Balance',
  create_date: 'Create Date',
  create_date_value: '{{date, YYYY.MM.DD}}',
  last_login: 'Last Login',
  last_login_value: '{{date, YYYY.MM.DD}}',
  accountant_Email: 'Accountant Email',
  system_admin_Email: 'System Administrator Email',
  no_set: 'No setting',
  show_more: 'Show More',
  lisense_type: 'License Type',
  size: 'Size',
  price: 'Price',
  count_quanlity: 'Count (Quantity)',
  sub_total: 'Sub Total',
  current_display: 'Current Display',
  total_sales: 'Total Sales',
  to: 'to',
  type: 'Type',
  title: 'Title',
  date: 'Date',
  date_hour_value: '{{date, MM/DD/YYYY - HH:mm}}',
  email: 'Email',
  user_name: 'User name',
  created: 'Created',
  add_user: 'Add User',
  add_aws: 'Add AWS',
  email_address: 'Email Address',
  are_you_sure_you_want_to_delete_this_user: 'Are you sure you want to delete this user?',
  create: 'Create',
  creating: 'Creating',
  delete: 'Delete',
  deleting: 'Deleting',
  delete_user: 'Delete User',
  detail: 'Detail',
  info: 'Info',
  members: 'Members',
  networks: 'Networks',
  coupons: 'Coupons',
  lock_account: 'LockAccount',
  download_active_lisense_list: 'Download Active License List',
  download_lisense_history: 'Download License History',
  download: 'Download',
  open_active_lisense_list: 'Open Active License List',
  open_lisense_history: 'Open Usage History',
  open: 'Open',
  true: 'TRUE',
  false: 'FALSE',
  date_value: '{{date, MM/DD/YYYY}}',
  start_date: 'Start date',
  end_date: 'End date',
  date_format: 'YYYY/MM/DD',
  member_uuid: 'Member UUID',
  role: 'Role',
  network_uuid: 'Network UUID',
  network_id: 'Network Id',
  network_name: 'Network Name',
  num_accounts: 'Num Accounts',
  num_clusters: 'Num Clusters',
  num_nodes: 'Num Nodes',
  overview: 'Overview',
  proposal: 'Proposal',
  num_of_cluster: 'Number of Cluster',
  num_of_nodes: 'Number of Nodes',
  cluster_id: 'Cluster Id',
  blockchain_node_count: 'Blockchain Node Count',
  approval_status: 'Approval status',
  endpoint: 'Endpoint',
  block_explore: 'Block Explorer',
  node: 'Node',
  nodes: 'Nodes',
  cluster: 'Cluster',
  node_name: 'Node Name',
  blockchain_id: 'Blockchain ID',
  node_type: 'Node type',
  instance_id: 'Instance ID',
  states: 'States',
  proposal_type: 'Proposal Type',
  proposed_date: 'Proposed Date',
  approved_nodes: 'Approved Nodes',
  rejected_nodes: 'Rejected Nodes',
  num_of_singles: 'Num of Signers',
  alert_createAt: '{{date, MM/DD/YYYY - HH:mm:ss}}',
  user_createAt: '{{date, MM/DD/YYYY - HH:mm:ss}}',
  account_count: 'Account count',
  monthly: 'Monthly',
  last_year: 'Last Year',
  user_count: 'User count',
  total: 'Total',
  tax: 'Tax',
  log: 'Log',
  run: 'Run',
  running: 'Running',
  command: 'Command',
  download_the_full_log: 'Download the full log',
  infomation: 'Information',
  guest: 'Guest',
  member: 'Member',
  information_guest: 'Information - Guest',
  information_member: 'Information - Member',
  create_info: 'Create Information',
  create_member_information: 'Create member information',
  create_guest_information: 'Create guest information',
  importance: 'Importance',
  normal: 'Normal',
  important: 'Important',
  link_url: 'Link URL',
  text: 'Text',
  link: 'Link',
  cancel: 'Cancel',
  aws_accounts: 'AWS Accounts',
  aws_account: 'AWS Account',
  aws_account_id: 'AWS Account ID',
  uuid: 'UUID',
  users: 'Users',
  plan: 'Plan',
  are_you_sure_you_want_to_add_aws_account: 'Are you sure you want to add AWS account ?',
  license: 'License',
  history: 'History',
  add_coupon: 'Add Coupon',
  edit: 'Edit',
  change_account_type: 'Change Account Type',
  remove_aws: 'Remove AWS account',
  remove: 'Remove',
  are_you_sure_you_want_to_remove_aws_account: 'Are you sure you want to remove this AWS account ?',
  enterprise: 'Enterprise',
  change: 'Change',
  changing: 'Changing',
  attention: 'Attention',
  change_account_plan_dialog_content: 'May I switch to {{plan}}?',
  aws_account_not_exist: 'AWS account not exist',
  aws_account_not_available: 'There is no available AWS account',
  aws_account_creation_failed: 'AWS account creation failed',
  billing_data_corrupted: 'Billing data corrupted',
  you_cant_change_the_plan_the_account_select_same_plan:
    'You are using enterprise plan or select same plan as current one',
  you_cant_change_the_plan_the_account_still_has_networks: `You can't change the plan. The account still has networks`,
  you_cant_change_the_plan_the_account_still_has_licenses: `You can't change the plan. The account still has valid licenses`,
  you_cant_change_the_plan_the_account_has_unpaid_bill: `You can't change the plan. The account has unpaid bill`,
  add_coupon_dialog_title: 'Add Coupon',
  edit_coupon_dialog_title: 'Edit Coupon',
  add_coupon_dialog_name_label: 'Coupon Name',
  edit_coupon_dialog_name_label: 'Coupon Name',
  add_coupon_dialog_name_place_holder: 'Initial Registration Campaign',
  add_coupon_dialog_balace_label: 'Coupon balance (In USD)',
  add_coupon_dialog_balace_place_holder: 'Coupon Balance',
  add_coupon_dialog_expired_label: 'Expiry Date',
  edit_coupon_dialog_expired_label: 'Expiry Date',
  adding: 'Adding',
  amount_must_be_greater_than_zero: 'Amount must be greater than 0',
  invalid_date_format: 'Invalid date format',
  validate_minimum: 'Input {{ val }} or greater',
  validate_maximum: 'Input {{ val }} or less',
  trailing_spaces_not_allowed_to_name: 'Trailing spaces not allowed to name',
  required_field: 'This field is required',
  amount_of_coupon_used: 'Amount of coupon used',
  num_servers: 'Num servers',
  status: 'Status',
  cluster_going_to_stop: 'Failed to change Signers of cluster to tx node. Going to stop instances',
  delete_soft: 'Delete (Soft)',
  delete_hard: 'Delete (Hard)',
  delete_hard_cluster_title: 'Delete Cluster (Hard)',
  delete_soft_cluster_title: 'Delete Cluster (Soft)',
  delete_node: 'Delete Node',
  delete_hard_cluster_warning:
    'The Signer node is still there, so the Goast Signer will remain. Are you sure you want to delete this cluster?',
  delete_soft_cluster_warning:
    'Signer node is automatically changed to Transaction node and deleted. Are you sure you want to delete this cluster?',
  delete_cluster_input_placeholder: 'Please enter your Cluster Name',
  cluster_name: 'Cluster Name',
  destroy_network_dialog_title: 'Destroy Network',
  number_of_nodes: 'Number of Nodes',
  number_of_cluster: 'Number of Cluster',
  destroy_network_dialog_warning: 'Are you sure you want to delete this network?',
  destroy_network_dialog_input_placeholder: 'Please enter your Network Name.',
  profile_settings: 'Profile Setting',
  update: 'Update',
  language: 'Language',
  timezone: 'Timezone',
  select_timezone: 'Select Timezone',
  input_display_name: 'Input Display Name',
  select_language: 'Select Language',
  updating: 'Updating',
  description: 'Description',
  organizations: 'Organizations',
  organization_count: '{{ count }} Organization',
  organization_count_plural: '{{ count }} Organizations',
  num_of_nodes_cluster: 'Num of Nodes / Cluster',
  node_count: 'Node Count',
  invalid_intake_host_configuration: 'Invalid intake host configuration',
  error_connecting_to_dataDog: 'Error connecting to DataDog',
  no_token_provided: 'No token provided.',
  session_cookie_does_not_set: 'Session Cookie does not set',
  account_not_exist: 'Account does not exist',
  invalid_email: 'Invalid email',
  invalid_email_address_format: 'Invalid email address format',
  can_not_find_out_administrator: 'Can not find out administrator',
  can_not_update_Profile: 'Can not update Profile!',
  can_not_get_list_member: 'Can not get list member!',
  can_not_add_member: 'Can not add member!',
  can_not_remove_member: 'Can not remove member!',
  can_not_create_account: 'Can not create account!',
  can_not_update_account: 'Can not update account!',
  can_not_remove_account: 'Can not remove account!',
  can_not_get_list_network: 'Can not get list network',
  can_not_create_network: 'Can not create network',
  can_not_update_network: 'Can not update network',
  can_not_destroy_network: 'Can not destroy network',
  can_not_create_cluster: 'Can not create cluster',
  can_not_update_cluster: 'Can not update cluster',
  can_not_get_list_network_accounts: 'Can not get list network accounts',
  can_not_find_out_invoice: 'Can not find out invoice',
  can_not_find_out_cluster: 'Can not find out cluster',
  can_not_find_out_node: 'Can not find out node',
  can_not_register_address: 'Can not register address!',
  account_is_not_exist: 'Account is not exist!',
  can_not_find_out_network: 'Can not find out network!',
  can_not_find_out_cluster_to_update: 'Can not find out cluster to update!',
  can_not_find_out_network_to_delete: 'Can not find out network to delete!',
  can_not_find_out_cluster_to_delete: 'Can not find out cluster to delete!',
  can_not_add_node: 'Can not add Node!',
  can_not_find_out_network_to_update: 'Can not find out network to update!',
  can_not_find_out_node_to_update: 'Can not find out node to update!',
  can_not_update_node: 'Can not update Node!',
  can_not_find_out_node_to_delete: 'Can not find out node to delete!',
  user_profile_does_not_exist: 'User profile does not exist!',
  admin_profile_does_not_exist: 'Admin profile does not exist!',
  AWS_account_was_created_but_not_found_in_database:
    'AWS account was created but not found in database',
  the_cluster_has_all_of_validator_nodes_of_network:
    'The cluster has all of Signer nodes of network',
  the_node_is_last_one_of_network: 'The node is last one of network',
  sender_account_does_not_exist: 'Sender account does not exist',
  receiver_account_does_not_exist: 'Receiver account does not exist',
  network_does_not_exist: 'Network does not exist',
  node_does_not_exist: 'Node does not exist',
  account_UUID_does_not_exist: 'Account UUID does not exist',
  account_does_not_exist: 'Account does not exist',
  proposed_node_does_not_exist: 'Proposed node does not exist',
  network_already_exist_in_account: 'Network already exist in account',
  invitation_does_not_exist: 'Invitation does not exist',
  you_dont_have_permission_to_response_the_invitation:
    "You don't have permission to response the invitation",
  invitation_was_already_responsed: 'Invitation was already responsed',
  invitation_was_canceled: 'Invitation was canceled',
  query_result_is_empty: 'query result is empty',
  alert_does_not_exist: 'Alert does not exist',
  member_does_not_exist: 'Member does not exist',
  alert_already_set_read: 'Alert already set read',
  possible_0_validator_node_can_not_send_proposal: 'Possible 0 Signers node, can not send proposal',
  admin_does_not_exist: 'Admin does not exist!',
  failed_to_finded_user_profile_of_specified_uid: 'Failed to finded user profile of specified uid',
  node_id: 'Node ID',
  organization: 'Organization',
  signing_address: 'Signing Address',
  enode: 'Enode',
  region: 'Region',
  instance_type: 'Instance Type',
  block_explorer_instance_type: 'Block Explorer Instance Type',
  delete_node_warning: 'Are you sure you want to delete this Node ?',
  delete_node_input_placeholder: 'Please enter your Node Name.',
  filter: 'Filter',
  all_user: 'All User',
  for_admin: 'For Admin',
  for_user: 'For User',
  too_many_character_of_profile_name_error_message: 'Please enter up to {{ value }} characters.',
  too_many_character_of_coupon_name_error_message: 'Please enter up to {{ value }} characters.',
  too_many_character_of_user_name_error_message: 'Please enter up to {{ value }} characters.',
  account_type: 'Account Type',
  data_grid: {
    find_column: 'Find column',
    print: 'Print',
    equals: 'Equals',
    contains: 'Contains',
    startsWith: 'Starts with',
    endsWith: 'Ends with',
    isEmpty: 'Is empty',
    isNotEmpty: 'Is not empty',
    isAnyOf: 'Is any of',
    is: 'Is',
    isNot: 'Is not',
    between: 'Between',
    filters: 'Filters',
    filter_value: 'Filter value',
    operators: 'Operators',
    column_title: 'Column title',
    reorder_column: 'Reorder column',
    show_all: 'Show all',
    hide_all: 'Hide all',
    columns: 'Columns',
    no_rows: 'No rows',
    density: 'Size',
    density_compact: 'Small',
    density_standard: 'Medium',
    density_comfortable: 'Large',
  },
  refresh: 'Refresh',
  action: 'Action',
  // Summary Menu
  summary: 'Summary',
  summary_billing_filename:
    '{{startDate}}{{endDate}}{{product}}.Payment Report_{{yyyy}}{{mm}}{{dd}}',
  start_month: 'Start Month',
  end_month: 'End Month',
  paid: 'Paid',
  unpaid: 'Unpaid',
  unpaid_exist: 'Unpaid bill exist',
  download_csv: 'Download CSV',
  billing_year: 'Billing Year',
  billing_month: 'Billing Month',
  billing_start_date: 'Billing Start Date',
  billing_end_date: 'Billing End Date',
  product_id: 'Product ID',
  product_name: 'Product Name',
  corporation_name: 'Corporation Name',
  organization_display_name: 'Organization Display Name',
  account_uuid: 'Account UUID',
  transfer_address: 'Owner Email',
  organization_owner: 'Organization Owner',
  owner_email: 'Owner Email',
  accountant_email: 'Accountant Email',
  billing_date: 'Billing Date',
  billing_id: 'Billing ID',
  payment_status: 'Payment Status',
  billing_amount: 'Billing Amount',
  payment_failed: 'Payment Failed',
  license_item_id: 'License Item ID',
  license_name: 'License name',
  unit_price: 'Unit price',
  quantity: 'Quantity',
  coupon_id: 'Coupon ID',
  card_billing_amount: 'Card Billing Amount',
  payment_exchange_rate: 'Payment Exchange Rate',
  fee: 'Fee',
  net: 'Net',
  stripe_status: 'Status',
  stripe_billing_date: 'Billing Date',
  license_amount: 'License Amount',
  download_datetime: 'Download Datetime',
  succeeded: 'Succeeded',
  failed: 'Failed',
  payment_summary: 'Payment Summary',
  // Summary Coupon
  granted_year: 'Granted Year',
  granted_month: 'Granted Month',
  org_display_name: 'Organization Display Name',
  coupon_type: 'Coupon Type',
  coupon_name_csv: 'Coupon Name',
  coupon_amount: 'Coupon Amount',
  grantor_coupon: 'Grantor',
  usage_status_coupon: 'Usage Status',
  coupon_summary: 'Coupon Summary',
  search_condition: 'Search Condition',
  corp_org_information: 'Corporate and Organizational Account Information',
  individual_coupon_summary: 'Individual Coupon Summary',
  others: 'Others',
  summary_coupon_filename:
    '{{startDate}}{{endDate}}{{productname}}.Grant_Coupon_Summary_{{yyyy}}{{mm}}{{dd}}',
  // Registration Modal
  howToRegisterAccount: 'How to register a new account',
  registration_title: 'For first-time registration',
  registration_content:
    'If you are a first time user of G.U.Blockchain Cloud and already have a Google account, please select [Login with Google Account] and your account will be automatically registered. In case you will use an e-mail address other than your Google account, please click the [Login with Email Address] button and enter the e-mail address you want to register. Name and password input box will display for the first time registration. Please perform the initial registration from that.',
  block: 'Block',
  activity: 'Activity',
  proposal_history: 'Proposal History',
  all_proposals_reset: 'All proposals are reset',
  signer: 'Signer',
  transaction: 'Transaction',
  address: 'Address',
  from_block: 'From Block',
  to_block: 'To Block',
  error_alloc_address: `40 digit hexadecimal number(a-f,0-9) required`,
  update_admin_user: 'Update Admin User',
  select_role: 'Select Role',
  owner: 'Owner',
  admin: 'Admin',
  operator: 'Operator',
  billing_operator: 'Billing Operator',
  are_you_sure_you_want_to_transfer_owner_to_this_admin:
    'Are you sure you want to transfer the owner role to this admin user?',
  rebuild: 'Rebuild',
  rebuild_node: 'Rebuild Node',
  are_you_sure_you_want_to_rebuild_this_node:
    'Rebuild node will reset the network data and refetch from other nodes.\nAre you sure you want to rebuild this node?',
  reboot: 'Reboot',
  change_node_version: ' Change Node Version',
  apply_latest_genesis_config: 'Apply latest genesis config',
  change_block_gas_limit: ' Change Block Gas Limit',
  enable_rebuild_node: 'Enable rebuild node',
  are_you_sure_you_want_to_downgrade_this_node:
    'Node downgrading requires to rebuild to reset the network data and refetch from other nodes.',
  node_version: 'Node Version',
  latest_block: 'Latest Block',
  latest_hard_fork: 'Latest Hard Fork',
  block_gas_limit: 'Block Gas Limit',
  input_gas_limit: 'Input Gas Limit',
  genesis_json: 'genesis.json',
  submit: 'Submit',
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
  small_v1: 'Small (v1)',
  medium_v1: 'Medium (v1)',
  large_v1: 'Large (v1)',
  xlarge_v1: 'XLarge (v1)',
  '2xlarge_v1': '2XLarge (v1)',
  small_v2: 'Small (v2)',
  medium_v2: 'Medium (v2)',
  large_v2: 'Large (v2)',
  business_v2: 'Business (v2)',
  businesslarge_v2: 'Business Large (v2)',
  mega_v2: 'Mega (v2)',
  metal_v2: 'Metal (Max TPS) (v2)',
  small_v3: 'Small (v3)',
  medium_v3: 'Medium (v3)',
  large_v3: 'Large (v3)',
  business_v3: 'Business (v3)',
  businesslarge_v3: 'Business Large (v3)',
  mega_v3: 'Mega (v3)',
  metal_v3: 'Metal (Max TPS) (v3)',
  last_login_at: 'Last Login At',
  create_org_account_limit: 'Create Org Account Limit',
  new_accounts: 'New Accounts',
  new_users: 'New Users',
  proposals: 'Proposals',
  debug: 'Debug',
  networks_search_placeholder: 'Search by Network UUID, Network Name',
  num_of_accounts: 'Number of accounts',
  num_of_users: 'Number of users',
  recent_users_in_month: 'Recent users in this month',
  estimated_sales_in_month: 'Estimated sales in this month',
  num_of_networks: 'Number of networks',
  admin_notifications: 'Admin Notifications',
  user_notifications: 'User Notifications',
  last_error_in_month: 'Only the first 30 elements the last month are displayed',
  from: 'From',
  this_month: 'This Month',
  last_week: 'Last Week',
  last_month: 'Last Month',
  last_quarter: 'Last Quarter',
  information: 'Information',
  warning: 'Warning',
  error: 'Error',
  add_member: 'Add Member',
  input_email_address: 'Input Email Address',
  unknown_member: 'Unknown member',
  transfer_primary_owner: 'Transfer Primary Owner',
  delete_member: 'Delete Member',
  are_you_sure_you_want_to_delete_this_member: 'Are you sure you want to delete this member?',
  Are_you_sure_you_want_to_transfer_the_primary_owner:
    'Are you sure you want to transfer the Primary Owner?',
  date_time_format: 'MM/DD/YYYY - HH:mm',
  users_search_placeholder: 'Search by Display Name, Contact Email',
  validator: 'Validator',
  relay: 'Relay',
  validator_node: 'Validator Node',
  relay_node: 'Relay Node',
  start: 'Start',
  num_of_validators: 'Num of Validators',
};
