import React from 'react';
// Component
import Button from '@mui/material/Button';
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/custom-submit-button';
// Store
import { useDispatch, useSelector } from 'react-redux';
import * as AccountActions from '~/stores/actions/account-action';
import { IStore } from '~/stores/configure-store';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFont } from '~/styles/themes/common-styles/font';
import { romanColor, dimGrayColor, whiteSmokeColor } from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';
import { IAccountMember, IAccountSummary } from '~/types/admin-types';

interface IProps extends WithStyles<typeof styles> {
  account: IAccountSummary;
  member: IAccountMember;
  onClose: (isRefersh?: boolean) => void;
  open: boolean;
}

const ChangeAccountPrimaryOwnerDialog = (props: IProps) => {
  const { classes, open, account, member, onClose } = props;
  const isSubmitting = useSelector((store: IStore) =>
    AccountActions.changeAccountPrimaryOwner.isPending(store),
  );
  const { t } = useTranslation(['admin']);
  const dispatch = useDispatch();

  const onSubmit = async () => {
    try {
      await dispatch(
        AccountActions.changeAccountPrimaryOwner({
          input: {
            accountUuid: account.accountUuid,
            uid: member.uid,
          },
        }),
      );
      onClose(true);
    } catch (error) {}
  };

  return (
    <CustomDialog open={open} onClose={() => onClose()} classes={{ paper: classes.dialogPaper }}>
      <CustomDialogTitle>
        <div id="account-members-transfer-title">{t('transfer_primary_owner')}</div>
      </CustomDialogTitle>
      <CustomDialogContent>
        <div id="account-members-transfer-attention" className={classes.warningText}>
          {t('Are_you_sure_you_want_to_transfer_the_primary_owner')}
        </div>
      </CustomDialogContent>
      <CustomDialogActions>
        <Button
          id="change-primary-owner-cancel"
          disabled={isSubmitting}
          className={classes.leftBtn}
          variant="contained"
          onClick={() => onClose()}
        >
          {t('cancel')}
        </Button>
        <SubmitButton
          id="change-primary-owner-add"
          isLoading={isSubmitting}
          label={t('create')}
          submittingLabel={t('creating')}
          onClick={onSubmit}
        />
      </CustomDialogActions>
    </CustomDialog>
  );
};

const styles = createStyles({
  root: {},
  dialogPaper: {
    minWidth: 620,
  },
  dialogTitle: {
    paddingBottom: 25,
  },
  // Submit Button
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 25,
  },
});

export default withStyles(styles)(ChangeAccountPrimaryOwnerDialog);
