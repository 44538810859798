import * as Gtypes from '../../../../gapi/gtypes';

export const destroyNetworkMutation = `mutation ($input: DestroyNetworkAdminInput!) {
  destroyNetwork(input: $input)
}`;

export type MutationDestroyNetworkArgs = Gtypes.MutationDestroyNetworkArgs;

export type DESTROY_NETWORK_RESULT_TYPE = {
  destroyNetwork: boolean;
};

export const deleteClusterMutation = `mutation ($input: DeleteClusterAdminInput!) {
  deleteCluster(input: $input)
}`;

export type MutationDeleteClusterArgs = Gtypes.MutationDeleteClusterArgs;

export type DELETE_CLUSTER_RESULT_TYPE = {
  deleteCluster: Gtypes.DeleteClusterAdminStatus;
};

export const deleteNodeMutation = `mutation ($input: DeleteNodeAdminInput!) {
  deleteNode(input: $input)
}`;

export type MutationDeleteNodeArgs = Gtypes.MutationDeleteNodeArgs;

export type DELETE_NODE_RESULT_TYPE = {
  deleteNode: boolean;
};

// control node
export const controlNodeMutation = `mutation ($input: ControlNodeInput!) {
  controlNode(input: $input)
}`;
export type CONTROL_NODE_RESULT_TYPE = {
  controlNode: boolean;
};

// update node version
export const updateNodeVersion = `mutation ($input: UpdateNodeVersionInput!) {
  updateNodeVersion(input: $input)
}`;
export type UPDATE_NODE_VERSION_RESULT_TYPE = {
  updateNodeVersion: Boolean;
};

// apply hard fork to node
export const applyHardForkToNode = `mutation ($input: ApplyHardForkToNodeInput!) {
  applyHardForkToNode(input: $input)
}`;
export type APPLY_HARD_FORK_TO_NODE_RESULT_TYPE = {
  applyHardForkToNode: Boolean;
};

// update block gas limit
export const updateBlockGasLimit = `mutation ($input: UpdateBlockGasLimitInput!) {
  updateBlockGasLimit(input: $input)
}`;
export type UPDATE_GAS_LIMIT_RESULT_TYPE = {
  updateBlockGasLimit: Boolean;
};

// get genesis json
export const getGenesisJsonQuery = `query ($networkUuid: String!, $clusterUuid: String!, $nodeUuid: String!) {
  getGenesisJson(networkUuid: $networkUuid, clusterUuid: $clusterUuid, nodeUuid: $nodeUuid)
}`;
export type GET_GENESIS_JSON_RESULT_TYPE = {
  getGenesisJson: string;
};

// list networks
export const listNetworksQuery = `query ($order: InputListNetworksOrder, $filter: InputListNetworksFilter, $page: InputPageInfo) {
  listNetworks(order: $order, filter: $filter, page: $page) {
    networks {
      networkId
      chainId
      networkUuid
      networkName
      numOfAccounts
      numOfClusters
      numOfNodes
      createAt
    }
    pageInfo {
      pageIndex
      pageSize
      totalPages
      totalItems
    }
    filter {
      searchText
    }
    order {
      orderType
      orderBy
    }
  }
}`;
export type LIST_NETWORKS_RESULT_TYPE = {
  listNetworks: Gtypes.ListNetworks;
};
