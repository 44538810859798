import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';
import * as Yup from 'yup';

// Component
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CustomInputNumCommaFormat from '~/components/common/custom-input-num-comma-format';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import SubmitButton from '~/components/common/submit-button';

import { Formik, Field, FieldProps, FormikActions, Form } from 'formik';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  pattensBlueColor,
  lightSlateGreyColor,
  nightRiderColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';
import { ListProposalHistoryFilter } from '~/gapi/gtypes';

interface IProps extends WithStyles<typeof styles> {
  filter?: ListProposalHistoryFilter;
  onClose: () => void;
  onFilterPage: (args) => void;
}

type FormValues = {
  startBlock?: number;
  endBlock?: number;
  address?: string;
};

const FilterForm = (props: IProps) => {
  const { classes, filter, onClose, onFilterPage } = props;
  const { t } = useTranslation('admin');

  const initialValues: FormValues = {
    startBlock: filter?.startBlock || undefined,
    endBlock: filter?.endBlock || undefined,
    address: filter?.address || '',
  };

  const validateSchema = Yup.object().shape<FormValues>({
    startBlock: Yup.number()
      .notRequired()
      .min(0, t('validate_minimum', { val: 0 })),
    endBlock: Yup.number()
      .notRequired()
      .min(0, t('validate_minimum', { val: 0 }))
      .test(
        'check-with-block-range',
        t('filter_end_block_more_than_start_block'),
        function (val: number) {
          const startBlock = this.parent.startBlock;
          return startBlock && val ? val >= startBlock : true;
        },
      ),
    address: Yup.string()
      .notRequired()
      .test({
        message: t('error_alloc_address') as string,
        name: 'address',
        test: (val) => (val ? /^0x[a-fA-F0-9]{40}$/g.test(val) : true),
      }),
  });

  const addressField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <div className={classes.formLabelLine}>
          <Grid container spacing={1} alignItems={'center'}>
            <Grid item xs={2}>
              <div className={classes.formLabel}>{t('address')}:</div>
            </Grid>
            <Grid item xs={10}>
              <TextField
                {...field}
                className={classes.textField}
                variant="outlined"
                fullWidth
                placeholder={t('address')}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      style={{ visibility: field.value ? 'visible' : 'hidden' }}
                      onClick={() => {
                        form.setFieldValue('address', '');
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            {!!form.errors.address && form.touched.address && (
              <>
                <Grid item xs={2}></Grid>
                <Grid item xs={10} className={classes.targetDetail}>
                  <div className={classes.formError}>{form.errors.address}</div>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      );
    },
    [classes, t],
  );

  const startBlockField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <>
          <div className={classes.formLabelLine}>
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item xs={2}>
                <div className={classes.formLabel}>{t('from_block')}:</div>
              </Grid>
              <Grid item xs={10} style={{ position: 'relative' }}>
                <CustomInputNumCommaFormat
                  {...field}
                  placeholder={t('from_block')}
                  id="filter-start-block"
                  isAllowed={(value) => value && value >= 0}
                  onChange={(values) => {
                    form.setFieldValue(field.name, values.floatValue);
                  }}
                />
                {field.value && (
                  <IconButton
                    className={classes.formBlockBtn}
                    style={{ position: 'absolute' }}
                    onClick={() => {
                      form.setFieldValue('startBlock', '');
                    }}
                  >
                    {' '}
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )}
              </Grid>
              {!!form.errors.startBlock && form.touched.startBlock && (
                <>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10} className={classes.targetDetail}>
                    <div className={classes.formError}>{form.errors.startBlock}</div>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </>
      );
    },
    [classes, t],
  );

  const endBlockField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <>
          <div className={classes.formLabelLine}>
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item xs={2}>
                <div className={classes.formLabel}>{t('to_block')}:</div>
              </Grid>
              <Grid item xs={10} style={{ position: 'relative' }}>
                <CustomInputNumCommaFormat
                  {...field}
                  id="filter-end-block"
                  placeholder={t('to_block')}
                  isAllowed={(value) => value && value >= 0}
                  onChange={(values) => {
                    form.setFieldValue(field.name, values.floatValue);
                  }}
                />
                {field.value && (
                  <IconButton
                    className={classes.toBlockBtn}
                    style={{ position: 'absolute' }}
                    onClick={() => {
                      form.setFieldValue('endBlock', '');
                    }}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )}
              </Grid>
              {!!form.errors.endBlock && form.touched.endBlock && (
                <>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10} className={classes.targetDetail}>
                    <div className={classes.formError}>{form.errors.endBlock}</div>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </>
      );
    },
    [classes, t],
  );

  const handleCloseDialog = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      const { setSubmitting } = formikActions;
      setSubmitting(true);
      onFilterPage({
        startBlock: values.startBlock || undefined,
        endBlock: values.endBlock || undefined,
        address: values.address,
      });
      setSubmitting(false);
    },
    [onFilterPage],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validateSchema}
      onSubmit={onSubmit}
      render={({ isSubmitting, isValid, dirty }) => (
        <Form className={classes.content}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Field name="startBlock" render={startBlockField} />
            </Grid>
            <Grid item md={12}>
              <Field name="endBlock" render={endBlockField} />
            </Grid>
            <Grid item md={12}>
              <Field name="address" render={addressField} />
            </Grid>
          </Grid>
          <div className={classes.actions}>
            <Button
              id="filter-proposal-history-cancel"
              disabled={isSubmitting}
              className={classes.leftBtn}
              variant="contained"
              onClick={handleCloseDialog}
            >
              {t('cancel')}
            </Button>
            <SubmitButton
              classes={{ root: classes.submitBtn }}
              id="filter-proposal-history-submit"
              isValid={isValid && dirty}
              isSubmitting={isSubmitting}
              label={t('update')}
              submittingLabel={t('updating')}
            />
          </div>
        </Form>
      )}
    />
  );
};

const styles = createStyles({
  root: {},
  content: {
    padding: 20,
    width: 500,
  },
  targetDetail: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  actions: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'end',
  },
  itemLabel: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  itemValue: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
  },
  gridLeftItem: {
    paddingRight: 6,
  },
  gridRightItem: {
    paddingLeft: 6,
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 13,
    marginBottom: 5,
  },
  formControlLabel: {
    marginLeft: '-14px',
    '& .MuiCheckbox-root': {
      padding: '12px',
    },
  },
  formSection: {
    marginTop: 10,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  textField: {
    '& .MuiInputBase-root': {
      height: 40,
      fontSize: '13px',
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: pattensBlueColor,
      backgroundColor: whiteSmokeColor,
      color: '#4D4F5C',
    },
    '& .MuiOutlinedInput-root': {
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiIconButton-root': {
        padding: 4,
      },
      '& .MuiOutlinedInput-input::placeholder': {
        color: lightSlateGreyColor,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': { border: 0 },
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
  submitBtn: {
    width: 80,
  },
  formBlockBtn: {
    position: 'absolute',
    right: 14,
    padding: 4,
    bottom: 4,
  },
  toBlockBtn: {
    position: 'absolute',
    right: 14,
    padding: 4,
    bottom: 4,
  },
  nodeName: {
    fontWeight: 400,
    color: nightRiderColor,
    fontSize: 13,
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  inputNodeName: {
    fontWeight: 450,
    color: nightRiderColor,
    fontSize: 13,
    maxWidth: 100,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default withStyles(styles)(FilterForm);
