import React from 'react';
import moment from 'moment-timezone';
// Redux
import * as AlertActions from '~/stores/actions/alert-action';
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
// Route
import { RouteComponentProps, withRouter } from 'react-router-dom';
// Component
import CustomDataGridToolbar from '~/components/common/custom-data-grid-toolbar';
import TypeFilter from './type-filter';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import {
  lightSlateGreyColor,
  nightRiderColor,
  whiteColor,
  denimColor,
} from '~/styles/themes/common-styles/color';
import { defaultFontBold } from '~/styles/themes/common-styles/font';
// Type
import { IAccountSummary, IAccountAlert, IAccountAlertList, AlertType } from '~/types/admin-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridFilterModel,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import { ROWS_PER_PAGE_OPTIONS, DATA_GRID_SORTING_ORDER } from '~/constants/common';
import { AccountAlertsOrderInputEnum } from '../../../gapi/gtypes';
import { OrderByEnum } from '../../../enums/common';
import CustomDateRange from '~/components/common/custom-date-range';
import ImgIcon from '~/components/common/img-icon';

interface DispProps {
  listAccountAlerts: (
    args: AlertActions.QueryListAccountAlertsArgs,
  ) => Promise<AlertActions.LIST_ACCOUNT_ALERTS_RESULT_TYPE>;
}

interface StateProps {
  accountAlertList: IAccountAlertList;
  listAccountAlertsLoading: boolean;
}

interface Props
  extends StateProps,
    DispProps,
    RouteComponentProps<{}>,
    WithStyles<typeof styles>,
    WithTranslation {
  account: IAccountSummary;
}

interface State {
  alertUuidIsReading?: string;
  filterModel?: GridFilterModel;
  startDate: Date;
  endDate: Date;
}

class AccountNotifications extends React.Component<Props & DispProps & StateProps, State> {
  constructor(props: Props & DispProps) {
    super(props);

    this.state = {
      startDate: moment().subtract(1, 'year').toDate(),
      endDate: moment().toDate(),
      filterModel: {
        items: [
          {
            columnField: DataGridFieldEnum.TYPE,
            operatorValue: 'equals',
            value: undefined,
          },
        ],
      },
    };
  }

  componentDidMount() {
    const { account, listAccountAlerts } = this.props;
    const { startDate, endDate } = this.state;
    listAccountAlerts({
      accountUuid: account.accountUuid,
      filter: {
        startDate: moment(startDate).startOf('day').toISOString(),
        endDate: moment(endDate).endOf('day').toISOString(),
      },
    });
  }

  renewListAlerts = async () => {
    const { account, listAccountAlerts } = this.props;
    account && (await listAccountAlerts({ accountUuid: account.accountUuid }));
  };

  public render() {
    const { classes } = this.props;
    return <div className={classes.root}>{this.renderAlerts}</div>;
  }

  private get renderAlerts() {
    const { classes, t, accountAlertList, listAccountAlertsLoading, account, i18n } = this.props;
    const { alertUuidIsReading, filterModel, startDate, endDate } = this.state;
    const { totalItems, pageIndex: page, pageSize } = accountAlertList.pageInfo;
    const { orderBy, orderType } = accountAlertList.order ?? {};

    const sortModel = orderType &&
      orderBy && [
        {
          field: orderBy,
          sort: orderType === OrderByEnum.Desc ? 'desc' : ('asc' as GridSortDirection),
        },
      ];

    const columns: GridColumns = [
      {
        field: DataGridFieldEnum.TYPE,
        disableColumnMenu: true,
        filterable: true,
        sortable: false,
        headerName: t('type'),
        width: 200,
        filterOperators: [
          {
            label: t('data_grid.equals'),
            value: 'equals',
            InputComponent: TypeFilter,
            getApplyFilterFn: () => null,
          },
        ],
        renderCell: (params: GridCellParams) => {
          return (
            <div className={classes.typeArea}>{this.renderAlertType(params.value as string)}</div>
          );
        },
      },
      {
        field: DataGridFieldEnum.TITLE,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        headerName: t('title'),
        width: 750,
        renderCell: (params: GridCellParams) => {
          const { title, description, linkUrl, alertUuid } = params.row;
          return (
            <div className={classes.cellNotification}>
              <div
                className={classes.titleNotification}
                onClick={() => this.showNotificationInfo(params.row as IAccountAlert)}
              >
                {title}
              </div>
              {alertUuidIsReading === alertUuid && (
                <div>
                  {description && <div className={classes.description}>{description}</div>}
                  {linkUrl && (
                    <div className={classes.path}>
                      <ImgIcon className={classes.icon} imgUrl="/images/icons/copy_icon.png" />
                      <div>{linkUrl}</div>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        },
      },
      {
        field: DataGridFieldEnum.ACCOUNT_UUID,
        disableColumnMenu: true,
        filterable: true,
        sortable: false,
        width: 200,
        headerName: t('account_UUID'),
      },
      {
        field: DataGridFieldEnum.DISPLAY_NAME,
        disableColumnMenu: true,
        filterable: true,
        sortable: false,
        width: 250,
        headerName: t('display_name'),
      },
      {
        field: DataGridFieldEnum.CREATED_AT,
        sortable: true,
        filterable: false,
        headerName: t('create_date'),
        valueFormatter: ({ value }) => t('alert_createAt', { date: value }),
        width: 300,
      },
    ];

    const items: Array<Item> = (accountAlertList.alerts || []).map((alert) => ({
      id: alert.alertUuid,
      alertUuid: alert.alertUuid,
      type: alert.type,
      title: alert.title[i18n.language],
      description: alert.description && alert.description[i18n.language],
      linkUrl: alert.linkUrl,
      accountUuid: account.accountUuid,
      displayName: account.displayName,
      createAt: new Date(alert.createAt),
    }));

    return (
      <>
        <div className={classes.topLine}>
          <div className={classes.topLineRight}>
            <div className={classes.datePickerArea}>
              <CustomDateRange
                label={t('current_display')}
                onChange={this.onChangeDateRange}
                value={{ startDate, endDate }}
              />
            </div>
          </div>
        </div>
        <div className={classes.dataGridWrapper}>
          <DataGrid
            isRowSelectable={() => false}
            rows={items}
            columns={columns}
            classes={{ root: classes.gridRoot }}
            components={{
              Toolbar: CustomDataGridToolbar,
            }}
            localeText={{
              toolbarColumns: t('data_grid.columns'),
              toolbarFilters: t('data_grid.filters'),
              toolbarDensity: t('data_grid.density'),
              filterPanelColumns: t('data_grid.columns'),
              filterPanelOperators: t('data_grid.operators'),
              columnsPanelTextFieldLabel: t('data_grid.find_column'),
              columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
              columnsPanelDragIconLabel: t('data_grid.reorder_column'),
              columnsPanelShowAllButton: t('data_grid.show_all'),
              columnsPanelHideAllButton: t('data_grid.hide_all'),
              toolbarDensityCompact: t('data_grid.density_compact'),
              toolbarDensityStandard: t('data_grid.density_standard'),
              toolbarDensityComfortable: t('data_grid.density_comfortable'),
              filterOperatorContains: t('data_grid.contains'),
              filterOperatorEquals: t('data_grid.equals'),
              filterOperatorStartsWith: t('data_grid.startsWith'),
              filterOperatorEndsWith: t('data_grid.endsWith'),
              filterOperatorIsEmpty: t('data_grid.isEmpty'),
              filterOperatorIsNotEmpty: t('data_grid.isNotEmpty'),
              filterOperatorIsAnyOf: t('data_grid.isAnyOf'),
              filterOperatorIs: t('data_grid.is'),
              filterOperatorNot: t('data_grid.isNot'),
              noRowsLabel: t('data_grid.no_rows'),
            }}
            loading={listAccountAlertsLoading}
            getRowHeight={() => 'auto'}
            paginationMode="server"
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            rowCount={totalItems || 0}
            pageSize={pageSize}
            page={page}
            onPageChange={this.onPageChange}
            onPageSizeChange={this.onPageSizeChange}
            sortingMode="server"
            sortModel={sortModel}
            sortingOrder={DATA_GRID_SORTING_ORDER}
            onSortModelChange={this.onSortModelChange}
            filterMode="server"
            filterModel={filterModel}
            onFilterModelChange={this.onFilterModelChange}
          />
        </div>
      </>
    );
  }

  private onChangeDateRange = (ranges: any): void => {
    const { account, listAccountAlerts } = this.props;
    const { startDate, endDate } = ranges;
    this.setState({
      startDate: startDate,
      endDate: endDate,
    });
    listAccountAlerts({
      accountUuid: account.accountUuid,
      filter: {
        startDate: moment(startDate).startOf('day').toISOString(),
        endDate: moment(endDate).endOf('day').toISOString(),
      },
      page: { pageIndex: 0 },
    });
  };

  private onFilterModelChange = (model: GridFilterModel) => {
    const { account, listAccountAlerts } = this.props;
    this.setState({
      filterModel: model,
    });
    listAccountAlerts({
      accountUuid: account.accountUuid,
      filter: {
        types:
          model.items[0]?.columnField === DataGridFieldEnum.TYPE && model.items[0].value
            ? [model.items[0].value]
            : undefined,
      },
      page: {
        pageIndex: 0,
      },
    });
  };

  private onSortModelChange = (model: GridSortModel) => {
    const { account, listAccountAlerts } = this.props;
    listAccountAlerts({
      accountUuid: account.accountUuid,
      order: model[0] && {
        orderBy: model[0].field as AccountAlertsOrderInputEnum,
        orderType: model[0].sort === 'asc' ? OrderByEnum.Asc : OrderByEnum.Desc,
      },
      page: { pageIndex: 0 },
    }).catch(console.log);
  };

  private onPageSizeChange = (pageSize) => {
    const { account, listAccountAlerts } = this.props;
    listAccountAlerts({
      accountUuid: account.accountUuid,
      page: { pageIndex: 0, pageSize },
    });
  };

  private onPageChange = (page) => {
    const { account, listAccountAlerts } = this.props;
    listAccountAlerts({
      accountUuid: account.accountUuid,
      page: { pageIndex: page },
    });
  };

  private renderAlertType = (type: string) => {
    const { t, classes } = this.props;

    switch (type) {
      case 'important':
        return (
          <>
            <img src="/images/icons/alert-important.svg" className={classes.iconsAlert} alt="" />
            <span className={classes.typeAlert}>{t('common:important')}</span>
          </>
        );
      case 'information':
        return (
          <>
            <img src="/images/icons/alert-infomation.svg" className={classes.iconsAlert} alt="" />
            <span className={classes.typeAlert}>{t('common:information')}</span>
          </>
        );
      case 'warning':
        return (
          <>
            <img src="/images/icons/alert-warning.svg" className={classes.iconsAlert} alt="" />
            <span className={classes.typeAlert}>{t('common:warning')}</span>
          </>
        );
      case 'error':
        return (
          <>
            <img src="/images/icons/alert-error.svg" className={classes.iconsAlert} alt="" />
            <span className={classes.typeAlert}>{t('common:error')}</span>
          </>
        );
      default:
        return null;
    }
  };

  private showNotificationInfo = (alert: IAccountAlert) => {
    const { alertUuidIsReading } = this.state;
    this.setState({
      alertUuidIsReading: alertUuidIsReading
        ? alertUuidIsReading === alert.alertUuid
          ? undefined
          : alert.alertUuid
        : alert.alertUuid,
    });
  };
}

const styles = (theme) =>
  createStyles({
    root: {
      paddingBottom: 50,
    },
    dataGridWrapper: {
      height: 500,
      width: '100%',
      backgroundColor: whiteColor,
    },
    gridRoot: {
      borderBottom: 'none',
      borderRadius: 0,
      '& .MuiDataGrid-cell': {
        whiteSpace: 'pre-wrap',
        maxHeight: 'none !important',
        outline: 'none !important',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    adminNotificationTable: {
      tableLayout: 'fixed',
    },
    // Top line
    topLine: {
      padding: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
    },
    topLineRight: {
      display: 'flex',
      alignItems: 'center',
    },
    datePickerArea: {
      display: 'flex',
      alignItems: 'center',
    },
    typeArea: {
      display: 'flex',
      alignItems: 'center',
    },
    iconsAlert: {
      width: 16,
      height: 16,
      marginRight: 12,
    },
    typeAlert: {
      fontSize: 15,
      color: lightSlateGreyColor,
    },
    cellNotification: {
      minHeight: 76,
      paddingTop: 8,
      paddingBottom: 8,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    titleNotification: {
      ...defaultFontBold,
      cursor: 'pointer',
      fontSize: 15,
      color: nightRiderColor,
    },
    descriptionNotification: {
      marginTop: 10,
      fontSize: 13,
      color: lightSlateGreyColor,
      wordWrap: 'break-word',
    },
    description: {
      fontSize: 13,
      color: lightSlateGreyColor,
      lineHeight: 1.62,
    },
    link: {
      ...defaultFontBold,
      textAlign: 'center',
      fontSize: 13,
      color: denimColor,
      cursor: 'pointer',
    },
    path: {
      display: 'flex',
      alignItems: 'center',
      '& div': {
        color: 'rgba(29,155,209,1)',
        marginLeft: 5,
      },
    },
    icon: {
      width: 12,
      height: 12,
    },
  });

const mapStateToProps = (store: IStore): StateProps => ({
  accountAlertList: store.appState.accountAlertList,
  listAccountAlertsLoading: AlertActions.listAccountAlerts.isPending(store),
});

const mapDispatchToProps = (dispatch): DispProps => ({
  listAccountAlerts: (args: AlertActions.QueryListAccountAlertsArgs) =>
    dispatch(AlertActions.listAccountAlerts(args)),
});

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation('admin')(AccountNotifications)),
  ),
);

type Item = {
  type: string;
  id: string;
  alertUuid: string;
  title: string;
  description?: string;
  accountUuid: string;
  displayName: string;
  linkUrl?: string;
  createAt?: Date;
};

enum DataGridFieldEnum {
  TYPE = 'type',
  ID = 'id',
  TITLE = 'title',
  ACCOUNT_UUID = 'accountUuid',
  DISPLAY_NAME = 'displayName',
  PARAMETERS = 'parameters',
  DESCRIPTION = 'description',
  LINK_URL = 'linkUrl',
  CREATED_AT = 'createAt',
}

export const AllAlertTypes: Array<AlertType> = ['information', 'important', 'warning', 'error'];
