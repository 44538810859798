import React from 'react';
import classNames from 'classnames';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { defaultFontBold } from '~/styles/themes/common-styles/font';
import {
  gainsboroColor,
  manateeColor,
  lavenderColor,
  nightRiderColor,
  denimColor,
  whiteColor,
  silverColor,
  persianGreenColor,
  romanColor,
  blackRussianColor,
} from '~/styles/themes/common-styles/color';

import { IAdminProfile, IClusterStatus, INetworkSummary } from '~/types/admin-types';
import { Link } from 'react-router-dom';
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import ClusterOptions from './cluster-options';
import CircularProgress from '@mui/material/CircularProgress';

interface IStateProps {
  profile?: IAdminProfile;
}

interface Props extends IStateProps, WithStyles<typeof styles>, WithTranslation {
  summary: INetworkSummary;
}

interface State {}

class NetworkDetailOverview extends React.Component<Props, State> {
  public render() {
    const { classes, t, summary, profile } = this.props;
    const { network, accounts } = summary;

    const columns: GridColumns = [
      {
        field: DataGridFieldEnum.CLUSTER_NAME,
        disableColumnMenu: true,
        filterable: true,
        sortable: false,
        headerName: t('cluster_name'),
        width: 200,
      },
      {
        field: DataGridFieldEnum.CLUSTER_ID,
        disableColumnMenu: true,
        filterable: true,
        sortable: false,
        headerName: t('cluster_id'),
        width: 300,
      },
      {
        field: DataGridFieldEnum.NODE_COUNT,
        disableColumnMenu: true,
        sortable: false,
        filterable: true,
        headerName: t('node_count'),
        width: 100,
      },
      {
        field: DataGridFieldEnum.ENDPOINT,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        headerName: t('endpoint'),
        renderCell: (params: GridCellParams) => (
          <div className={classes.endpointUrl}>{params.value}</div>
        ),
        width: 300,
      },
      {
        field: DataGridFieldEnum.BLOCK_EXPLORER,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        headerName: t('block_explore'),
        renderCell: (params: GridCellParams) => (
          <div className={classes.endpointUrl}>{params.value}</div>
        ),
        width: 300,
      },
      {
        field: DataGridFieldEnum.STATUS,
        sortable: false,
        filterable: false,
        headerName: t('status'),
        width: 150,
        renderCell: (params: GridCellParams) =>
          this.clusterStatusRender(params.value as IClusterStatus),
      },
      {
        field: DataGridFieldEnum.ACTION,
        type: 'actions',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        headerName: t('action'),
        headerClassName: classes.cellAction,
        width: 150,
        align: 'left',
        headerAlign: 'left',
        renderCell: (params: GridCellParams) => {
          const showOption = profile && ['owner', 'admin'].includes(profile.role || '');
          return (
            showOption && (
              <ClusterOptions networkUuid={network.networkUuid} cluster={params.row as any} />
            )
          );
        },
      },
    ];

    const items: Array<Item> = network.clusters.map((cluster) => {
      const rpc = cluster.listeners?.find((val) => val.type === 'HTTP_RPC');
      const explorer = cluster.listeners?.find((val) => val.type === 'BLOCK_EXPLORER');
      return {
        id: cluster.clusterUuid,
        clusterName: cluster.clusterName,
        clusterUuid: cluster.clusterUuid,
        nodeCount: cluster.nodes.length,
        endpointUrl: rpc?.port
          ? `${cluster.endpointUrl}${rpc.port === 443 ? '' : `:${rpc.port}`}/`
          : 'N/A',
        endpointBlockExplorerUrl: explorer?.port
          ? `${cluster.endpointUrl}${explorer.port === 443 ? '' : `:${explorer.port}`}/`
          : 'N/A',
        status: cluster.status,
      };
    });

    return (
      <div className={classes.root}>
        <div className={classes.networkInfo}>
          <div className={classes.networkInfoLeft}>
            <div className={classes.nameAndUuid}>
              <div className={classes.networkName}>
                <div className={classes.infoLabel}>{t('network_name')}</div>
                <div className={classes.infoValue}>{network.networkName}</div>
              </div>
              <div className={classes.networkUuid}>
                <div className={classes.infoLabel}>{t('network_uuid')}</div>
                <div className={classes.infoValue}>{network.networkUuid}</div>
              </div>
            </div>
            <div className={classes.description}>
              <div className={classes.infoLabel}>{t('description')}</div>
              <div className={classNames(classes.infoValue, classes.descriptionValue)}>
                {network.networkDescription}
              </div>
            </div>
          </div>
          <div className={classes.networkInfoSeparatorBar} />
          <div className={classes.networkInfoRight}>
            <div className={classes.organizations}>
              <div className={classes.infoLabel}>{t('organizations')}</div>
              <Link to={`/networks/${network.networkUuid}/organizations`}>
                <div className={classNames(classes.infoValue, classes.organizationValue)}>
                  {t('organization_count', { count: accounts.length })}
                </div>
              </Link>
            </div>
            <div className={classes.idAndNumOfNodeCluster}>
              <div className={classes.networkId}>
                <div className={classes.infoLabel}>{t('network_id')}</div>
                <div className={classes.infoValue}>{network.networkId}</div>
              </div>
              <div className={classes.numOfNodeCluster}>
                <div className={classes.infoLabel}>{t('num_of_nodes_cluster')}</div>
                <div className={classes.infoValue}>{`${network.clusters
                  .map((c) => c.nodes.length)
                  .reduce((a, b) => a + b, 0)}/${network.clusters.length}`}</div>
              </div>
            </div>
          </div>
        </div>
        <DataGrid
          className={classes.dataGridWrapper}
          rows={items}
          columns={columns}
          autoHeight
          localeText={{
            toolbarColumns: t('data_grid.columns'),
            toolbarFilters: t('data_grid.filters'),
            toolbarDensity: t('data_grid.density'),
            filterPanelColumns: t('data_grid.columns'),
            filterPanelOperators: t('data_grid.operators'),
            columnsPanelTextFieldLabel: t('data_grid.find_column'),
            columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
            columnsPanelDragIconLabel: t('data_grid.reorder_column'),
            columnsPanelShowAllButton: t('data_grid.show_all'),
            columnsPanelHideAllButton: t('data_grid.hide_all'),
            toolbarDensityCompact: t('data_grid.density_compact'),
            toolbarDensityStandard: t('data_grid.density_standard'),
            toolbarDensityComfortable: t('data_grid.density_comfortable'),
            filterOperatorContains: t('data_grid.contains'),
            filterOperatorEquals: t('data_grid.equals'),
            filterOperatorStartsWith: t('data_grid.startsWith'),
            filterOperatorEndsWith: t('data_grid.endsWith'),
            filterOperatorIsEmpty: t('data_grid.isEmpty'),
            filterOperatorIsNotEmpty: t('data_grid.isNotEmpty'),
            filterOperatorIsAnyOf: t('data_grid.isAnyOf'),
            filterOperatorIs: t('data_grid.is'),
            filterOperatorNot: t('data_grid.isNot'),
            noRowsLabel: t('data_grid.no_rows'),
          }}
          isRowSelectable={() => false}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 70}
          hideFooter
        />
      </div>
    );
  }

  public clusterStatusRender = (status: IClusterStatus) => {
    const { classes } = this.props;
    type ClusterStatusText = 'running' | 'pending' | 'removing' | 'stopped' | 'error';
    const statusMap: { [key in IClusterStatus]: ClusterStatusText } = {
      alive: 'running',
      pending: 'pending',
      removing: 'removing',
      dead: 'stopped',
      failure: 'error',
    };
    const statusText = statusMap[status];

    return (
      <div className={classes.clusterStatus}>
        {statusText === 'pending' ||
          (statusText === 'removing' && (
            <CircularProgress size={14} className={classes.loadingIcon} />
          ))}
        {statusText === 'running' && (
          <div className={classNames(classes.statusIcon, classes.runningIcon)} />
        )}
        {statusText === 'stopped' ||
          (statusText === 'error' && (
            <div className={classNames(classes.statusIcon, classes.stoppedIcon)} />
          ))}
        <span className={classes.statusText}>{statusText}</span>
      </div>
    );
  };
}

const styles = (theme) =>
  createStyles({
    root: {},
    networkInfo: {
      padding: '25px 20px 35px',
      display: 'flex',
      borderBottom: `1px solid ${gainsboroColor}`,
    },
    networkInfoLeft: {
      flex: 2,
      display: 'flex',
    },
    networkInfoSeparatorBar: {
      width: 1,
      backgroundColor: lavenderColor,
      marginLeft: 50,
      marginRight: 50,
    },
    networkInfoRight: {
      flex: 1,
    },
    nameAndUuid: {
      flex: 1,
      marginRight: 20,
    },
    networkName: {},
    networkUuid: {
      marginTop: 15,
    },
    organizations: {},
    networkId: {
      flex: 1,
      marginRight: 10,
    },
    numOfNodeCluster: {
      flex: 1,
    },
    idAndNumOfNodeCluster: {
      display: 'flex',
      marginTop: 15,
    },
    description: {
      flex: 1,
    },
    networkInfoItem: {
      marginTop: 15,
    },
    infoLabel: {
      ...defaultFontBold,
      fontSize: 14,
      color: nightRiderColor,
    },
    infoValue: {
      marginTop: 10,
      fontSize: 15,
      color: nightRiderColor,
      lineHeight: '25px',
    },
    organizationValue: {
      color: denimColor,
      cursor: 'pointer',
    },
    descriptionValue: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 4,
      WebkitBoxOrient: 'vertical',
    },
    loadingIcon: {
      color: silverColor,
    },
    statusIcon: {
      width: 10,
      height: 10,
      borderRadius: '50%',
    },
    runningIcon: {
      backgroundColor: persianGreenColor,
    },
    stoppedIcon: {
      backgroundColor: romanColor,
    },
    statusText: {
      marginLeft: 10,
    },
    cellAction: {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
    },
    dataGridWrapper: {
      width: '100%',
      backgroundColor: whiteColor,
      border: 'none',
    },
    clusterStatus: {
      display: 'flex',
      alignItems: 'center',
    },
    endpointUrl: {
      paddingTop: 8,
      wordWrap: 'break-word',
      wordBreak: 'break-all',
    },
  });

enum DataGridFieldEnum {
  ID = 'id',
  CLUSTER_ID = 'clusterUuid',
  CLUSTER_NAME = 'clusterName',
  NODE_COUNT = 'nodeCount',
  ENDPOINT = 'endpointUrl',
  BLOCK_EXPLORER = 'endpointBlockExplorerUrl',
  STATUS = 'status',
  ACTION = 'action',
}

type Item = {
  id: string;
  clusterName: string;
  clusterUuid: string;
  nodeCount: number;
  endpointUrl: string;
  endpointBlockExplorerUrl: string;
  status: IClusterStatus;
};

const mapStateToProps = (store: IStore, ownProps): IStateProps => ({
  profile: store.appState.profile,
});

export default withStyles(styles)(
  connect(mapStateToProps)(withTranslation('admin')(NetworkDetailOverview)),
);
