import React, { useCallback, useState } from 'react';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ImgIcon from '~/components/common/img-icon';
import { denimColor, nightRiderColor } from '~/styles/themes/common-styles/color';
import { defaultFont } from '~/styles/themes/common-styles/font';
import DeleteUserDialog from '~/components/common/delete-user-dialog';
import UpdateAdminUserDialog from './update-admin-user-dialog';
import OptionButton from '~/components/common/option-button';
import { IAdminUser } from '~/types/admin-types';

interface IProps extends WithStyles<typeof styles> {
  user: IAdminUser;
}

export const AdminUserOption = (props: IProps) => {
  const { classes, user } = props;
  const { t } = useTranslation('admin');
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);
  const [openUpdateUserDialog, setOpenUpdateUserDialog] = useState(false);

  const onOpenAdminUserPopover = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onCloseOptionPopover = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const onOpenDeleteUserDialog = useCallback(() => {
    setOpenDeleteUserDialog(true);
  }, []);

  const onCloseDeleteUserDialog = useCallback(() => {
    setOpenDeleteUserDialog(false);
    setMenuAnchorEl(null);
  }, []);

  const onOpenUpdateUserDialog = useCallback(() => {
    setOpenUpdateUserDialog(true);
  }, []);

  const onCloseUpdateUserDialog = useCallback(() => {
    setOpenUpdateUserDialog(false);
    setMenuAnchorEl(null);
  }, []);

  return (
    <>
      <DeleteUserDialog user={user} open={openDeleteUserDialog} onClose={onCloseDeleteUserDialog} />
      {openUpdateUserDialog && (
        <UpdateAdminUserDialog
          user={user}
          open={openUpdateUserDialog}
          onClose={onCloseUpdateUserDialog}
        />
      )}
      <Popover
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={onCloseOptionPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <List>
          <ListItem button className={classes.listOptionItem} onClick={onOpenUpdateUserDialog}>
            <ImgIcon className={classes.editIcon} imgUrl="/images/icons/edit-ico.png" />
            {t('edit')}
          </ListItem>
          <ListItem button className={classes.listOptionItem} onClick={onOpenDeleteUserDialog}>
            <ImgIcon className={classes.deleteIcon} imgUrl="/images/icons/delete_ico.png" />
            {t('delete')}
          </ListItem>
        </List>
      </Popover>
      <OptionButton id="admin-user-option" onClick={onOpenAdminUserPopover} />
    </>
  );
};

const styles = createStyles({
  root: {},
  tableHeadCustom: {
    marginTop: 16,
  },
  networkUuid: {
    cursor: 'pointer',
    color: denimColor,
  },
  menuIcon: {},
  listOptionItem: {
    height: 46,
    width: 210,
    ...defaultFont,
    fontSize: 15,
    color: nightRiderColor,
  },
  deleteIcon: {
    marginRight: 8,
  },
  editIcon: {
    marginRight: 8,
  },
});

export default withStyles(styles)(AdminUserOption);
