import React, { useCallback } from 'react';

// Components
import { TextField } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid';

// Styles
import { Theme } from '@mui/material/styles';
import { withStyles, createStyles, WithStyles } from '@mui/styles';

// i18n
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props extends GridFilterInputValueProps, WithTranslation, WithStyles<typeof styles> {}

const TypeFilter: React.FunctionComponent<Props> = (props) => {
  const { item, classes, t, applyValue } = props;

  const handleFilterChange = useCallback(
    (event) => {
      const value = event.target.value;
      applyValue({ ...item, value: value === 'all' ? undefined : value });
    },
    [applyValue, item],
  );

  return (
    <TextField
      select
      label={t('value')}
      value={item.value || 'all'}
      onChange={handleFilterChange}
      fullWidth
      SelectProps={{
        native: true,
      }}
    >
      {[
        { label: 'All Type', value: 'all' },
        { label: 'Information', value: 'information' },
        { label: 'Important', value: 'important' },
        { label: 'Warning', value: 'warning' },
        { label: 'Error', value: 'error' },
      ].map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </TextField>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(withTranslation('admin')(TypeFilter));
