import React, { useState, useCallback, useMemo } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { nightRiderColor } from '~/styles/themes/common-styles/color';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid';
import { LastLoginFilterSelections } from '..';

interface IProps extends GridFilterInputValueProps, WithTranslation, WithStyles<typeof styles> {}

const TypeFilter: React.FunctionComponent<IProps> = (props) => {
  const { classes, t, item, applyValue } = props;
  const selected = item.value;

  const handleFilterChange = useCallback(
    (value) => {
      applyValue({
        ...item,
        value: value.target.value,
      });
    },
    [applyValue, item],
  );

  return (
    <FormControl className={classes.textField} fullWidth variant="standard">
      <InputLabel id="select-label">Filter value</InputLabel>
      <Select id="last-login-select" value={selected} onChange={handleFilterChange}>
        {LastLoginFilterSelections.map((val) => (
          <MenuItem key={val.value} value={val.value}>
            {t(val.label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const styles = createStyles({
  textField: {
    position: 'relative',
  },
  popup: {
    right: 0,
    position: 'fixed',
    background: '#fff',
  },
  popoverPaper: {
    marginTop: 5,
    position: 'fixed',
  },
  list: {
    padding: 0,
  },
  listItem: {
    width: 270,
    fontSize: 15,
    color: nightRiderColor,
    paddingTop: 8,
    paddingBottom: 8,
  },
  nestedListItem: {
    paddingLeft: 36,
    height: 40,
  },
  listItemCheckbox: {
    padding: 5,
  },
});

export default withStyles(styles)(withTranslation('admin')(TypeFilter));
