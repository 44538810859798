import React from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  lightSlateGreyColor,
  nightRiderColor,
  whiteColor,
  blackRussianColor,
} from '~/styles/themes/common-styles/color';
import { defaultFontBold } from '~/styles/themes/common-styles/font';
import { IStore } from '~/stores/configure-store';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import Layout from '~/components/common/Layout';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import TypeFilter from './type-filter';
import CustomDateRange, { DateRangeParams } from '~/components/common/custom-date-range';
import * as AlertActions from '~/stores/actions/alert-action';
import { AlertType, IAdminAlertList } from '~/types/admin-types';
import { DataGrid, GridCellParams, GridColumns, GridFilterModel } from '@mui/x-data-grid';
import CustomDataGridToolbar from '~/components/common/custom-data-grid-toolbar';
import { ROWS_PER_PAGE_OPTIONS } from '~/constants/common';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withAdminProfile } from '~/hooks/with-admin-profile';
import { compose } from 'redux';
import { AppRoutePath } from '~/AppRouter';
import { withAuthorization } from '~/hooks/with-authorization';

interface StateProps {
  adminNotificationList: IAdminAlertList;
  isLoading: boolean;
}

interface DispProps {
  listAdminNotifications: (
    args: AlertActions.QueryListAdminAlertsArgs,
  ) => Promise<AlertActions.LIST_ADMIN_ALERTS_RESULT_TYPE>;
}

interface Props
  extends StateProps,
    DispProps,
    RouteComponentProps<{}>,
    WithTranslation,
    WithStyles<typeof styles> {}

interface State {
  filterModel: GridFilterModel;
  startDate: Date;
  endDate: Date;
}

class AdminNotifications extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      startDate: moment().subtract(1, 'month').toDate(),
      endDate: moment().toDate(),
      filterModel: {
        items: [
          {
            columnField: DataGridFieldEnum.TYPE,
            operatorValue: 'contains',
            value: {
              forAdmin: AllAlertTypes,
            },
          },
        ],
      },
    };
  }

  componentDidMount() {
    const { listAdminNotifications } = this.props;
    const { startDate, endDate, filterModel } = this.state;
    listAdminNotifications({
      filter: {
        startDate: moment(startDate).startOf('day').toISOString(),
        endDate: moment(endDate).endOf('day').toISOString(),
        forAdmin: AllAlertTypes,
      },
      page: {
        pageSize: 30,
      },
    });
  }

  public render() {
    const { classes, t, adminNotificationList, isLoading, i18n } = this.props;
    const { totalItems, pageIndex: page, pageSize } = adminNotificationList.pageInfo;
    const { filterModel, startDate, endDate } = this.state;

    const columns: GridColumns = [
      {
        field: DataGridFieldEnum.TYPE,
        disableColumnMenu: true,
        filterable: true,
        sortable: false,
        headerName: t('type'),
        width: 150,
        filterOperators: [
          {
            label: t('data_grid.contains'),
            value: 'contains',
            InputComponent: TypeFilter,
            getApplyFilterFn: () => null,
          },
        ],
        renderCell: (params: GridCellParams) => {
          return (
            <Typography className={classes.typeArea}>
              {this.renderAlertType(params.value as string)}
            </Typography>
          );
        },
      },
      {
        field: DataGridFieldEnum.TITLE,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        headerName: t('title'),
        width: 1000,
        renderCell: (params: GridCellParams) => {
          const { title, description } = params.row;
          return (
            <Typography className={classes.cellNotification}>
              <Typography className={classes.titleNotification}>{title}</Typography>
              {description && (
                <Typography className={classes.descriptionNotification}>
                  <Typography className={classes.description}>{description}</Typography>
                </Typography>
              )}
            </Typography>
          );
        },
      },
      {
        field: DataGridFieldEnum.CREATED_AT,
        sortable: false,
        filterable: false,
        headerName: t('create_date'),
        valueFormatter: ({ value }) => t('date_hour_value', { date: value }),
        width: 300,
      },
    ];
    const items: Array<Item> = (adminNotificationList.alerts || []).map((alert) => ({
      id: alert.alertUuid,
      type: alert.type,
      title: alert.title[i18n.language],
      description: alert.description && alert.description[i18n.language],
      createAt: new Date(alert.createAt),
    }));

    return (
      <Layout>
        <div className={classes.root}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Link
              component={RouterLink}
              to={AppRoutePath.Dashboard}
              color="text.primary"
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            </Link>
            <Typography color="text.primary">{t('admin_notifications')}</Typography>
          </Breadcrumbs>
          <div className={classes.datePickerArea}>
            <CustomDateRange
              label={t('current_display')}
              onChange={this.onChangeDateRange}
              value={{ startDate, endDate }}
            />
          </div>
          <div className={classes.dataGridWrapper}>
            <DataGrid
              isRowSelectable={() => false}
              rows={items}
              columns={columns}
              classes={{ root: classes.gridRoot }}
              components={{
                Toolbar: CustomDataGridToolbar,
              }}
              localeText={{
                toolbarColumns: t('data_grid.columns'),
                toolbarFilters: t('data_grid.filters'),
                toolbarDensity: t('data_grid.density'),
                filterPanelColumns: t('data_grid.columns'),
                filterPanelOperators: t('data_grid.operators'),
                columnsPanelTextFieldLabel: t('data_grid.find_column'),
                columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
                columnsPanelDragIconLabel: t('data_grid.reorder_column'),
                columnsPanelShowAllButton: t('data_grid.show_all'),
                columnsPanelHideAllButton: t('data_grid.hide_all'),
                toolbarDensityCompact: t('data_grid.density_compact'),
                toolbarDensityStandard: t('data_grid.density_standard'),
                toolbarDensityComfortable: t('data_grid.density_comfortable'),
                filterOperatorContains: t('data_grid.contains'),
                filterOperatorEquals: t('data_grid.equals'),
                filterOperatorStartsWith: t('data_grid.startsWith'),
                filterOperatorEndsWith: t('data_grid.endsWith'),
                filterOperatorIsEmpty: t('data_grid.isEmpty'),
                filterOperatorIsNotEmpty: t('data_grid.isNotEmpty'),
                filterOperatorIsAnyOf: t('data_grid.isAnyOf'),
                filterOperatorIs: t('data_grid.is'),
                filterOperatorNot: t('data_grid.isNot'),
                noRowsLabel: t('data_grid.no_rows'),
              }}
              loading={isLoading}
              paginationMode="server"
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              rowCount={totalItems || 0}
              pageSize={pageSize}
              page={page}
              onPageChange={this.onPageChange}
              onPageSizeChange={this.onPageSizeChange}
              filterMode="server"
              filterModel={filterModel}
              onFilterModelChange={this.onFilterModelChange}
              getRowHeight={() => 'auto'}
              onRowClick={this.onRowClick}
              disableSelectionOnClick
            />
          </div>
        </div>
      </Layout>
    );
  }

  private onRowClick = (params) => {
    const { history } = this.props;
    history.push(`/admin-notifications/${params.row.id}`);
  };

  private onChangeDateRange = (ranges: DateRangeParams): void => {
    const { startDate, endDate } = ranges;
    this.setState({
      startDate: startDate,
      endDate: endDate,
    });
    this.props.listAdminNotifications({
      filter: {
        startDate: moment(startDate).startOf('day').toISOString(),
        endDate: moment(endDate).endOf('day').toISOString(),
      },
      page: { pageIndex: 0 },
    });
  };

  private onFilterModelChange = (model: GridFilterModel) => {
    this.setState({
      filterModel: model,
    });
    this.props.listAdminNotifications({
      filter: {
        ...(model?.items[0].value ?? {
          forAdmin: [],
          forUser: [],
          forAll: [],
        }),
      },
      page: { pageIndex: 0 },
    });
  };

  private onPageSizeChange = (pageSize) => {
    this.props.listAdminNotifications({
      page: { pageIndex: 0, pageSize },
    });
  };

  private onPageChange = (page) => {
    this.props.listAdminNotifications({
      page: { pageIndex: page },
    });
  };

  private renderAlertType = (type: string) => {
    const { t, classes } = this.props;

    switch (type) {
      case 'important':
        return (
          <>
            <img src="/images/icons/alert-important.svg" className={classes.iconsAlert} alt="" />
            <span className={classes.typeAlert}>{t('common:important')}</span>
          </>
        );
      case 'information':
        return (
          <>
            <img src="/images/icons/alert-infomation.svg" className={classes.iconsAlert} alt="" />
            <span className={classes.typeAlert}>{t('common:information')}</span>
          </>
        );
      case 'warning':
        return (
          <>
            <img src="/images/icons/alert-warning.svg" className={classes.iconsAlert} alt="" />
            <span className={classes.typeAlert}>{t('common:warning')}</span>
          </>
        );
      case 'error':
        return (
          <>
            <img src="/images/icons/alert-error.svg" className={classes.iconsAlert} alt="" />
            <span className={classes.typeAlert}>{t('common:error')}</span>
          </>
        );
      default:
        return null;
    }
  };
}

const styles = (theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: 'auto',
      paddingRight: 0,
    },
    dataGridWrapper: {
      height: 500,
      width: '100%',
      marginTop: 24,
      backgroundColor: whiteColor,
      [theme.breakpoints.up('sm')]: {
        marginTop: 32,
      },
      '& .MuiDataGrid-row': {
        cursor: 'pointer',
      },
    },
    gridRoot: {
      '& .MuiDataGrid-row': {
        maxHeight: 'none !important',
      },
      '& .MuiDataGrid-cell': {
        whiteSpace: 'pre-wrap',
        maxHeight: 'none !important',
        outline: 'none !important',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    datePickerArea: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 4,
      justifyContent: 'end',
    },
    typeArea: {
      display: 'flex',
      alignItems: 'center',
    },
    iconsAlert: {
      width: 16,
      height: 16,
      marginRight: 12,
    },
    typeAlert: {
      fontSize: 15,
      color: lightSlateGreyColor,
    },
    cellNotification: {
      minHeight: 76,
      paddingTop: 8,
      paddingBottom: 8,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    titleNotification: {
      ...defaultFontBold,
      fontSize: 15,
      color: nightRiderColor,
    },
    descriptionNotification: {
      marginTop: 10,
      fontSize: 13,
      color: lightSlateGreyColor,
      wordWrap: 'break-word',
    },
    description: {
      fontSize: 13,
      color: lightSlateGreyColor,
      lineHeight: 1.62,
    },
  });

const mapStateToProps = (store: IStore) => ({
  adminNotificationList: store.appState.adminNotificationList,
  isLoading: AlertActions.listAdminNotifications.isPending(store),
});

const mapDispatchToProps = (dispatch): DispProps => ({
  listAdminNotifications: (args: AlertActions.QueryListAdminAlertsArgs) =>
    dispatch(AlertActions.listAdminNotifications(args)),
});

export default compose(
  withFirebaseUser(),
  withAdminProfile,
  withAuthorization(['owner', 'admin', 'operator']),
  withStyles(styles),
  withTranslation(['admin', 'common']),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AdminNotifications);

type Item = {
  id: string;
  type: string;
  title: string;
  description?: string;
  createAt?: Date;
};

enum DataGridFieldEnum {
  TYPE = 'type',
  TITLE = 'title',
  PARAMETERS = 'parameters',
  DESCRIPTION = 'description',
  CREATED_AT = 'createAt',
}

export const AllAlertTypes: Array<AlertType> = ['information', 'important', 'warning', 'error'];
