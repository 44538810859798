import React from 'react';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  defaultFont,
  defaultFontBold,
  defaultFontRegular,
} from '~/styles/themes/common-styles/font';
import {
  nightRiderColor,
  lightSlateGreyColor,
  pattensBlueColor,
  snowColor,
  whiteColor,
} from '~/styles/themes/common-styles/color';
import LgButton from '~/components/common/lg-button';
import ArrowIcon from '../../common/arrow-icon';
import ImgIcon from '~/components/common/img-icon';
import AddCouponDialog from './add-coupon-dialog';
import ChangeAccountTypeDialog from './change-account-plan-dialog';

import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { renderAccountBalance } from '~/utilities/render-utils';
import { IAccountSummary, IAdminProfile } from '~/types/admin-types';
import { IStore } from '~/stores/configure-store';
import { connect } from 'react-redux';

interface StateProps {
  profile?: IAdminProfile;
}

interface Props
  extends RouteComponentProps<{}>,
    WithStyles<typeof styles>,
    WithTranslation,
    StateProps {
  account: IAccountSummary;
}

interface DispProps {}

interface State {
  accountTypeAnchorEl?: HTMLButtonElement;
  openChangeAccountTypeDialog: boolean;
  accountPlan: 'personal' | 'enterprise';
  openAddCouponDialog: boolean;
}

class AccountDetailInfo extends React.Component<Props & DispProps, State> {
  constructor(props: Props & DispProps) {
    super(props);

    this.state = {
      accountTypeAnchorEl: undefined,
      openChangeAccountTypeDialog: false,
      accountPlan: this.props.account.plan,
      openAddCouponDialog: false,
    };
  }

  public render() {
    const { classes, t, account, profile } = this.props;
    const { openAddCouponDialog } = this.state;
    const showChangeAccountType = profile?.role === 'owner' || profile?.role === 'admin';

    return (
      <div className={classes.root}>
        <div className={classes.left}>
          <div className={classNames(classes.leftItem, classes.leftTopItem)}>
            <div className={classes.label}>{t('account_UUID')}</div>
            <div className={classes.value}>{account.accountUuid}</div>
          </div>
          <div className={classes.leftItem}>
            <div className={classes.label}>{t('display_name')}</div>
            <div className={classes.value}>{account.displayName}</div>
          </div>
          <div className={classes.leftItem}>
            <div className={classes.label}>{t('contact_email')}</div>
            <div className={classes.value}>{account.email}</div>
          </div>
          <div className={classes.leftItem}>
            <div className={classes.label}>{t('balance')}</div>
            <div className={classes.value}>{renderAccountBalance(account.balance)}</div>
          </div>
          <div className={classes.leftItem}>
            <div className={classes.label}>{t('account_type')}</div>
            <div className={classes.value}>
              {t(account.plan === 'personal' ? 'normal' : 'enterprise')}
            </div>
          </div>
          <div className={classes.leftItem}>
            <div className={classes.label}>{t('create_date')}</div>
            <div className={classes.value}>
              {account.createAt ? t('date_value', { date: new Date(account.createAt) }) : ''}
            </div>
          </div>
          <div className={classes.leftItem}>
            <div className={classes.label}>{t('lock_account')}</div>
            <div className={classes.value}>
              {/* account.lockAccount ? t('true') : */ t('false')}
            </div>
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.rightTop}>
            {showChangeAccountType && (
              <>
                <AddCouponDialog
                  open={openAddCouponDialog}
                  onClose={this.onCloseAddCouponDialog}
                  account={account}
                />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addBtn}
                  startIcon={<AddIcon />}
                  onClick={this.onOpenAddCouponDialog}
                >
                  {t('add_coupon')}
                </Button>
              </>
            )}
          </div>
          {showChangeAccountType && (
            <div className={classes.rightBottom}>{this.renderAccountTypeSection}</div>
          )}
        </div>
      </div>
    );
  }

  get renderAccountTypeSection() {
    const { classes, t, account } = this.props;
    const { accountTypeAnchorEl, openChangeAccountTypeDialog, accountPlan } = this.state;

    return (
      <div className={classes.accountTypeBox}>
        <ChangeAccountTypeDialog
          open={openChangeAccountTypeDialog}
          onClose={this.onCloseChangeAccountPlanDialog}
          changeAccountArgs={{
            accountUuid: account.accountUuid,
            newPlan: accountPlan,
          }}
        />
        <div className={classes.accountTypeTitle}>{t('change_account_type')}</div>
        <div className={classes.accountTypeButtonWrapper}>
          <LgButton
            classes={{ root: classes.openPlanMenuBtn }}
            onClick={this.onOpenAccountTypeMenu}
          >
            <div>
              <span className={classes.openPlanMenuBtnText}>
                {t(accountPlan === 'personal' ? 'normal' : 'enterprise')}
              </span>
            </div>
            <ArrowIcon direction={'down'} size={3} />
          </LgButton>
          <Menu
            anchorEl={accountTypeAnchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            keepMounted
            open={Boolean(accountTypeAnchorEl)}
            onClose={this.onCloseAccountTypeMenu}
            classes={{ paper: classes.planMenu }}
            elevation={1}
            disableAutoFocusItem
          >
            <MenuItem
              className={classes.planMenuItem}
              onClick={this.onSelectPlanMenuItem}
              value="personal"
            >
              <ImgIcon
                className={accountPlan === 'personal' ? '' : 'icon'}
                imgUrl="/images/icons/check_ico.png"
              />
              <Typography className="text">{t('normal')}</Typography>
            </MenuItem>
            <MenuItem
              className={classes.planMenuItem}
              onClick={this.onSelectPlanMenuItem}
              value="enterprise"
            >
              <ImgIcon
                className={accountPlan === 'enterprise' ? '' : 'icon'}
                imgUrl="/images/icons/check_ico.png"
              />
              <Typography className="text">{t('enterprise')}</Typography>
            </MenuItem>
          </Menu>
          <Button
            id="submit"
            variant="contained"
            color="primary"
            disabled={account.plan === accountPlan}
            onClick={this.onClickChangeAccountTypeBtn}
            className={classes.submitPlanButton}
          >
            {t('change')}
          </Button>
        </div>
      </div>
    );
  }

  private onOpenAddCouponDialog = () => {
    this.setState({
      openAddCouponDialog: true,
    });
  };

  private onCloseAddCouponDialog = () => {
    this.setState({
      openAddCouponDialog: false,
    });
  };

  private onOpenChangeAccountPlanDialog = () => {
    this.setState({
      openChangeAccountTypeDialog: true,
    });
  };

  private onCloseChangeAccountPlanDialog = () => {
    this.setState({
      openChangeAccountTypeDialog: false,
    });
  };

  private onOpenAccountTypeMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      accountTypeAnchorEl: e.currentTarget,
    });
  };

  private onCloseAccountTypeMenu = () => {
    this.setState({ accountTypeAnchorEl: undefined });
  };

  private onSelectPlanMenuItem = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      accountPlan: event.currentTarget.getAttribute('value') as any,
    });
    this.onCloseAccountTypeMenu();
  };

  private onClickChangeAccountTypeBtn = async () => {
    this.onOpenChangeAccountPlanDialog();
  };
}

const styles = createStyles({
  root: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  left: {},
  leftItem: {
    marginTop: 15,
  },
  leftTopItem: {
    marginTop: 0,
  },
  label: {
    ...defaultFontBold,
    fontSize: 12,
    color: nightRiderColor,
  },
  value: {
    marginTop: 4,
    ...defaultFont,
    fontSize: 15,
    color: lightSlateGreyColor,
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  rightTop: {
    textAlign: 'right',
  },
  addBtn: {
    height: 34,
    width: 170,
    '& span': {
      justifyContent: 'center',
    },
  },
  iconAddBtn: {
    width: 16,
    height: 16,
    marginRight: 10,
  },
  rightBottom: {
    textAlign: 'right',
  },
  accountTypeBox: {
    textAlign: 'center',
    padding: '30px 95px',
    backgroundColor: snowColor,
    border: `solid 1px ${pattensBlueColor}`,
    borderRadius: 4,
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
  },
  accountTypeTitle: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
  },
  accountTypeButtonWrapper: {
    marginTop: 20,
  },
  openPlanMenuBtn: {
    width: 180,
    height: 36,
    marginRight: 15,
  },
  openPlanMenuBtnText: {},
  planMenu: {
    width: 180,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: pattensBlueColor,
    marginTop: 2,
  },
  planMenuItem: {
    ...defaultFontRegular,
    fontSize: 15,
    height: 48,
    '& .text': {
      ...defaultFont,
      fontSize: 13,
      marginLeft: 8,
    },
    '& .icon': {
      visibility: 'hidden',
    },
    '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.14)',
    },
  },
  submitPlanButton: {
    width: 160,
    height: 36,
  },
  submitPlanButtonDisabled: {
    color: whiteColor,
  },
});

const mapStateToProps = (store: IStore): StateProps => ({
  profile: store.appState.profile,
});

export default withStyles(styles)(
  connect(mapStateToProps)(withTranslation('admin')(withRouter(AccountDetailInfo))),
);
