import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  lightSlateGreyColor,
  gainsboroColor,
  blackRussianColor,
  denimColor,
  romanColor,
} from '~/styles/themes/common-styles/color';
import { Link as RouterLink } from 'react-router-dom';
import { IStore } from '~/stores/configure-store';
import HomeIcon from '@mui/icons-material/Home';
import Layout from '~/components/common/Layout';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CustomDateRange from '~/components/common/custom-date-range';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import * as SummaryActions from '~/stores/actions/summary-action';
import { IAdminProfile, ISalesWithCond } from '~/types/admin-types';
import { displayCurrency } from '~/utilities/payment-utils';
import CustomDataGridToolbar from '~/components/common/custom-data-grid-toolbar';
import { GridColumns, DataGrid, GridCellParams } from '@mui/x-data-grid';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withAdminProfile } from '~/hooks/with-admin-profile';
import { compose } from 'redux';
import { LICENSE_TYPE_ID_STORAGE } from '~/constants/common';
import { AppRoutePath } from '~/AppRouter';
import { withAuthorization } from '~/hooks/with-authorization';
import classNames from 'classnames';

interface Props extends RouteComponentProps<{}>, WithTranslation, WithStyles<typeof styles> {}

interface StateProps {
  sales: ISalesWithCond;
  profile?: IAdminProfile;
  isLoading: boolean;
}

interface DispProps {
  listSales: (
    args: SummaryActions.QueryListSalesSummaryArgs,
  ) => Promise<SummaryActions.LIST_SALES_SUMMARY_RESULT_TYPE>;
}

interface State {
  startDate: Date;
  endDate: Date;
}

class Sales extends React.Component<Props & DispProps & StateProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('month').toDate(),
    };
  }

  async componentDidMount() {
    const { startDate, endDate } = this.state;

    this.props.listSales({
      startDate: moment(startDate).format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'),
      endDate: moment(endDate).format('YYYY-MM-DD[T]HH:mm:ss[.999Z]'),
    });
  }

  public render() {
    const { classes, t, profile, sales, isLoading } = this.props;
    const { startDate, endDate } = this.state;
    const ExtraDataKey = 'extra-data';
    const SummaryKey = 'summary';
    const timezone = profile?.preference.timezone || 'UTC';

    const items: Array<Item> = (sales.summary.licenses || []).map((sale, index) => ({
      id: `${index}`,
      licenseName: sale.licenseName,
      licenseItemId: sale.licenseItemId,
      size: sale.size,
      unitPrice: sale.unitPrice,
      qty: sale.qty,
      subTotalPrice: sale.subTotalPrice,
    }));

    const extraRows: Array<Item> = [
      {
        id: `subtotal-${ExtraDataKey}`,
        licenseName: t('sub_total'),
        subTotalPrice: sales.summary.totalPrice,
      },
      {
        id: `counpon-${ExtraDataKey}`,
        licenseName: t('amount_of_coupon_used'),
        subTotalPrice: sales.summary.totalCoupon,
      },
      {
        id: `tax-${ExtraDataKey}`,
        licenseName: t('tax'),
        subTotalPrice: sales.summary.totalTax,
      },
      {
        id: `total-${SummaryKey}-${ExtraDataKey}`,
        licenseName: t('total_sales'),
        subTotalPrice:
          sales.summary.totalPrice - sales.summary.totalCoupon + sales.summary.totalTax,
      },
    ];

    const columns: GridColumns = [
      {
        field: DataGridFieldEnum.LICENSE_NAME,
        disableColumnMenu: true,
        filterable: true,
        sortable: false,
        headerName: t('lisense_type'),
        width: 250,
        renderCell(params: GridCellParams) {
          const { licenseName, id } = params.row;
          return id.includes(ExtraDataKey) ? (
            <div
              className={classNames({
                [classes.extraDataTitle]: true,
                [classes.summaryData]: id.includes(SummaryKey),
              })}
            >
              {licenseName}
            </div>
          ) : (
            licenseName
          );
        },
      },
      {
        field: DataGridFieldEnum.SIZE,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        headerName: t('size'),
        width: 200,
        renderCell(params: GridCellParams) {
          const { licenseItemId, size } = params.row;
          return (
            <>
              {licenseItemId !== LICENSE_TYPE_ID_STORAGE ? '' : '1'} {t(size)}
            </>
          );
        },
      },
      {
        field: DataGridFieldEnum.UNIT_PRICE,
        sortable: false,
        filterable: false,
        headerName: t('price'),
        width: 200,
        valueFormatter({ value }) {
          return value ? `$ ${displayCurrency(value as number)}` : '';
        },
      },
      {
        field: DataGridFieldEnum.QTY,
        sortable: false,
        filterable: false,
        headerName: t('count_quanlity'),
        width: 200,
        valueFormatter({ value }) {
          return value || '';
        },
      },
      {
        field: DataGridFieldEnum.SUB_TOTAL_PRICE,
        sortable: false,
        filterable: false,
        headerName: t('sub_total'),
        width: 200,
        renderCell(params: GridCellParams) {
          const { subTotalPrice, id } = params.row;
          return id.includes(ExtraDataKey) ? (
            <div
              className={classNames({
                [classes.extraDataTitle]: true,
                [classes.summaryData]: id.includes(SummaryKey),
                [classes.couponData]: id.includes('counpon'),
              })}
            >
              {`${id.includes('counpon') ? '-' : ''}$ ${displayCurrency(subTotalPrice as number)}`}
            </div>
          ) : (
            `$ ${displayCurrency(subTotalPrice as number)}`
          );
        },
      },
    ];

    return (
      <Layout>
        <div className={classes.root}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Link
              component={RouterLink}
              to={AppRoutePath.Dashboard}
              color="text.primary"
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            </Link>
            <Typography color="text.primary">{t('sales')}</Typography>
          </Breadcrumbs>
          <div className={classes.datePickerArea}>
            <CustomDateRange
              label={t('current_display')}
              onChange={this.onChangeDateRange}
              value={{ startDate, endDate }}
              inputStartDate={moment
                .tz(moment(startDate).format('YYYY-MM-DD'), 'UTC')
                .startOf('day')
                .tz(timezone)
                .format(`${t('date_time_format')} (UTC)`)}
              inputEndDate={moment
                .tz(moment(endDate).format('YYYY-MM-DD'), 'UTC')
                .endOf('day')
                .tz(timezone)
                .format(`${t('date_time_format')} (UTC)`)}
            />
          </div>
          <div className={classes.dataGridWrapper}>
            <DataGrid
              rows={[...items, ...extraRows]}
              columns={columns}
              loading={isLoading}
              components={{
                Toolbar: CustomDataGridToolbar,
              }}
              classes={{ root: classes.gridRoot }}
              localeText={{
                toolbarColumns: t('data_grid.columns'),
                toolbarFilters: t('data_grid.filters'),
                toolbarDensity: t('data_grid.density'),
                filterPanelColumns: t('data_grid.columns'),
                filterPanelOperators: t('data_grid.operators'),
                columnsPanelTextFieldLabel: t('data_grid.find_column'),
                columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
                columnsPanelDragIconLabel: t('data_grid.reorder_column'),
                columnsPanelShowAllButton: t('data_grid.show_all'),
                columnsPanelHideAllButton: t('data_grid.hide_all'),
                toolbarDensityCompact: t('data_grid.density_compact'),
                toolbarDensityStandard: t('data_grid.density_standard'),
                toolbarDensityComfortable: t('data_grid.density_comfortable'),
                filterOperatorContains: t('data_grid.contains'),
                filterOperatorEquals: t('data_grid.equals'),
                filterOperatorStartsWith: t('data_grid.startsWith'),
                filterOperatorEndsWith: t('data_grid.endsWith'),
                filterOperatorIsEmpty: t('data_grid.isEmpty'),
                filterOperatorIsNotEmpty: t('data_grid.isNotEmpty'),
                filterOperatorIsAnyOf: t('data_grid.isAnyOf'),
                filterOperatorIs: t('data_grid.is'),
                filterOperatorNot: t('data_grid.isNot'),
                noRowsLabel: t('data_grid.no_rows'),
              }}
              isRowSelectable={() => false}
              checkboxSelection={false}
              hideFooter
            />
          </div>
        </div>
      </Layout>
    );
  }

  private onChangeDateRange = (ranges: any): void => {
    const { startDate, endDate } = ranges;
    this.setState({
      startDate: startDate,
      endDate: endDate,
    });
    this.props.listSales({
      startDate: moment(startDate).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'),
      endDate: moment(endDate).endOf('day').format('YYYY-MM-DD[T]HH:mm:ss[.999Z]'),
    });
  };
}

const styles = (theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: 'auto',
      paddingRight: 0,
    },
    dataGridWrapper: {
      height: 500,
      width: '100%',
      marginTop: 24,
      backgroundColor: whiteColor,
      [theme.breakpoints.up('sm')]: {
        marginTop: 32,
      },
      '& .MuiDataGrid-row': {
        maxHeight: '80px',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
    },
    gridRoot: {
      '& .MuiDataGrid-row': {
        maxHeight: 'none !important',
      },
      '& .MuiDataGrid-cell': {
        whiteSpace: 'pre-wrap',
        maxHeight: 'none !important',
        outline: 'none !important',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    datePickerSeparator: {
      marginLeft: 10,
      marginRight: 10,
      fontSize: 13,
      color: lightSlateGreyColor,
    },
    datePickerArea: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      marginTop: 4,
    },
    label: {
      marginLeft: 16,
      marginRight: 16,
      color: lightSlateGreyColor,
      fontSize: 13,
      fontWeight: 500,
    },
    contentPaper: {
      marginTop: 24,
      boxShadow: `0 2px 6px 0 ${blackRussianColor}`,
      border: `solid 1px ${gainsboroColor}`,
      backgroundColor: whiteColor,
    },
    extraDataTitle: {
      fontSize: 15,
      fontWeight: 500,
    },
    couponData: {
      color: romanColor,
    },
    summaryData: {
      color: denimColor,
    },
  });

const mapStateToProps = (store: IStore): StateProps => ({
  profile: store.appState.profile,
  sales: store.appState.sales,
  isLoading: SummaryActions.listSalesSummary.isPending(store),
});

const mapDispatchToProps = (dispatch): DispProps => ({
  listSales: (args: SummaryActions.QueryListSalesSummaryArgs) =>
    dispatch(SummaryActions.listSalesSummary(args)),
});

export default compose(
  withFirebaseUser(),
  withAdminProfile,
  withAuthorization(['owner', 'admin', 'operator', 'billing_operator']),
  withStyles(styles),
  withTranslation('admin'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Sales);

export type Item = {
  id: string;
  licenseName: string;
  licenseItemId?: number;
  size?: string;
  unitPrice?: number;
  qty?: number;
  subTotalPrice: number;
};

export enum DataGridFieldEnum {
  LICENSE_NAME = 'licenseName',
  ID = 'id',
  SIZE = 'size',
  UNIT_PRICE = 'unitPrice',
  UUID = 'uuid',
  QTY = 'qty',
  SUB_TOTAL_PRICE = 'subTotalPrice',
}
