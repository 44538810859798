import guest from './guest';
import member from './member';

export default {
  ...guest,
  ...member,
  cannot_connect_to_the_server: 'Cannot connect to the server',
  not_supported_browser_alert:
    'Your browser is not supported, please use Google Chrome, Firefox, Safari or Microsoft Edge.',
  unknow_error_occured: 'Unknown error occured',
  fail_to_create_platform_account: 'Fail to create platform account',
  fail_to_get_account_uuid: 'Fail to get accountUuid',
  account_does_not_exist: 'Account does not exist',
  member_does_not_exist_in_account: 'Member does not exist in Account',
  member_already_exist: 'Member already exist',
  member_does_not_exist: 'Member does not exist',
  there_is_only_one_owner_can_not_revoke_owner: 'There is only one owner, can not revoke owner',
  user_still_have_more_than_1_network_owner_role:
    'User still have more than 1 network role as owner. Change all network role to non-owner value.',
  user_is_the_last_owner_of_a_network: 'User is the last owner of a network',
  can_not_revoke_owner_final: 'Can not revoke owner final',
  cant_not_revoke_last_owner: 'Can not revoke last owner',
  account_name_and_email_is_already_used_and_deleted:
    'Account name and email is already used and deleted',
  you_dont_have_a_permission_to_perform_the_account: `You don't have permission to perform the action`,
  you_dont_have_a_permission_to_add_a_member_to_account: `You don't have permission to add a member to the account`,
  you_dont_have_a_permission_to_delete_the_account: `You don't have a permission to delete the account.`,
  you_cant_delete_the_account_the_account_still_has_users: `You can't delete the account. The account still has users.`,
  you_cant_delete_the_account_the_account_still_has_networks: `You can't delete the account. The account still has networks.`,
  you_cant_delete_the_account_the_account_has_unpaid_bill: `You can't delete the account. The account has unpaid bill.`,
  invitation_rejection_may_not_completed: 'Invitation rejection may not completed',
  cannot_remove_yourself: 'Cannot remove yourself',
  can_not_add_member: 'Can not add member',
  can_not_update_member: 'Can not update member',
  update_member_success: 'Update member success',
  can_not_remove_member: 'Can not remove member',
  remove_member_success: 'Remove member success',
  change_primary_owner_success: 'change primary owner success',
  can_not_update_account: 'Can not update account',
  update_account_success: 'Update account success',
  can_not_list_providers: 'Can not list providers',
  can_not_list_networks: 'Can not list networks',
  can_not_update_network_status: 'Can not update network status',
  can_not_get_account: 'Can not get account',
  can_not_get_network: 'Can not get network',
  can_not_get_network_role: 'Can not get network role',
  can_not_create_network: 'Can not create network',
  can_not_delete_network: 'Can not delete network',
  network_not_found_or_denied_to_delete: 'Network not found or denied to delete',
  delete_network_started: 'Destroy network started',
  can_not_create_cluster: 'Can not create cluster',
  can_not_delete_cluster: 'Can not delete cluster',
  cluster_not_found_or_not_empty: 'Cluster not found or not empty',
  create_cluster_started: 'Create cluster started',
  delete_cluster_started: 'Delete cluster started',
  can_not_create_node: 'Can not create node',
  can_not_update_node: 'Can not update node',
  can_not_delete_node: 'Can not delete node',
  node_not_found_or_validator: 'Node not found or signer',
  delete_node_started: 'Delete node started',
  can_not_control_node: 'Can not control node',
  can_not_list_account_on_network: 'Can not list account own network',
  can_not_list_network_accesses: 'Can not list network accesses',
  grant_network_role_success: 'Grant network role success',
  can_not_revoke_network_role: 'Can not revoke network role',
  revoke_network_role_success: 'Revoke network role success',
  can_not_list_alerts: 'Can not list alerts',
  can_not_list_unread_alerts: 'Can not list unread alerts',
  can_not_list_new_unread_alerts: 'Can not list new unread alerts',
  can_not_set_alert_readed: 'Can not set alert readed',
  can_not_set_all_alert_readed: 'Can not set all alert readed',
  can_not_send_invitation: 'Can not send invitation',
  send_invitation_success: 'Send invitation success',
  can_not_cancel_invitation: 'Can not cancel invitation',
  cancel_invitation_success: 'Cancel invitation success',
  can_not_response_invitation: 'Can not response invitation',
  accept_invitation_success: 'Accept invitation success',
  reject_invitation_success: 'Reject invitation success',
  can_not_get_network_status: 'Can not get network status',
  can_not_create_account: 'Can not create account',
  create_account_success: 'Create account success',
  can_not_remove_account: 'Can not remove account',
  remove_account_success: 'Remove account success',
  can_not_list_proposals: 'Can not list proposals',
  can_not_create_proposal: 'Can not create proposal',
  create_proposal_success: 'Create proposal success',
  can_not_vote_proposal: 'Can not vote proposal',
  vote_proposal_success: 'Vote proposal success',
  can_not_list_active_licenses: 'Can not list active licenses',
  can_not_list_active_licenses_summary: 'Can not list active licenses summary',
  can_not_list_available_licenses: 'Can not list available licenses',
  can_not_list_insufficient_licenses: 'Can not list insufficient licenses',
  can_not_list_insufficient_cluster_licenses: 'Can not list insufficient cluster licenses',
  can_not_list_insufficient_node_licenses: 'Can not list insufficient node licenses',
  can_not_list_insufficient_node_server_licenses: 'Can not list insufficient node server licenses',
  can_not_list_insufficient_volume_licenses: 'Can not list insufficient volume licenses',
  can_not_estimate_licenses: 'Can not estimate licenses',
  can_not_list_billings: 'Can not list billings',
  can_not_list_coupons: 'Can not list coupons',
  can_not_list_cards: 'Can not list cards',
  card_registration_required: 'Card registration required',
  card_registration_failed: 'Card registration failed',
  card_registration_successfully: 'Card registration success',
  failed_to_purchase_licenses: 'Failed to purchase licenses',
  unmatched_coupon_balance: 'Unmatched coupon balance',
  licenses_purchased_successfully: 'Licenses purchased successfully',
  failed_to_cancel_licenses_purchase: 'Failed to cancel license purchase',
  licenses_purchased_canceled_successfully: 'Licenses purchase canceled successfully',
  can_not_list_restrictions: 'Can not list restrictions',
  can_not_set_restrictions: 'Can not set restrictions',
  can_not_set_node_server_type: 'Can not set node server type',
  changing_node_server_type_started: 'Changing node server type started',
  failed_to_set_node_server_type: 'Failed to set node server type',
  can_not_expand_node_volume_size: 'Can not expand node data volume size',
  expanding_volume_size_started: 'Expanding data volume size started',
  failed_to_expand_node_volume_size: 'Failed to expand data volume size',
  can_not_get_node_metrics: 'Can not get node metrics',
  can_not_get_node_log: 'Can not get node log',
  can_not_get_block_explorer_metrics: 'Can not get block explorer metrics',
  can_not_get_block_explorer_log: 'Can not get block explorer log',
  add_member_success: 'Add member success',
  specified_uid_not_registered_in_system: 'The specified uid is not registered in this system.',
  specified_email_not_registered_in_system:
    'The specified email address is not registered in this system.',
  memmber_already_exist: 'Member already exist',
  network_role_can_not_grant: 'Can not grant network role',
  network_can_not_update: 'Can not update network status',
  'network-updated-successfully': 'Network updated successfully',
  'cluster-updated-successfully': 'Cluster updated successfully',
  'cluster-added-successfully': 'Cluster added successfully',
  'node-updated-successfully': 'Node updated successfully',
  'node-added-successfully': 'Node added successfully',
  update_profile_successfully: 'Profile updated successfully',
  update_restriction_successfully: 'Restrictions updated successfully',
  'custom-domain-added-successfully': 'Custom domain added successfully',
  'can-not-add-custom-domain': 'Can not add custom domain',
  'custom-domain-removed-successfully': 'Custom domain removed successfully',
  'can-not-remove-custom-domain': 'Can not remove custom domain',
  unauthenticated_error:
    'You do not have permission to view this site. If this page is displayed even if you have been granted the authority, please contact us using the following.',
  invoice_no: 'Invoice No',
  can_not_update_node_version: 'Can not change node version',
  update_node_version_failed: 'Change node version failed',
  update_node_version_started: 'Change node version started',
  can_not_update_block_gas_limit: 'Unable to update the block gas limit',
  update_gas_limit_start: 'Update the block gas limit started',
  can_not_update_latest_hard_fork: 'Unable to update the latest hard fork',
  update_latest_hard_fork_start: 'Update the latest hard fork start',
  can_not_get_genesis_json: 'Unable to take network genesis.json',
  reboot_command_sent: 'Reboot command sent',
  rebuild_command_sent: 'Rebuild command sent',
  admin_is_created_successfully: 'Administrator is created successfully',
  admin_is_updated_successfully: 'Administrator is updated successfully',
  admin_is_deleted_successfully: 'Administrator is deleted successfully',
  notification_not_found: 'Notification not found',
};
