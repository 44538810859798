import React from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IStore } from '~/stores/configure-store';
import HomeIcon from '@mui/icons-material/Home';
import Layout from '~/components/common/Layout';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CustomDataGridToolbar from '~/components/common/custom-data-grid-toolbar';
import Typography from '@mui/material/Typography';
import LastLoginFilter from './last-login-filter';
import Link from '@mui/material/Link';
import * as UserActions from '~/stores/actions/user-action';
import {
  DataGrid,
  GridColumns,
  GridFilterModel,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import { ROWS_PER_PAGE_OPTIONS, DATA_GRID_SORTING_ORDER } from '~/constants/common';
import { OrderByEnum } from '~/enums/common';
import { ListAccountsOrderEnum, ListUserOrderEnum, QueryListUsersArgs } from '~/gapi/gtypes';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withAdminProfile } from '~/hooks/with-admin-profile';
import { compose } from 'redux';
import { AppRoutePath } from '~/AppRouter';
import { withAuthorization } from '~/hooks/with-authorization';
import { IUserList } from '~/types/user-types';
import { whiteColor } from '~/styles/themes/common-styles/color';

interface IStateProps {
  userList: IUserList;
  isLoading: boolean;
}

interface IDispProps {
  listUsers: (args: QueryListUsersArgs) => Promise<UserActions.LIST_USERS>;
}

interface Props
  extends IStateProps,
    IDispProps,
    RouteComponentProps<{}>,
    WithTranslation,
    WithStyles<typeof styles> {}

interface State {
  filterModel?: GridFilterModel;
  searchText: string;
}

class Users extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      filterModel: {
        items: [
          {
            columnField: DataGridFieldEnum.LAST_LOGIN_AT,
            operatorValue: 'contains',
          },
        ],
      },
      searchText: '',
    };
  }

  async componentDidMount() {
    const { userList } = this.props;
    if (userList?.filter?.searchText) {
      this.setState({
        searchText: userList.filter.searchText,
      });
    }
    try {
      await this.props.listUsers({
        page: { pageIndex: 0 },
        filter: {
          lastloginAt: {
            endDate: moment().endOf('days').toISOString(),
          },
        },
      });
    } catch (error) {}
  }

  public render() {
    const { classes, t, userList, isLoading } = this.props;
    const { totalItems, pageIndex: page, pageSize } = userList.pageInfo;
    const { orderBy, orderType } = userList.order ?? {};
    const { filterModel, searchText } = this.state;

    const columns: GridColumns = [
      {
        field: DataGridFieldEnum.NAME,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        headerName: t('display_name'),
        width: 250,
      },
      {
        field: DataGridFieldEnum.EMAIL,
        disableColumnMenu: true,
        sortable: true,
        filterable: false,
        headerName: t('contact_email'),
        width: 300,
      },
      {
        field: DataGridFieldEnum.CREATE_ORG_ACCOUNT_LITMIT,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        headerName: t('create_org_account_limit'),
        width: 200,
      },
      {
        field: DataGridFieldEnum.LAST_LOGIN_AT,
        disableColumnMenu: true,
        sortable: true,
        filterable: true,
        headerName: t('last_login_at'),
        valueFormatter: ({ value }) => t('user_createAt', { date: value }),
        width: 250,
        filterOperators: [
          {
            label: t('from'),
            value: 'contains',
            InputComponent: LastLoginFilter,
            getApplyFilterFn: () => null,
          },
        ],
      },
      {
        field: DataGridFieldEnum.CREATED_AT,
        disableColumnMenu: true,
        sortable: true,
        filterable: false,
        headerName: t('create_date'),
        valueFormatter: ({ value }) => t('create_date_value', { date: value }),
        width: 200,
      },
    ];

    const items: Array<Item> = (userList.users || []).map((user) => ({
      id: user.uid,
      name: user.name,
      email: user.email,
      createOrgAccountLimit: user.createOrgAccountLimit,
      lastloginAt: user.lastloginAt ? new Date(user.lastloginAt) : undefined,
      createAt: user.createAt ? new Date(user.createAt) : undefined,
    }));

    const sortModel = orderType &&
      orderBy && [
        {
          field: orderBy,
          sort: orderType === OrderByEnum.Desc ? 'desc' : ('asc' as GridSortDirection),
        },
      ];

    return (
      <Layout>
        <div className={classes.root}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Link
              component={RouterLink}
              to={AppRoutePath.Dashboard}
              color="text.primary"
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            </Link>
            <Typography color="text.primary">{t('users')}</Typography>
          </Breadcrumbs>
          <div className={classes.dataGridWrapper}>
            <DataGrid
              rows={items}
              columns={columns}
              components={{
                Toolbar: CustomDataGridToolbar,
              }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  placeholder: t('users_search_placeholder'),
                  isQuickSearch: true,
                  onChange: (event) => this.onSearchInputChange(event.target.value),
                  clearSearch: () => this.onSearchInputChange(''),
                },
              }}
              classes={{ root: classes.gridRoot }}
              localeText={{
                toolbarColumns: t('data_grid.columns'),
                toolbarFilters: t('data_grid.filters'),
                toolbarDensity: t('data_grid.density'),
                filterPanelColumns: t('data_grid.columns'),
                filterPanelOperators: t('data_grid.operators'),
                columnsPanelTextFieldLabel: t('data_grid.find_column'),
                columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
                columnsPanelDragIconLabel: t('data_grid.reorder_column'),
                columnsPanelShowAllButton: t('data_grid.show_all'),
                columnsPanelHideAllButton: t('data_grid.hide_all'),
                toolbarDensityCompact: t('data_grid.density_compact'),
                toolbarDensityStandard: t('data_grid.density_standard'),
                toolbarDensityComfortable: t('data_grid.density_comfortable'),
                filterOperatorContains: t('data_grid.contains'),
                filterOperatorEquals: t('data_grid.equals'),
                filterOperatorStartsWith: t('data_grid.startsWith'),
                filterOperatorEndsWith: t('data_grid.endsWith'),
                filterOperatorIsEmpty: t('data_grid.isEmpty'),
                filterOperatorIsNotEmpty: t('data_grid.isNotEmpty'),
                filterOperatorIsAnyOf: t('data_grid.isAnyOf'),
                filterOperatorIs: t('data_grid.is'),
                filterOperatorNot: t('data_grid.isNot'),
                noRowsLabel: t('data_grid.no_rows'),
              }}
              isRowSelectable={() => false}
              checkboxSelection={false}
              loading={isLoading}
              paginationMode="server"
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              rowCount={totalItems || 0}
              pageSize={pageSize}
              page={page}
              onPageChange={this.onPageChange}
              onPageSizeChange={this.onPageSizeChange}
              sortingMode="server"
              sortingOrder={DATA_GRID_SORTING_ORDER}
              sortModel={sortModel}
              onSortModelChange={this.onSortModelChange}
              filterMode="server"
              filterModel={filterModel}
              onFilterModelChange={this.onFilterModelChange}
            />
          </div>
        </div>
      </Layout>
    );
  }

  private getFilterLastLogin = (model: GridFilterModel) => {
    const modelItem = model.items.find(
      (val) => val.columnField === DataGridFieldEnum.LAST_LOGIN_AT,
    );
    switch (modelItem?.value) {
      case LastLoginFilterEnum.ThisMonth:
        return moment().startOf('month').toISOString();
      case LastLoginFilterEnum.LastWeek:
        return moment().subtract(1, 'week').startOf('days').toISOString();
      case LastLoginFilterEnum.LastMonth:
        return moment().subtract(1, 'month').startOf('days').toISOString();
      case LastLoginFilterEnum.LastQuarter:
        return moment().subtract(1, 'quarter').startOf('days').toISOString();
      case LastLoginFilterEnum.LastYear:
        return moment().subtract(1, 'year').startOf('days').toISOString();
      default:
        return undefined;
    }
  };

  private onFilterModelChange = (model: GridFilterModel) => {
    this.setState({
      filterModel: model,
    });
    this.props
      .listUsers({
        filter: {
          lastloginAt: {
            startDate: this.getFilterLastLogin(model),
            endDate: moment().endOf('days').toISOString(),
          },
        },
        page: { pageIndex: 0 },
      })
      .catch(console.log);
  };

  private onSortModelChange = (model: GridSortModel) => {
    this.props
      .listUsers({
        order: model[0] && {
          orderBy: model[0].field as ListUserOrderEnum,
          orderType: model[0].sort === 'asc' ? OrderByEnum.Asc : OrderByEnum.Desc,
        },
        page: { pageIndex: 0 },
      })
      .catch(console.log);
  };

  private onPageSizeChange = (pageSize) => {
    this.props.listUsers({
      page: { pageIndex: 0, pageSize },
    });
  };

  private onPageChange = (page) => {
    this.props.listUsers({
      page: { pageIndex: page },
    });
  };

  private onSearchInputChange = (value) => {
    this.setState({
      searchText: value,
    });
    this.debouncedOnSearchInputChange(value);
  };

  private debouncedOnSearchInputChange = _.debounce((searchText: string) => {
    const { listUsers } = this.props;
    listUsers({
      filter: { searchText },
      page: { pageIndex: 0 },
    }).catch(console.log);
  }, 300);
}

const styles = (theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: 'auto',
      paddingRight: 0,
    },
    dataGridWrapper: {
      height: 500,
      width: '100%',
      marginTop: 24,
      backgroundColor: whiteColor,
      [theme.breakpoints.up('sm')]: {
        marginTop: 32,
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
    },
    gridRoot: {
      '& .MuiDataGrid-row': {
        maxHeight: 'none !important',
      },
      '& .MuiDataGrid-cell': {
        whiteSpace: 'pre-wrap',
        maxHeight: 'none !important',
        outline: 'none !important',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    topLine: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'end',
    },
    button: {
      margin: 0,
    },
  });

const mapStateToProps = (store: IStore) => ({
  userList: store.appState.userList,
  isLoading: UserActions.listUsers.isPending(store),
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  listUsers: (args: QueryListUsersArgs) => dispatch(UserActions.listUsers(args)),
});

export default compose(
  withFirebaseUser(),
  withAdminProfile,
  withAuthorization(['owner', 'admin', 'operator']),
  withStyles(styles),
  withTranslation('admin'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Users);

export type Item = {
  id: string;
  name: string;
  email: string;
  createOrgAccountLimit: number;
  lastloginAt?: Date;
  createAt?: Date;
};

export enum DataGridFieldEnum {
  EMAIL = 'email',
  NAME = 'name',
  CREATE_ORG_ACCOUNT_LITMIT = 'createOrgAccountLimit',
  LAST_LOGIN_AT = 'lastloginAt',
  CREATED_AT = 'createAt',
}

enum LastLoginFilterEnum {
  ThisMonth = 'this_month',
  LastWeek = 'last_week',
  LastMonth = 'last_month',
  LastQuarter = 'last_quarter',
  LastYear = 'last_year',
}

export const LastLoginFilterSelections: Array<{ label: string; value: LastLoginFilterEnum }> = [
  { label: LastLoginFilterEnum.ThisMonth, value: LastLoginFilterEnum.ThisMonth },
  { label: LastLoginFilterEnum.LastWeek, value: LastLoginFilterEnum.LastWeek },
  { label: LastLoginFilterEnum.LastMonth, value: LastLoginFilterEnum.LastMonth },
  { label: LastLoginFilterEnum.LastQuarter, value: LastLoginFilterEnum.LastQuarter },
  { label: LastLoginFilterEnum.LastYear, value: LastLoginFilterEnum.LastYear },
];
