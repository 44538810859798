// React, Redux
import React from 'react';
// Component
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Theme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { container } from '~/styles/themes/common-styles/grid-member';
import { denimColor, whiteColor } from '~/styles/themes/common-styles/color';
// Type
import { withTranslation, WithTranslation } from 'react-i18next';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { parseBCP47String } from '~/utilities/utils';
import { SelectChangeEvent } from '@mui/material/Select';
import { defaultFont } from '~/styles/themes/common-styles/font-guest';
import { Link } from 'react-router-dom';
import { AppRoutePath } from '~/AppRouter';

interface IProps extends WithStyles<typeof styles>, WithTranslation {}

const Header: React.FC<IProps> = ({ classes, t, i18n }) => {
  const [openSelectLng, setopenSelectLng] = React.useState(false);

  return (
    <>
      <div className={classes.header}>
        <AppBar position="fixed" className={classes.appBar}>
          <div className={classes.container}>
            <Toolbar classes={{ root: classes.toolBar }}>
              <Link to={AppRoutePath.Dashboard}>
                <img className={classes.logo} src="/images/icons/logo_guest.svg" alt="" />
              </Link>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <Select
                    open={openSelectLng}
                    onClose={() => setopenSelectLng(false)}
                    onOpen={() => setopenSelectLng(true)}
                    classes={{
                      select: clsx(classes.customSelect, classes.selectMenu),
                    }}
                    value={parseBCP47String(i18n.language).language || 'en'}
                    onChange={(event: SelectChangeEvent) => {
                      i18n.changeLanguage(event.target.value as string);
                      Cookies.set('lang', event.target.value);
                    }}
                    disableUnderline
                    renderValue={(value: any) => value.toLocaleUpperCase()}
                    inputProps={{ 'aria-label': 'Without label' }}
                    variant="standard"
                  >
                    <MenuItem
                      classes={{ root: classes.menuItem, selected: classes.selectedItem }}
                      value="en"
                    >
                      English
                    </MenuItem>
                    <MenuItem
                      classes={{ root: classes.menuItem, selected: classes.selectedItem }}
                      value="ja"
                    >
                      日本語
                    </MenuItem>
                  </Select>
                </ListItem>
              </List>
            </Toolbar>
          </div>
        </AppBar>
      </div>
    </>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    header: {
      position: 'sticky',
      top: 0,
      zIndex: 10,
      minHeight: 60,
    },
    container,
    appBar: {
      backgroundColor: whiteColor,
      boxShadow: 'none',
      borderBottom: '1px solid rgba(112, 112, 112, 0.3)',
    },
    toolBar: {
      height: 60,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 0,
      paddingRight: 0,
      minHeight: 60,
    },
    logo: {
      width: 240,
    },
    linkItem: {},
    list: {
      ...defaultFont,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 0,
      paddingLeft: '0',
      listStyle: 'none',
      paddingTop: '0',
      paddingBottom: '0',
      '@media (max-width: 970px)': {
        flexDirection: 'column',
        paddingTop: 8,
        marginTop: 20,
        borderTop: '1px solid #f5f5f5',
      },
    },
    listItem: {
      float: 'left',
      color: 'inherit',
      position: 'relative',
      display: 'block',
      width: 'auto',
      margin: '0',
      paddingLeft: 12,
      paddingRight: 12,
      '@media (max-width: 970px)': {
        padding: '5px 10px',
      },
    },
    menuItem: {
      padding: '7px 16px !important',
      height: '100%',
      '&:hover': {
        color: denimColor,
      },
      display: 'inherit !important',
    },
    customSelect: {
      marginLeft: 0,
      display: 'flex',
    },
    selectMenu: {
      paddingLeft: 0,
      paddingRight: '32px !important',
      width: 21,
      '&:focus': {
        backgroundColor: 'transparent',
      },
      fontSize: 14,
      fontWeight: 700,
    },
    selectedItem: {
      backgroundColor: '#FFFFFF !important',
      color: `${denimColor} !important`,
    },
  });

export default withStyles(styles)(withTranslation()(Header));
