import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { whiteColor, nightRiderColor, pattensBlueColor } from '~/styles/themes/common-styles/color';

interface IProps extends WithTranslation, WithStyles<typeof styles> {
  value?: 'signer' | 'transaction';
  onChange: (value?: 'signer' | 'transaction') => void;
}

interface IState {
  anchorEl: HTMLElement | null;
}

class NodeTypeSelector extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  render() {
    const { classes, value = 'all' } = this.props;
    const label = (ITEMS.find((item) => item.value === value) || {}).label;

    return (
      <>
        {this.popover}
        <div className={classes.root} onClick={this.handleOpenMenu}>
          <span>{label}</span>
          <img src="/images/icons/down_ico_off.svg" alt="" />
        </div>
      </>
    );
  }

  private get popover() {
    const { classes, value = 'all' } = this.props;
    const { anchorEl } = this.state;
    return (
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={this.handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: classes.popoverPaper }}
      >
        <List className={classes.popoverList}>
          {ITEMS.map((item) => (
            <ListItem
              key={item.value}
              button={true}
              className={classes.popoverListItem}
              onClick={this.onListItemClick(item.value)}
            >
              <img
                className={classNames(
                  classes.checkIcon,
                  item.value === value && classes.selectedIcon,
                )}
                src="/images/icons/check_icon.svg"
                alt=""
              />
              {item.label}
            </ListItem>
          ))}
        </List>
      </Popover>
    );
  }

  onListItemClick = (value: IValue) => () => {
    this.props.onChange(value === 'all' ? undefined : value);
    this.handleCloseMenu();
  };

  private handleOpenMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  private handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };
}

const styles = createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: whiteColor,
    border: `1px solid ${pattensBlueColor}`,
    borderRadius: 4,
    height: 32,
    width: 200,
    paddingLeft: 20,
    paddingRight: 10,
    fontSize: 14,
    cursor: 'pointer',
  },
  popoverPaper: {},
  popoverList: {},
  popoverListItem: {
    width: 200,
    fontSize: 15,
    color: nightRiderColor,
    paddingTop: 8,
    paddingBottom: 8,
  },
  checkIcon: {
    visibility: 'hidden',
    marginRight: 15,
    width: 14,
    height: 14,
  },
  selectedIcon: {
    visibility: 'visible',
  },
});

export default withStyles(styles)(withTranslation()(NodeTypeSelector));

type IValue = 'all' | 'signer' | 'transaction';

const ITEMS: Array<{ label: string; value: IValue }> = [
  { label: 'All Type', value: 'all' },
  { label: 'Validator Node', value: 'signer' },
  { label: 'Relay Node', value: 'transaction' },
];
