import React from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
// Route
import { withRouter, RouteComponentProps } from 'react-router-dom';
// Material UI style difinition
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  whiteColor,
  blackRussianColor,
  brightGreyColor,
  hawkesBlueColor,
  denimColor,
} from '~/styles/themes/common-styles/color';
import { defaultFontBold, defaultFontMedium } from '~/styles/themes/common-styles/font';
import { IStore } from '~/stores/configure-store';
import Barchart from '~/components/common/bar-chart';
import CustomSelect from '~/components/common/custom-select-with-label';
import * as StatsActions from '~/stores/actions/stats-action';
import { ICountStats } from '~/types/admin-types';
import { SelectChangeEvent } from '@mui/material/Select';
import { getYearOptions } from '~/utilities/moment-utils';
import Box from '@mui/material/Box';

// defines

interface IStateProps {
  userStats: ICountStats;
  isLoading: boolean;
}

interface IDispProps {
  getUserStats: (year: string) => Promise<StatsActions.GET_ACCOUNT_STATS_RESULT_TYPE>;
}

interface Props
  extends IStateProps,
    IDispProps,
    RouteComponentProps<{}>,
    WithTranslation,
    WithStyles<typeof styles> {}

interface IState {}

interface IData {
  key: string;
  value: number;
}

class AccountChart extends React.Component<Props, IState> {
  async componentDidMount() {
    const { userStats, getUserStats } = this.props;

    if (!userStats.stats) {
      getUserStats(userStats.year.toString());
    }
  }

  public render() {
    const { classes, t } = this.props;
    const { year, stats } = this.props.userStats;
    const data = this.groupChartData();
    const total = (stats || []).map((item) => item.count).reduce((a, b) => a + b, 0);
    const max = Math.max(...(stats || []).map((item) => item.count));

    return (
      <Box className={classes.chartBox} boxShadow={1} padding={2} borderRadius={1}>
        <div className={classes.heading}>
          <p className={classes.textTitle}>{t('new_users')}</p>
          <div className={classes.optionBtnArea}>
            <CustomSelect
              items={yearOptions}
              classes={{ root: classes.optionBtn }}
              onChange={this.selectTimeFilterAccount}
              valueSelected={year}
            />
          </div>
        </div>
        <div className={classes.textTotal}>
          {t('total')}: {total}
        </div>
        <Barchart data={data} maxYAxesValue={max} cornerRadius={4} />
      </Box>
    );
  }

  private groupChartData(): Array<IData> {
    const { stats } = this.props.userStats;

    return Array.from(Array(12).keys()).map((value) => {
      const result = {
        key: moment.months(value).slice(0, 3),
        value: 0,
      };
      const monthStats = (stats || []).find((item) => moment(item.key).month() === value);
      if (monthStats) {
        result.value = monthStats.count;
      }
      return result;
    });
  }

  private selectTimeFilterAccount = (event: SelectChangeEvent) => {
    this.props.getUserStats(event.target.value);
  };
}

const styles = createStyles({
  chartBox: {
    minWidth: 370,
    padding: 20,
    backgroundColor: whiteColor,
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textTitle: {
    ...defaultFontMedium,
    color: brightGreyColor,
    fontSize: 16,
  },
  textTotal: {
    ...defaultFontBold,
    fontSize: 15,
    color: denimColor,
    marginBottom: 15,
  },
  optionBtnArea: {
    display: 'flex',
  },
  optionBtn: {
    ...defaultFontMedium,
    minWidth: 92,
    color: brightGreyColor,
    fontSize: 13,
    border: `1px solid ${hawkesBlueColor}`,
    borderRadius: 4,
    boxShadow: `0 2px 3px 0 ${blackRussianColor}`,
    textTransform: 'none',
    '& img': {
      marginLeft: 13.5,
    },
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  userStats: store.appState.userStats,
  isLoading: StatsActions.getUserStats.isPending(store),
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  getUserStats: (year: string) => dispatch(StatsActions.getUserStats(year)),
});

export default withStyles(styles)(
  withTranslation('admin')(withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountChart))),
);

const yearOptions = getYearOptions().map((item) => ({ label: item, value: item }));
