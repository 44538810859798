import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableSortLabel from '@mui/material/TableSortLabel';
import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { container } from '~/styles/themes/common-styles/grid-member';
import {
  defaultFont,
  defaultFontBold,
  defaultFontExtraBold,
} from '~/styles/themes/common-styles/font';
import {
  lightSlateGreyColor,
  whiteSmokeColor,
  nightRiderColor,
  dodgerBlue4Color,
  romanColor,
  veryLightGreyColor,
  midGreyColor,
} from '~/styles/themes/common-styles/color';
import { connect } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import moment from 'moment-timezone';
// Component
import TableHeadCustom from './table-head';
import TableCellHeadCustom from './table-head-cell';
import TableRowHeadCustom from './table-head-row';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import RemoveTwoToneIcon from '@mui/icons-material/RemoveTwoTone';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Type
import { IAccountSummary, IBillingSummary } from '~/types/admin-types';
import { calculateSubtotal, displayCurrency } from '~/utilities/payment-utils';
import {
  DEFAULT_COUNTRY_CODE,
  INVOICE_NO,
  LICENSE_TYPE_ID_STORAGE,
  PAYMENT_TYPE_RESULT_FAIL,
  TAX_PERCENTAGE,
} from '~/constants/common';

interface IStateProps {
  billingHistory?: IBillingSummary;
}

interface IDispProps {}

interface IProps
  extends IStateProps,
    IDispProps,
    RouteComponentProps<{ accountUuid: string; subtab: string }>,
    WithStyles<typeof styles>,
    WithTranslation {
  account: IAccountSummary;
}

interface IState {
  expanded: Array<Boolean>;
}

class BillingDateTable extends React.Component<
  IProps & RouteComponentProps & WithStyles<typeof styles> & WithTranslation,
  IState
> {
  constructor(props) {
    super(props);
    this.state = {
      expanded: [],
    };
  }

  handleAccordionToggleOne = (index) => () => {
    const { expanded } = this.state;
    expanded[index] = !expanded[index];
    this.setState({ expanded });
  };

  public render() {
    const { classes, t, billingHistory } = this.props;
    const { subtab } = this.props.match.params;

    const dateBillings =
      (billingHistory &&
        billingHistory.details.filter((d) => {
          return moment(d.invoicedDate).utc().startOf('month').format('YYYY-MM') === subtab;
        })) ||
      [];

    return (
      <>
        <Table>
          <colgroup>
            <col width="16%" />
            <col width="26%" />
            <col width="24%" />
            <col width="34%" />
          </colgroup>
          <TableHeadCustom>
            <TableRowHeadCustom>
              <TableCellHeadCustom>
                <span className={classes.CellHeadCustom}>{t('billing_date')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom>
                <span className={classes.CellHeadCustom}>{t('billing_id')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom>
                <span className={classes.CellHeadCustom}>{t('payment_status')}</span>
              </TableCellHeadCustom>
              <TableCellHeadCustom>
                <TableSortLabel className={classes.iconColor}></TableSortLabel>
                <span className={classes.CellHeadCustom}>{t('billing_amount')}</span>
              </TableCellHeadCustom>
            </TableRowHeadCustom>
          </TableHeadCustom>
        </Table>
        {dateBillings.map((bill, i) => (
          <Accordion
            key={i}
            className={classes.customPanel}
            onChange={this.handleAccordionToggleOne(i)}
          >
            <AccordionSummary
              expandIcon={this.state.expanded[i] ? <RemoveTwoToneIcon /> : <AddTwoToneIcon />}
              className={classes.customPanelSumary}
              classes={{
                content: classes.customPanelSumaryContent,
              }}
            >
              <Typography
                variant="body2"
                className={classNames(classes.textPanelSumary, classes.paddingLeft)}
              >
                {moment(bill.invoicedDate).utc().format('YYYY/MM/DD')}
              </Typography>
              <Typography variant="body2" className={classes.billIdPanelSumary}>
                {bill.id}
              </Typography>
              <Typography variant="body2" className={classes.billIdPanelSumary}>
                {bill.result === PAYMENT_TYPE_RESULT_FAIL
                  ? t('payment_failed')
                  : Boolean(bill.paidDate)
                  ? t('paid')
                  : t('unpaid')}
              </Typography>
              <Typography variant="body2" className={classes.textPanelSumary}>
                $ {displayCurrency(bill.amount + bill.tax - bill.coupon)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.customPanelDetail}>
              <table className={classes.tableDetail}>
                <thead>
                  <tr className={classes.textHeading}>
                    <th>{t('license_item_id')}</th>
                    <th>{t('license_name')}</th>
                    <th>{t('size')}</th>
                    <th>{t('unit_price')}</th>
                    <th>{t('quantity')}</th>
                    <th>{t('sub_total')}</th>
                  </tr>
                </thead>
                <tbody>
                  {bill.licenses.map((license, n) => (
                    <tr className={classes.textDetails} key={n}>
                      <td>{license.licenseItemId}</td>
                      <td>{license.licenseName}</td>
                      <td>
                        {license.licenseItemId !== LICENSE_TYPE_ID_STORAGE ? '' : '1'}
                        {t(license.size)}
                      </td>
                      <td>$ {displayCurrency(license.unitPrice)}</td>
                      <td>{license.qty}</td>
                      <td>$ {displayCurrency(license.amount)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      colSpan={5}
                      className={classNames(
                        classes.tableCellBoldTypeCustom,
                        classes.topBorder,
                        classes.bottomBorder,
                      )}
                    >
                      {t('sub_total')}
                    </td>
                    <td
                      className={classNames(
                        classes.tableCellBoldTypeCustom,
                        classes.topBorder,
                        classes.bottomBorder,
                      )}
                    >
                      $ {displayCurrency(bill.amount)}
                    </td>
                  </tr>
                  <tr className={classes.textDetails}>
                    <td colSpan={5}>{t('amount_of_coupon_used')}</td>
                    <td className={classes.couponValue}>{`-$ ` + displayCurrency(bill.coupon)}</td>
                  </tr>
                  <tr className={classes.textDetails}>
                    <td colSpan={5}>{`${t('tax')} (${TAX_PERCENTAGE})`}</td>
                    <td>$ {displayCurrency(bill.tax)}</td>
                  </tr>
                  <tr>
                    <td colSpan={5} className={classNames(classes.total, classes.topBorder)}>
                      {t('billing_amount')}
                    </td>
                    <td className={classNames(classes.total, classes.topBorder)}>
                      $ {displayCurrency(bill.amount + bill.tax - bill.coupon)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    );
  }
}

const styles = createStyles({
  container,
  customCellHead: {
    paddingRight: '0 !important',
  },
  CellHeadCustom: {
    ...defaultFontExtraBold,
    color: `${nightRiderColor} !important`,
  },
  customPanel: {
    margin: 0,
    boxShadow: `0 1px 1px ${whiteSmokeColor}`,
  },
  customPanelSumary: {
    padding: '10px 0',
    margin: 0,
    borderBottom: `1px dotted ${whiteSmokeColor}`,
  },
  customPanelSumaryContent: {
    margin: '12px 0 !important',
  },
  customPanelDetail: {
    display: 'block',
    padding: '8px 0',
    borderBottom: `1px solid ${whiteSmokeColor}`,
  },
  textPanelSumary: {
    ...defaultFont,
    flexBasis: '18%',
  },
  billIdPanelSumary: {
    ...defaultFont,
    flexBasis: '27%',
  },
  paddingLeft: {
    paddingLeft: 20,
  },
  tableDetail: {
    width: '100%',
    paddingLeft: '17%',
    borderSpacing: 0,
  },
  textHeading: {
    '& th': {
      ...defaultFontBold,
      fontSize: 13,
      color: lightSlateGreyColor,
    },
  },
  textDetails: {
    '& td': {
      ...defaultFont,
      fontSize: 15,
      color: nightRiderColor,
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  tableCellBoldTypeCustom: {
    ...defaultFontBold,
    color: nightRiderColor,
    paddingTop: 10,
    paddingBottom: 10,
  },
  topBorder: {
    borderTop: `1px solid ${veryLightGreyColor}`,
  },
  bottomBorder: {
    borderBottom: `1px solid ${veryLightGreyColor}`,
  },
  couponValue: {
    color: `${romanColor} !important`,
  },
  total: {
    ...defaultFontBold,
    color: `${dodgerBlue4Color} !important`,
    paddingTop: 10,
    paddingBottom: 10,
  },
  iconColor: {
    color: midGreyColor,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  billingHistory: store.appState.licenseSummary.history,
});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  withRouter(
    withTranslation('admin')(connect(mapStateToProps, mapDispatchToProps)(BillingDateTable)),
  ),
);
