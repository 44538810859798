import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';
import CustomDataGridToolbar from '~/components/common/custom-data-grid-toolbar';
import { AccountRoleType, IAccountMember, IAccountSummary } from '~/types/admin-types';
import { romanColor, whiteColor } from '~/styles/themes/common-styles/color';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import AddAccountMemberDialog from './add-account-member-dialog';
import AccountMemberOption from './member-options';

interface Props
  extends RouteComponentProps<{ accountUuid: string }>,
    WithStyles<typeof styles>,
    WithTranslation {
  isLoading: boolean;
  members?: Array<IAccountMember>;
  account: IAccountSummary;
  listAccountMembers: () => void;
}

interface State {
  searchText: string;
  openAddMemberDialog: boolean;
}

class AccountDetailMembers extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchText: '',
      openAddMemberDialog: false,
    };
  }

  public async componentDidMount() {
    const { members } = this.props;
    if (!members) {
      this.props.listAccountMembers();
    }
  }

  onSearchInputChange = (value) => {
    this.setState({
      searchText: value,
    });
  };

  onOpenAddMemberDialog = () => {
    this.setState({ openAddMemberDialog: true });
  };

  onCloseAddMemberDialog = (isRefersh?: boolean) => {
    this.setState({ openAddMemberDialog: false });
    if (isRefersh) {
      this.props.listAccountMembers();
    }
  };

  public render() {
    const { classes, t, isLoading, members = [], account } = this.props;
    const { searchText, openAddMemberDialog } = this.state;

    const items: Array<Item> = members
      .filter((member) => member.name.includes(searchText) || member.email.includes(searchText))
      .sort((a, b) => Number(b.hasProfile) - Number(a.hasProfile))
      .map((member, index) => ({
        id: `${member.uid}-${index}`,
        uid: member.uid,
        hasProfile: member.hasProfile,
        name: member.name,
        email: member.email,
        role: member.role,
        isPrimaryOwner: member.isPrimaryOwner ? member.isPrimaryOwner : false,
        createAt: new Date(member.createAt),
        lastloginAt: new Date(member.lastloginAt),
      }));

    const columns: GridColumns = [
      {
        field: DataGridFieldEnum.NAME,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        headerName: t('display_name'),
        renderCell: (params: GridCellParams) => {
          const { hasProfile, displayName } = params.row;
          return hasProfile ? (
            displayName
          ) : (
            <div className={classes.memberInactive}>{t('unknown_member')}</div>
          );
        },
        width: 200,
      },
      {
        field: DataGridFieldEnum.EMAIL,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        headerName: t('contact_email'),
        width: 250,
      },
      {
        field: DataGridFieldEnum.ROLE,
        disableColumnMenu: true,
        sortable: false,
        filterable: true,
        headerName: t('role'),
        width: 200,
        renderCell: (params: GridCellParams) => {
          const { role, isPrimaryOwner } = params.row;
          return (
            <>
              {role}
              {Boolean(isPrimaryOwner) ? '(primary)' : ''}
            </>
          );
        },
        type: 'singleSelect',
        valueOptions: ITEMS,
      },
      {
        field: DataGridFieldEnum.CREATED_AT,
        sortable: true,
        filterable: false,
        headerName: t('create_date'),
        valueFormatter: ({ value }) => t('create_date_value', { date: value }),
        width: 150,
      },
      {
        field: DataGridFieldEnum.LAST_LOGIN_AT,
        sortable: true,
        filterable: false,
        headerName: t('last_login'),
        valueFormatter: ({ value }) => t('create_date_value', { date: value }),
        width: 150,
      },
      {
        field: DataGridFieldEnum.ACTION,
        type: 'actions',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        headerName: t('action'),
        headerClassName: classes.cellAction,
        width: 200,
        align: 'left',
        headerAlign: 'left',
        renderCell: (params: GridCellParams) => {
          return (
            <AccountMemberOption
              account={account}
              member={params.row as any}
              listAccountMembers={this.props.listAccountMembers}
            />
          );
        },
      },
    ];

    return (
      <div>
        <div className={classes.topLine}>
          <Button
            id="admin-user-add-button"
            variant="contained"
            color="primary"
            className={classes.addBtn}
            startIcon={<AddIcon />}
            onClick={this.onOpenAddMemberDialog}
          >
            {t('add_user')}
          </Button>
          <AddAccountMemberDialog
            account={account}
            open={openAddMemberDialog}
            onClose={this.onCloseAddMemberDialog}
          />
        </div>
        <div className={classes.dataGridWrapper}>
          <DataGrid
            isRowSelectable={() => false}
            rows={items}
            columns={columns}
            classes={{ root: classes.gridRoot }}
            components={{
              Toolbar: CustomDataGridToolbar,
            }}
            componentsProps={{
              toolbar: {
                value: searchText,
                isQuickSearch: true,
                placeholder: t('search_account_member_placeholder'),
                onChange: (event) => this.onSearchInputChange(event.target.value),
                clearSearch: () => this.onSearchInputChange(''),
              },
            }}
            localeText={{
              toolbarColumns: t('data_grid.columns'),
              toolbarFilters: t('data_grid.filters'),
              toolbarDensity: t('data_grid.density'),
              filterPanelColumns: t('data_grid.columns'),
              filterPanelOperators: t('data_grid.operators'),
              columnsPanelTextFieldLabel: t('data_grid.find_column'),
              columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
              columnsPanelDragIconLabel: t('data_grid.reorder_column'),
              columnsPanelShowAllButton: t('data_grid.show_all'),
              columnsPanelHideAllButton: t('data_grid.hide_all'),
              toolbarDensityCompact: t('data_grid.density_compact'),
              toolbarDensityStandard: t('data_grid.density_standard'),
              toolbarDensityComfortable: t('data_grid.density_comfortable'),
              filterOperatorContains: t('data_grid.contains'),
              filterOperatorEquals: t('data_grid.equals'),
              filterOperatorStartsWith: t('data_grid.startsWith'),
              filterOperatorEndsWith: t('data_grid.endsWith'),
              filterOperatorIsEmpty: t('data_grid.isEmpty'),
              filterOperatorIsNotEmpty: t('data_grid.isNotEmpty'),
              filterOperatorIsAnyOf: t('data_grid.isAnyOf'),
              filterOperatorIs: t('data_grid.is'),
              filterOperatorNot: t('data_grid.isNot'),
              noRowsLabel: t('data_grid.no_rows'),
            }}
            loading={isLoading}
          />
        </div>
      </div>
    );
  }
}

const styles = (theme) =>
  createStyles({
    root: {},
    topLine: {
      padding: '20px 20px 10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
    },
    dataGridWrapper: {
      height: 500,
      width: '100%',
      backgroundColor: whiteColor,
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(0.5),
      },
    },
    cellAction: {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
    },
    gridRoot: {
      border: 'none',
      '& .MuiDataGrid-cell': {
        whiteSpace: 'pre-wrap',
        maxHeight: 'none !important',
        outline: 'none !important',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    memberInactive: {
      color: romanColor,
    },
    addBtn: {
      margin: 0,
    },
  });

export default withStyles(styles)(withTranslation('admin')(withRouter(AccountDetailMembers)));

type Item = {
  id: string;
  uid: string;
  name: string;
  email: string;
  role: string;
  isPrimaryOwner: boolean;
  hasProfile: boolean;
  createAt: Date;
  lastloginAt: Date;
};

enum DataGridFieldEnum {
  NAME = 'name',
  ID = 'id',
  EMAIL = 'email',
  ROLE = 'role',
  CREATED_AT = 'createAt',
  LAST_LOGIN_AT = 'lastloginAt',
  ACTION = 'action',
}

const ITEMS: Array<{ label: string; value: AccountRoleType }> = [
  { label: 'Owner', value: 'owner' },
  { label: 'Admin', value: 'admin' },
  { label: 'Member', value: 'member' },
  { label: 'Guest', value: 'guest' },
];
