import React from 'react';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import { IAccountSummary } from '~/types/admin-types';
import { defaultFont } from '~/styles/themes/common-styles/font';
import { pattensBlueColor } from '~/styles/themes/common-styles/color';
// components
import EditCouponDialog from '../edit-coupon-dialog/index';
import OptionButton from '~/components/common/option-button';
import ListItem from '@mui/material/ListItem';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';

export type Item = {
  id: string;
  createAt: string;
  name: string;
  expiredAt: string;
  balance: number;
};

interface Props extends WithStyles<typeof styles>, WithTranslation {
  coupon: Item;
  account: IAccountSummary;
}

interface DispProps {}

interface IState {
  openEditCouponDialog: boolean;
  networkOptionAnchorEl: HTMLButtonElement | null;
}

class CouponOption extends React.Component<Props & DispProps, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openEditCouponDialog: false,
      networkOptionAnchorEl: null,
    };
  }

  public render() {
    return (
      <>
        {this.couponOptionPopoverRender}
        <OptionButton id="coupon-button-menu" onClick={this.onOpenCouponOptionPopover} />
      </>
    );
  }

  get couponOptionPopoverRender() {
    const { classes, t, coupon, account } = this.props;
    const { networkOptionAnchorEl, openEditCouponDialog } = this.state;
    return (
      <>
        <EditCouponDialog
          coupon={coupon}
          account={account}
          open={openEditCouponDialog}
          onClose={this.onCloseEditCouponDialog}
        />
        <Popover
          open={Boolean(networkOptionAnchorEl)}
          anchorEl={networkOptionAnchorEl}
          onClose={this.onCloseCouponOptionPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={1}
        >
          <List>
            <ListItem button className={classes.listOptionItem} onClick={this.inviteToNetwork}>
              {this.props.t('edit')}
            </ListItem>
          </List>
        </Popover>
      </>
    );
  }

  private onCloseCouponOptionPopover = () => {
    this.setState({ networkOptionAnchorEl: null });
  };
  private onOpenCouponOptionPopover = (e) => {
    this.setState({ networkOptionAnchorEl: e.currentTarget });
  };

  private inviteToNetwork = () => {
    this.onOpenEditCouponDialog();
    this.onCloseCouponOptionPopover();
  };

  private onOpenEditCouponDialog = () => {
    this.setState({ openEditCouponDialog: true });
  };
  private onCloseEditCouponDialog = () => {
    this.setState({ openEditCouponDialog: false });
  };
}

const styles = createStyles({
  root: {},
  couponName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  listOptionItem: {
    height: 46,
    width: 210,
    ...defaultFont,
    fontSize: 16,
  },
  horizontalSeparate: {
    height: 1,
    width: '100%',
    backgroundColor: pattensBlueColor,
  },
});

export default withStyles(styles)(withTranslation('admin')(CouponOption));
