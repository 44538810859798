import React from 'react';
// Component
import Button from '@mui/material/Button';
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/custom-submit-button';
import CustomSelect from '~/components/common/custom-select';
import CustomInput from '~/components/common/custom-input';
// Store
import { useDispatch, useSelector } from 'react-redux';
import * as AccountActions from '~/stores/actions/account-action';
import { IStore } from '~/stores/configure-store';
// Form
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import classNames from 'classnames';
import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';
import { romanColor, dimGrayColor, whiteSmokeColor } from '~/styles/themes/common-styles/color';
// React i18next
import { Trans, useTranslation } from 'react-i18next';
import { AccountRoleType, IAccountSummary } from '~/types/admin-types';

interface IProps extends WithStyles<typeof styles> {
  account: IAccountSummary;
  onClose: (isRefersh?: boolean) => void;
  open: boolean;
}

type FormValues = {
  email: string;
  accountMemberRole: string;
};

const AddUserDialog = (props: IProps) => {
  const { classes, open, account, onClose } = props;
  const isCreating = useSelector((store: IStore) =>
    AccountActions.addAccountMember.isPending(store),
  );
  const { t } = useTranslation(['admin']);
  const dispatch = useDispatch();

  const initialValues: FormValues = {
    email: '',
    accountMemberRole: '',
  };

  const validateSchema = Yup.object().shape<FormValues>({
    email: Yup.string().required(t('required')).email(t('invalid_email')),
    accountMemberRole: Yup.string().required(t('required')),
  });

  const emailField = ({ field, form }: FieldProps<FormValues>) => {
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>
            <Trans i18nKey={'email'} />
          </div>
          {!!form.errors.email && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.email}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            id="member-account-members-add-input-email"
            placeholder={t('input_email_address')}
          />
        </div>
      </>
    );
  };

  const accountMemberRoleField = ({ field, form }: FieldProps<FormValues>) => {
    const options: { value: AccountRoleType; label: string }[] = [
      { value: 'owner', label: 'Owner' },
      { value: 'admin', label: 'Admin' },
      { value: 'member', label: 'Member' },
      { value: 'guest', label: 'Guest' },
    ];

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>
            <Trans i18nKey={'organization_role'} />
          </div>
          {!!form.errors.accountMemberRole && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.accountMemberRole}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            id="member-account-members-add-input-role"
            valueSelected={field.value}
            placeholder={t('select_role')}
            items={options}
          />
        </div>
      </>
    );
  };

  const onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { setSubmitting } = formikActions;

    try {
      await dispatch(
        AccountActions.addAccountMember({
          input: {
            accountUuid: account.accountUuid,
            email: values.email,
            accountRole: values.accountMemberRole as AccountRoleType,
          },
        }),
      );
      onClose(true);
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <CustomDialog open={open} onClose={() => onClose()} classes={{ paper: classes.dialogPaper }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validateSchema}
        onSubmit={onSubmit}
        render={({ isValid, isSubmitting }) => (
          <Form>
            <CustomDialogTitle>
              <div id="member-account-members-add-title-area">{t('add_member')}</div>
            </CustomDialogTitle>
            <CustomDialogContent>
              <div title="email-field">
                <Field name="email" render={emailField} />
              </div>
              <div className={classes.formSection} title="account-role-field">
                <Field name="accountMemberRole" render={accountMemberRoleField} />
              </div>
            </CustomDialogContent>
            <CustomDialogActions>
              <Button
                id="member-account-members-add-submit-cancel"
                disabled={isSubmitting || isCreating}
                className={classes.leftBtn}
                variant="contained"
                onClick={() => onClose()}
              >
                {t('cancel')}
              </Button>
              <SubmitButton
                id="member-account-members-add-submit-add"
                type="submit"
                disabled={!isValid}
                isLoading={isSubmitting || isCreating}
                label={t('create')}
                submittingLabel={t('creating')}
              />
            </CustomDialogActions>
          </Form>
        )}
      />
    </CustomDialog>
  );
};

const styles = createStyles({
  root: {},
  dialogPaper: {
    minWidth: 620,
  },
  dialogTitle: {
    paddingBottom: 25,
  },
  // Form Field
  formSection: {
    marginTop: 15,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  // Submit Button
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

export default withStyles(styles)(AddUserDialog);
