import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFont } from '~/styles/themes/common-styles/font';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
import * as SummaryActions from '~/stores/actions/summary-action';
import { AppRoutePath } from '~/AppRouter';
// Component
import Layout from '../common/Layout';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import Typography from '@mui/material/Typography';
import LoadingIcon from '~/components/common/loading-icon';
import NetworkDetailOverview from './overview-tab';
import NetworkDetailNodes from './nodes-tab';
import NetworkDetailProposals from './proposal-tab';
import NetworkDetailProposalHistory from './proposal-history-tab';
import NetworkDetailDebug from './debug-tab';
import OrganizationTab from './organization-tab';
// Styles
import {
  whiteColor,
  blackRussianColor,
  gainsboroColor,
  dodgerBlue4Color,
  dimGrayColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

import { compose } from 'redux';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withAdminProfile } from '~/hooks/with-admin-profile';
import { withAuthorization } from '~/hooks/with-authorization';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { NETWORK_DETAIL_DIALOG_POLLING_TIMEOUT } from '~/constants/polling';

interface IProps extends WithStyles<typeof styles> {}

const LinkTab = (props) => <Tab component={RouterLink} {...props} />;

const NetworkDetail = (props: IProps) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('admin');
  const params = useParams<{ networkUuid: string; tab: string }>();

  const profile = useSelector((store: IStore) => store.appState.profile);
  const networkSummary = useSelector((store: IStore) => store.appState.networkSummary);
  const [isLoadingNetwork, setLoadingNetwork] = useState(false);

  const role = profile?.role || '';
  const showInfo = ['owner', 'admin', 'operator'].includes(role);
  const showNodes = ['owner', 'admin', 'operator'].includes(role);
  const showProposals = ['owner', 'admin', 'operator'].includes(role);
  const showProposalHistory = ['owner', 'admin', 'operator'].includes(role);
  const showOrganization = ['owner', 'admin', 'operator'].includes(role);
  const showDebug = ['owner', 'admin', 'operator'].includes(role);

  const { pathname } = location;
  const { networkUuid, tab } = params;

  const tabs: Array<{ label: string; tabName: string; url: string; component: JSX.Element }> =
    useMemo(() => {
      const _tabs: Array<{ label: string; tabName: string; url: string; component: JSX.Element }> =
        [];
      if (networkSummary && !isLoadingNetwork) {
        if (showInfo) {
          _tabs.push({
            label: t('overview'),
            tabName: 'overview',
            url: `/networks/${networkUuid}/overview`,
            component: <NetworkDetailOverview summary={networkSummary} />,
          });
        }
        if (showOrganization) {
          _tabs.push({
            label: t('organizations'),
            tabName: 'organizations',
            url: `/networks/${networkUuid}/organizations`,
            component: <OrganizationTab summary={networkSummary} />,
          });
        }
        if (showNodes) {
          _tabs.push({
            label: t('nodes'),
            tabName: 'nodes',
            url: `/networks/${networkUuid}/nodes`,
            component: <NetworkDetailNodes summary={networkSummary} />,
          });
        }
        if (showProposals) {
          _tabs.push({
            label: t('proposals'),
            tabName: 'proposals',
            url: `/networks/${networkUuid}/proposals`,
            component: <NetworkDetailProposals summary={networkSummary} />,
          });
        }
        if (showProposalHistory) {
          _tabs.push({
            label: t('proposal_history'),
            tabName: 'proposal-history',
            url: `/networks/${networkUuid}/proposal-history`,
            component: <NetworkDetailProposalHistory summary={networkSummary} />,
          });
        }
        if (showDebug) {
          _tabs.push({
            label: t('debug'),
            tabName: 'debug',
            url: `/networks/${networkUuid}/debug`,
            component: <NetworkDetailDebug summary={networkSummary} />,
          });
        }
      }
      return _tabs;
    }, [
      isLoadingNetwork,
      networkSummary,
      networkUuid,
      showDebug,
      showInfo,
      showNodes,
      showProposalHistory,
      showProposals,
      showOrganization,
      t,
    ]);

  const tabValue = tabs
    .map((tab, index) => tab.tabName)
    .findIndex((tabName) => pathname.includes(tabName));

  useEffect(() => {
    if (networkUuid) {
      (async () => {
        try {
          setLoadingNetwork(true);
          await dispatch(SummaryActions.getNetworkSummary({ networkUuid }));
        } catch (error) {
        } finally {
          setLoadingNetwork(false);
        }
      })();
    }
    const interval = setInterval(async () => {
      dispatch(SummaryActions.getNetworkSummary({ networkUuid }));
    }, NETWORK_DETAIL_DIALOG_POLLING_TIMEOUT);

    return () => clearInterval(interval);
  }, [dispatch, networkUuid]);

  return (
    <Layout>
      <div className={classes.root}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Link
            component={RouterLink}
            to={AppRoutePath.Dashboard}
            color="text.primary"
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          </Link>
          <Link
            component={RouterLink}
            to={AppRoutePath.Networks}
            color="text.primary"
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {t('networks')}
          </Link>
          <Typography color="text.primary">{networkUuid ? `ID: ${networkUuid}` : ''}</Typography>
        </Breadcrumbs>
        <div className={classes.contentPaper}>
          {isLoadingNetwork && (
            <div className={classes.loadingArea}>
              <LoadingIcon />
            </div>
          )}
          {!isLoadingNetwork && !networkSummary && <div>Network doesn't exist</div>}
          {!!tabs.length && (
            <>
              <Tabs
                id="admin-network-tab"
                value={tabValue}
                indicatorColor="primary"
                classes={{
                  root: classes.tabsRoot,
                  indicator: classes.tabIndicator,
                }}
                textColor="primary"
              >
                {tabs.map((val, index) => (
                  <LinkTab
                    key={index}
                    classes={{ root: classes.tabCustom, selected: classes.tabSelected }}
                    label={val.label}
                    to={val.url}
                  />
                ))}
              </Tabs>
              {tabs.map((tab, index) => {
                if (!pathname.includes(tab.tabName)) {
                  return null;
                }
                return <div key={index}>{tab.component}</div>;
              })}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

const styles = createStyles({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 'auto',
    paddingRight: 0,
  },
  loadingArea: {
    margin: '100px 0',
    textAlign: 'center',
  },
  headerLabel: {
    color: `${dimGrayColor} !important`,
  },
  tabsRoot: {
    borderBottom: `1px solid ${gainsboroColor}`,
  },
  tabIndicator: {
    background: dodgerBlue4Color,
  },
  tabSelected: {
    color: `${dodgerBlue4Color} !important`,
  },
  tabCustom: {
    ...defaultFont,
    textTransform: 'none',
    fontSize: 17,
    minWidth: '145px !important',
    '@media (min-width: 576px)': {
      minWidth: '125px !important',
    },
  },
  contentPaper: {
    marginTop: 24,
    boxShadow: `0 2px 6px 0 ${blackRussianColor}`,
    border: `solid 1px ${gainsboroColor}`,
    borderRadius: 4,
    backgroundColor: whiteColor,
  },
});

export default compose(
  withFirebaseUser(),
  withAdminProfile,
  withAuthorization(['owner', 'admin', 'operator']),
  withStyles(styles),
)(NetworkDetail);
