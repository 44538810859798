import { INodeUnit } from '~/types/admin-types';
import { INodeStatus, INodeDisplay, INode, IExplorer, Proposal } from '~/types/network-types';
import { displayCurrency } from './payment-utils';
import { TFunction } from 'i18next';
import { parseBCP47String } from '~/utilities/utils';

export const displayNodeType = (node: INode | INodeUnit, proposalList: Proposal[]) => {
  let nodeTypestatus = node.nodeInfo.signer ? 'Validator Node' : 'Relay Node';

  if (proposalList) {
    const proposalExist = proposalList.filter(
      (proposal) => proposal.targetNodeUuid === node.nodeUuid,
    );

    if (proposalExist.length && proposalExist[0].status === 'proposed') {
      nodeTypestatus =
        nodeTypestatus === 'Validator Node'
          ? 'Validator Node >> Relay Node'
          : 'Relay Node >> Validator Node';
    } else if (
      proposalExist.length &&
      proposalExist[0].status === 'approved' &&
      !proposalExist[0].isValidator
    ) {
      nodeTypestatus =
        nodeTypestatus === 'Validator Node' ? 'Validator Node >> Relay Node' : nodeTypestatus;
    } else if (
      proposalExist.length &&
      proposalExist[0].status === 'approved' &&
      proposalExist[0].isValidator
    ) {
      nodeTypestatus =
        nodeTypestatus === 'Relay Node' ? 'Relay Node >> Validator Node' : nodeTypestatus;
    }
  }
  return nodeTypestatus;
};

export const displayNodeStatus = (node: INode) => {
  const { status } = node.serverInfo;
  const stateStrMap: { [key in INodeStatus]: INodeDisplay } = {
    alive: 'running',
    pending: 'pending',
    removing: 'removing',
    dead: 'stopped',
    failure: 'error',
  };
  let result: INodeDisplay = 'unknown';

  // first, chech server state
  switch (status) {
    case 'pending':
    case 'dead':
    case 'failure':
    case 'removing':
      result = stateStrMap[status];
      break;
    case 'alive':
      // next, check node state
      const { status: nodeStatus } = node.nodeInfo;

      if (nodeStatus === 'alive') {
        const disk = displayServerDiskUsage(node);
        result = disk < 95 ? stateStrMap[nodeStatus] : 'x_warn_disk';
      } else if (stateStrMap[nodeStatus]) {
        result = stateStrMap[nodeStatus];
      }
      break;
    default:
      break;
  }
  return result;
};

export const displayNodeStatusAdmin = (node: INodeUnit) => {
  const { status } = node.serverInfo;
  const stateStrMap: { [key in INodeStatus]: INodeDisplay } = {
    alive: 'running',
    pending: 'pending',
    removing: 'removing',
    dead: 'stopped',
    failure: 'error',
  };
  let result: INodeDisplay = 'unknown';

  // first, chech server state
  switch (status) {
    case 'pending':
    case 'dead':
    case 'failure':
    case 'removing':
      result = stateStrMap[status];
      break;
    case 'alive':
      // next, check node state
      const { status: nodeStatus } = node.nodeInfo;

      if (nodeStatus === 'alive') {
        const disk = displayServerDiskUsage(node);
        result = disk < 95 ? stateStrMap[nodeStatus] : 'x_warn_disk';
      } else if (stateStrMap[nodeStatus]) {
        result = stateStrMap[nodeStatus];
      }
      break;
    default:
      break;
  }
  return result;
};

export const displayNodeStatusDetail = (node: INode, trans: TFunction, force: boolean = false) => {
  const target = node.serverInfo.status === 'alive' ? 'node' : 'server';
  const status = displayNodeStatus(node);

  let result = '';

  if (status.substr(0, 2) === 'x_') {
    // custom status
    result = trans(status);
  } else if (status !== 'running') {
    result = `${target} ${status}`;
  } else if (status === 'running' && force) {
    result = `${target} ${status}`;
  }
  return result;
};

export const sortNodesByName = (pre: INode, cur: INode) => {
  let order: number = 0;
  if (pre.nodeName < cur.nodeName) {
    order = -1;
  } else if (pre.nodeName > cur.nodeName) {
    order = 1;
  }
  return order;
};

export const sortNodesByNameAdmin = (pre: INodeUnit, cur: INodeUnit) => {
  let order: number = 0;
  if (pre.nodeName < cur.nodeName) {
    order = -1;
  } else if (pre.nodeName > cur.nodeName) {
    order = 1;
  }
  return order;
};

const clusterProviderNames: { [key: string]: string } = {
  amazon: 'Amazon Web Services',
};

export const displayClusterProviderName = (text: string) => {
  return clusterProviderNames[text] || 'Unknown';
};

export const displayNumberSign = (target: number) => {
  if (target === 0) {
    return '';
  }
  return target > 0 ? '+' : '-';
};

export const displayServerDiskUsage = (node: INode | INodeUnit | IExplorer) => {
  const { volumeSize, volumeUsed } = node.serverInfo;
  let diskUsedRate = 0;

  if (volumeSize && volumeSize > 0 && volumeUsed && volumeUsed > 0) {
    diskUsedRate = Math.floor((volumeUsed * 100) / volumeSize);
  }
  return diskUsedRate;
};

export const renderAccountBalance = (balance) =>
  balance === 0 ? '0' : `${balance < 0 ? '-' : ''}$${displayCurrency(Math.abs(balance))}`;

export const renderDocumentLink = (language: string) =>
  parseBCP47String(language).language === 'ja'
    ? 'https://docs.bccloud.net/v/japanese'
    : 'https://docs.bccloud.net';
