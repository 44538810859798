import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IStore } from '~/stores/configure-store';
import Layout from '~/components/common/Layout';
import Breadcrumbs from '@mui/material/Breadcrumbs';

// components
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import { Button, Typography } from '@mui/material';
import AddUserDialog from '~/components/common/add-user-dialog';
import AdminUserOption from './admin-user-options';

import * as AccountActions from '~/stores/actions/account-action';
import { IAdminUser, IAdminProfile } from '~/types/admin-types';
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';
import CustomDataGridToolbar from '~/components/common/custom-data-grid-toolbar';
import { ROWS_PER_PAGE_OPTIONS } from '~/constants/common';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withAdminProfile } from '~/hooks/with-admin-profile';
import { compose } from 'redux';
import { AppRoutePath } from '~/AppRouter';
import { AdminRoleEnum } from '~/gapi/gtypes';
import { withAuthorization } from '~/hooks/with-authorization';
import { whiteColor } from '~/styles/themes/common-styles/color';

interface StateProps {
  administrators: IAdminUser[];
  isLoading: boolean;
  profile?: IAdminProfile;
}

interface DispProps {
  listAdminUsers: () => Promise<AccountActions.LIST_ADMIN_USERS_RESULT_TYPE>;
}

interface Props
  extends StateProps,
    DispProps,
    RouteComponentProps<{}>,
    WithTranslation,
    WithStyles<typeof styles> {}

interface State {
  openAddUserDialog: boolean;
}

class AdminUsers extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      openAddUserDialog: false,
    };
  }

  componentDidMount() {
    this.props.listAdminUsers().catch(console.log);
  }

  public render() {
    const { classes, t, administrators, isLoading, profile } = this.props;
    const { openAddUserDialog } = this.state;

    const columns: GridColumns = [
      {
        field: DataGridFieldEnum.EMAIL,
        disableColumnMenu: true,
        filterable: true,
        sortable: false,
        headerName: t('email'),
        width: 300,
      },
      {
        field: DataGridFieldEnum.USER_NAME,
        disableColumnMenu: true,
        sortable: false,
        filterable: true,
        headerName: t('user_name'),
        width: 200,
      },
      {
        field: DataGridFieldEnum.ROLE,
        disableColumnMenu: true,
        sortable: false,
        filterable: true,
        headerName: t('role'),
        valueFormatter: ({ value }) => t(value),
        width: 150,
      },
      {
        field: DataGridFieldEnum.CREATED_AT,
        sortable: false,
        filterable: false,
        headerName: t('created'),
        valueFormatter: ({ value }) => t('user_createAt', { date: value }),
        width: 250,
      },
      {
        field: DataGridFieldEnum.ACTION,
        type: 'actions',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        headerName: t('action'),
        headerClassName: classes.cellAction,
        width: 200,
        align: 'left',
        headerAlign: 'left',
        renderCell: (params: GridCellParams) => {
          const { adminUuid, role } = params.row;
          const showOption =
            profile &&
            profile.adminUuid !== adminUuid &&
            (profile.role === 'owner' || !['owner', 'admin'].includes(role));
          return showOption && <AdminUserOption user={params.row as any} />;
        },
      },
    ];

    const items: Array<Item> = (administrators || [])
      .map((user) => ({
        email: user.email,
        id: user.adminUuid,
        adminUuid: user.adminUuid,
        name: user.name,
        role: user.role,
        createAt: user.createAt ? new Date(user.createAt) : undefined,
      }))
      .sort((a, b) => {
        const roleOrder: Record<AdminRoleEnum, number> = {
          owner: 0,
          admin: 1,
          operator: 2,
          billing_operator: 3,
        };
        return roleOrder[a.role] - roleOrder[b.role];
      });

    return (
      <Layout>
        <div className={classes.root}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Link
              component={RouterLink}
              to={AppRoutePath.Dashboard}
              color="text.primary"
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            </Link>
            <Typography color="text.primary">{t('admin_users')}</Typography>
          </Breadcrumbs>
          <div className={classes.topLine}>
            <Button
              id="admin-user-add-button"
              variant="contained"
              color="primary"
              className={classes.addBtn}
              startIcon={<AddIcon />}
              onClick={this.onOpenAddUserDialog}
            >
              {t('add_user')}
            </Button>
            <AddUserDialog open={openAddUserDialog} onClose={this.onCloseAddUserDialog} />
          </div>
          <div className={classes.dataGridWrapper}>
            <DataGrid
              rows={items}
              columns={columns}
              components={{
                Toolbar: CustomDataGridToolbar,
              }}
              localeText={{
                toolbarColumns: t('data_grid.columns'),
                toolbarFilters: t('data_grid.filters'),
                toolbarDensity: t('data_grid.density'),
                filterPanelColumns: t('data_grid.columns'),
                filterPanelOperators: t('data_grid.operators'),
                columnsPanelTextFieldLabel: t('data_grid.find_column'),
                columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
                columnsPanelDragIconLabel: t('data_grid.reorder_column'),
                columnsPanelShowAllButton: t('data_grid.show_all'),
                columnsPanelHideAllButton: t('data_grid.hide_all'),
                toolbarDensityCompact: t('data_grid.density_compact'),
                toolbarDensityStandard: t('data_grid.density_standard'),
                toolbarDensityComfortable: t('data_grid.density_comfortable'),
                filterOperatorContains: t('data_grid.contains'),
                filterOperatorEquals: t('data_grid.equals'),
                filterOperatorStartsWith: t('data_grid.startsWith'),
                filterOperatorEndsWith: t('data_grid.endsWith'),
                filterOperatorIsEmpty: t('data_grid.isEmpty'),
                filterOperatorIsNotEmpty: t('data_grid.isNotEmpty'),
                filterOperatorIsAnyOf: t('data_grid.isAnyOf'),
                filterOperatorIs: t('data_grid.is'),
                filterOperatorNot: t('data_grid.isNot'),
                noRowsLabel: t('data_grid.no_rows'),
              }}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              isRowSelectable={() => false}
              disableSelectionOnClick
              checkboxSelection={false}
              loading={isLoading}
              filterMode="server"
            />
          </div>
        </div>
      </Layout>
    );
  }

  private onOpenAddUserDialog = () => {
    this.setState({
      openAddUserDialog: true,
    });
  };

  private onCloseAddUserDialog = () => {
    this.setState({
      openAddUserDialog: false,
    });
  };
}

const styles = (theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: 'auto',
      paddingRight: 0,
    },
    cellAction: {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
    },
    dataGridWrapper: {
      height: 500,
      width: '100%',
      marginTop: 24,
      backgroundColor: whiteColor,
      [theme.breakpoints.up('sm')]: {
        marginTop: 32,
      },
    },
    topLine: {
      marginTop: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
    },
    addBtn: {
      margin: 0,
    },
  });

const mapStateToProps = (store: IStore): StateProps => ({
  administrators: store.appState.administrators,
  profile: store.appState.profile,
  isLoading: AccountActions.listAdminUsers.isPending(store),
});

const mapDispatchToProps = (dispatch): DispProps => ({
  listAdminUsers: () => dispatch(AccountActions.listAdminUsers()),
});

export default compose(
  withFirebaseUser(),
  withAdminProfile,
  withAuthorization(['owner', 'admin']),
  withStyles(styles),
  withTranslation('admin'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AdminUsers);

type Item = {
  email: string;
  id: string;
  name: string;
  adminUuid: string;
  role: string;
  createAt?: Date;
};

enum DataGridFieldEnum {
  EMAIL = 'email',
  ID = 'id',
  USER_NAME = 'name',
  ROLE = 'role',
  ACTION = 'action',
  CREATED_AT = 'createAt',
}
