import React from 'react';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import TableBodyCellCustom from '~/components/common/table-body-cell';
import TableBodyRowCustom from '~/components/common/table-body-row';
import NodeStatusIcon from '~/components/common/node-status-icon';
import DeleteNodeDialog from '../delete-node-dialog';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ImgIcon from '~/components/common/img-icon';
import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  lightSlateGreyColor,
  nightRiderColor,
  pattensBlueColor,
} from '~/styles/themes/common-styles/color';
import { Item } from '..';
import ConfirmRebuildNodeDialog from '../confirm-rebuild-node-dialog';
import UpdateNodeVersionDialog from '../change-node-version-dialog';
import UpdateGasLimitDialog from '../update-gas-limit-dialog';
import UpdateHardForkDialog from '../update-hard-fork-dialog';
import CircularProgress from '@mui/material/CircularProgress';
import SourceIcon from '@mui/icons-material/Source';
import GenesisJsonViewDialog from '~/components/common/genesis-json-dialog';
// Redux
import * as NetworkActions from '~/stores/actions/network-action';
import * as AppActions from '~/stores/actions/app-action';
import { MutationControlNodeArgs, QueryGetGenesisJsonArgs } from '~/gapi/gtypes';
import { CONTROL_NODE_RESULT_TYPE } from '~/stores/actions/network-action';
import { IStore } from '~/stores/configure-store';
import { connect } from 'react-redux';
import { INodeControlType } from '~/types/network-types';
import { IAdminProfile } from '~/types/admin-types';

interface IStateProps {
  isLoadingGetGenesis: boolean;
  profile?: IAdminProfile;
}

interface IDispProps {
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => void;
  controleNode: (args: MutationControlNodeArgs) => Promise<CONTROL_NODE_RESULT_TYPE>;
  getGenesisJson: (
    args: QueryGetGenesisJsonArgs,
  ) => Promise<NetworkActions.GET_GENESIS_JSON_RESULT_TYPE>;
}

interface Props extends IStateProps, WithStyles<typeof styles>, WithTranslation, IDispProps {
  item: Item;
}

interface State {
  menuAnchorEl: HTMLElement | null;
  openDeleteNodeDialog: boolean;
  openConfirmRebuildNodeDialog: boolean;
  openUpdateNodeVersionDialog: boolean;
  openHardForkDialog: boolean;
  openUpdateGasLimitDialog: boolean;
  openGenesisJsonDialog: boolean;
  genesisJson?: string;
}

class NodeTableRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      menuAnchorEl: null,
      openDeleteNodeDialog: false,
      openConfirmRebuildNodeDialog: false,
      openUpdateNodeVersionDialog: false,
      openHardForkDialog: false,
      openUpdateGasLimitDialog: false,
      openGenesisJsonDialog: false,
    };
  }

  public render() {
    const { classes, item, t } = this.props;
    const { summary, cluster, node } = item;
    const {
      openDeleteNodeDialog,
      openConfirmRebuildNodeDialog,
      openUpdateNodeVersionDialog,
      openHardForkDialog,
      openUpdateGasLimitDialog,
      openGenesisJsonDialog,
      genesisJson,
    } = this.state;

    return (
      <>
        {this.renderOptionPopover}
        <DeleteNodeDialog
          nodeDetail={node}
          summary={summary}
          clusterDetail={cluster}
          open={openDeleteNodeDialog}
          onClose={this.onCloseDeleteNodeDialog}
        />
        {openConfirmRebuildNodeDialog && (
          <ConfirmRebuildNodeDialog
            open={openConfirmRebuildNodeDialog}
            cluster={cluster}
            node={node}
            onClose={this.onCloseConfirmRebuildNodeDialog}
            onSubmit={this.onSubmitRebuildNode}
          />
        )}
        {openUpdateNodeVersionDialog && (
          <UpdateNodeVersionDialog
            open={openUpdateNodeVersionDialog}
            onClose={this.onCloseUpdateNodeVersionDialog}
            network={summary.network}
            cluster={cluster}
            node={node}
          />
        )}
        {openUpdateGasLimitDialog && (
          <UpdateGasLimitDialog
            open={openUpdateGasLimitDialog}
            network={summary.network}
            cluster={cluster}
            node={node}
            onClose={this.onCloseUpdateGasLimitDialog}
          />
        )}
        {openHardForkDialog && (
          <UpdateHardForkDialog
            open={openHardForkDialog}
            network={summary.network}
            node={node}
            cluster={cluster}
            onClose={this.onCloseHardForkDialog}
          />
        )}
        {openGenesisJsonDialog && (
          <GenesisJsonViewDialog
            open={openGenesisJsonDialog}
            genesisJson={genesisJson}
            onClose={this.onCloseGenesisJsonDialog}
          />
        )}
        <TableBodyRowCustom>
          <TableBodyCellCustom classes={{ root: classes.tableBodyCellCommon }}>
            {item.clusterName}
          </TableBodyCellCustom>
          <TableBodyCellCustom classes={{ root: classes.tableBodyCellCommon }}>
            {item.nodeName}
          </TableBodyCellCustom>
          <TableBodyCellCustom classes={{ root: classes.tableBodyCellCommon }}>
            {item.networkUuid}
          </TableBodyCellCustom>
          <TableBodyCellCustom classes={{ root: classes.tableBodyCellCommon }}>
            {item.nodeType}
          </TableBodyCellCustom>
          <TableBodyCellCustom classes={{ root: classes.tableBodyCellCommon }}>
            {item.instanceId}
          </TableBodyCellCustom>
          <TableBodyCellCustom classes={{ root: classes.tableBodyCellCommon }}>
            <NodeStatusIcon
              classes={{ root: classes.nodeIcon }}
              status={item.nodeState}
              className={classes.nodeStatusIcon}
            />
            <span>{item.nodeState}</span>
          </TableBodyCellCustom>
          <TableBodyCellCustom classes={{ root: classes.tableBodyCellCommon }}>
            <IconButton onClick={this.onOpenOptionPopover}>
              <ImgIcon className={classes.menuIcon} imgUrl="/images/icons/options-ico.png" />
            </IconButton>
          </TableBodyCellCustom>
        </TableBodyRowCustom>
      </>
    );
  }

  onOpenDeleteNodeDialog = () => {
    this.setState({ openDeleteNodeDialog: true });
  };
  onCloseDeleteNodeDialog = () => {
    this.setState({ openDeleteNodeDialog: false });
  };

  private onOpenConfirmRebuildNodeDialog = () => {
    this.setState({
      openConfirmRebuildNodeDialog: true,
    });
  };

  private onCloseConfirmRebuildNodeDialog = () => {
    this.setState({
      openConfirmRebuildNodeDialog: false,
    });
  };

  private onOpenUpdateNodeVersionDialog = () => {
    this.onCloseOptionPopover();
    this.setState({
      openUpdateNodeVersionDialog: true,
    });
  };

  private onCloseUpdateNodeVersionDialog = () => {
    this.setState({
      openUpdateNodeVersionDialog: false,
    });
  };

  private hardForkNetwork = () => {
    this.onOpenHardForkDialog();
    this.onCloseOptionPopover();
  };

  private onOpenHardForkDialog = () => {
    this.setState({ openHardForkDialog: true });
  };

  private onCloseHardForkDialog = () => {
    this.setState({ openHardForkDialog: false });
  };

  private onOpenUpdateGasLimitDialog = () => {
    this.onCloseOptionPopover();
    this.setState({
      openUpdateGasLimitDialog: true,
    });
  };

  private onCloseUpdateGasLimitDialog = () => {
    this.setState({
      openUpdateGasLimitDialog: false,
    });
  };

  private onSubmitRebuildNode = () => {
    this.onCloseConfirmRebuildNodeDialog();
    this.onSelectControlNodeAction('rebuild');
  };

  private controlNodeReboot = async () => {
    this.onSelectControlNodeAction('reboot');
  };

  private controlNodeStart = async () => {
    this.onSelectControlNodeAction('start');
  };

  private onSelectControlNodeAction = async (action: INodeControlType) => {
    const { controleNode, item } = this.props;
    this.onCloseOptionPopover();

    await controleNode({
      input: {
        action,
        nodeUuids: [item.node.nodeUuid],
        clusterUuid: item.cluster.clusterUuid,
        networkUuid: item.networkUuid,
      },
    });
  };

  private onOpenGenesisJsonDialog = async () => {
    const { item, getGenesisJson } = this.props;
    try {
      const rs = await getGenesisJson({
        networkUuid: item.networkUuid,
        clusterUuid: item.cluster.clusterUuid,
        nodeUuid: item.node.nodeUuid,
      });
      this.setState({ genesisJson: rs.getGenesisJson });
      this.setState({
        openGenesisJsonDialog: true,
      });
    } catch (error) {}
  };

  private onCloseGenesisJsonDialog = () => {
    this.setState({
      openGenesisJsonDialog: false,
    });
  };

  get renderOptionPopover() {
    const { classes, item, isLoadingGetGenesis, profile, t } = this.props;
    const { menuAnchorEl } = this.state;
    const ableDeleteNode = ['owner', 'admin'].includes(profile?.role || '');
    const ableControlNode = ['owner', 'admin', 'operator'].includes(profile?.role || '');

    return (
      <Popover
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={this.onCloseOptionPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <List>
          <ListItem
            id="node-button-del"
            button
            className={classes.listOptionItem}
            onClick={this.onOpenDeleteNodeDialog}
            disabled={!ableDeleteNode}
          >
            <ImgIcon className={classes.deleteIcon} imgUrl="/images/icons/delete_ico.png" />
            {t('delete')}
          </ListItem>
          {item.node.serverInfo.status === 'alive' && (
            <>
              <ListItem
                id="get-genesis-btn"
                button
                className={classes.listOptionItem}
                onClick={this.onOpenGenesisJsonDialog}
                disabled={isLoadingGetGenesis || !ableControlNode}
              >
                {isLoadingGetGenesis ? (
                  <CircularProgress size={16} style={{ color: '#7B90A3', marginRight: '8px' }} />
                ) : (
                  <SourceIcon className={classes.sourceIcon} fontSize="small" />
                )}
                {t('genesis_json')}
              </ListItem>
              <ListItem
                button
                className={classes.listOptionItem}
                onClick={this.onOpenUpdateNodeVersionDialog}
                disabled={!ableControlNode}
              >
                {t('change_node_version')}
              </ListItem>
              <ListItem
                button
                className={classes.listOptionItem}
                onClick={this.hardForkNetwork}
                disabled={!ableControlNode}
              >
                {this.props.t('apply_latest_genesis_config')}
              </ListItem>
              <ListItem
                button
                className={classes.listOptionItem}
                onClick={this.onOpenUpdateGasLimitDialog}
                disabled={!ableControlNode}
              >
                {this.props.t('change_block_gas_limit')}
              </ListItem>
              <div className={classes.horizontalSeparate}></div>
              <ListItem
                id="node-button-rebuild"
                button
                className={classes.listOptionItem}
                onClick={this.onOpenConfirmRebuildNodeDialog}
                disabled={!ableControlNode}
              >
                {t('rebuild')}
              </ListItem>
              <ListItem
                id="node-button-reboot"
                button
                className={classes.listOptionItem}
                onClick={this.controlNodeReboot}
                disabled={!ableControlNode}
              >
                {t('reboot')}
              </ListItem>
            </>
          )}
          {item.node.serverInfo.status === 'dead' && (
            <ListItem
              id="node-button-start"
              button
              className={classes.listOptionItem}
              onClick={this.controlNodeStart}
              disabled={!ableControlNode}
            >
              {t('start')}
            </ListItem>
          )}
        </List>
      </Popover>
    );
  }

  onOpenOptionPopover = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ menuAnchorEl: e.currentTarget });
  };

  onCloseOptionPopover = () => {
    this.setState({ menuAnchorEl: null });
  };
}

const styles = createStyles({
  root: {},
  nodeIcon: {
    marginRight: 7,
  },
  nodeStatusIcon: {
    marginRight: 7,
    verticalAlign: 'middle',
  },
  menuIcon: {},
  tableBodyCellCommon: {
    padding: '4px 0px 4px 2%',
    wordBreak: 'break-all',
  },
  listOptionItem: {
    height: 46,
    width: 250,
    ...defaultFont,
    fontSize: 15,
    color: nightRiderColor,
  },
  sourceIcon: {
    marginRight: 8,
    color: lightSlateGreyColor,
  },
  deleteIcon: {
    marginRight: 8,
  },
  horizontalSeparate: {
    height: 1,
    width: '100%',
    backgroundColor: pattensBlueColor,
  },
});

const mapStateToProps = (store: IStore, ownProps): IStateProps => ({
  isLoadingGetGenesis: NetworkActions.getGenesisJson.isPending(store),
  profile: store.appState.profile,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => dispatch(AppActions.openSnackBar(args)),
  controleNode: (args: MutationControlNodeArgs) => dispatch(NetworkActions.controlNode(args)),
  getGenesisJson: (args: QueryGetGenesisJsonArgs) => dispatch(NetworkActions.getGenesisJson(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation('admin')(NodeTableRow)),
);
