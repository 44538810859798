import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';

import clsx from 'clsx';

import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  whiteSmokeColor,
  pattensBlueColor,
  lightSlateGreyColor,
} from '~/styles/themes/common-styles/color';

interface IItem {
  value: string;
  label: string;
}

interface IProps extends WithStyles<typeof styles> {
  onChange?: any;
  items: IItem[];
  disabled?: boolean;
  placeholder?: string;
  valueSelected?: string;
  className?: string;
  name?: string;
  onBlur?: any;
  id?: string;
}

const CustomSelect: React.FunctionComponent<IProps> = ({
  classes,
  onChange,
  items,
  disabled,
  placeholder,
  valueSelected,
  className,
  name,
  onBlur,
  id,
}) => {
  const arr = items.concat();
  let value = valueSelected;
  const placeHolderItem: IItem = {
    value: 'placeholder',
    label: placeholder || 'Placeholder',
  };
  if (!valueSelected) {
    arr.unshift(placeHolderItem);
    value = placeHolderItem.value;
  }

  return (
    <Paper className={classNames(classes.selectPaper, className)} elevation={0}>
      <Select
        className={classNames({
          [classes.select]: true,
          [classes.placeholderText]: value === placeHolderItem.value,
        })}
        disabled={disabled}
        value={value}
        name={name}
        onBlur={onBlur}
        classes={{
          select: clsx(classes.selectRoot, classes.selectMenu, classes.arrowDownIcon),
        }}
        MenuProps={{
          style: {
            maxHeight: 400,
          },
        }}
        onChange={onChange}
        disableUnderline
        id={id}
      >
        {arr.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            className={classes.menuListItem}
            style={{ display: item.value === placeHolderItem.value ? 'none' : 'inherit' }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </Paper>
  );
};

const styles = createStyles({
  root: {},
  selectRoot: {},
  select: {
    width: '100%',
    height: '100%',
    fontWeight: 'inherit',
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '&>.MuiSelect-select': {
      letterSpacing: 'normal',
    },
  },
  selectPaper: {
    height: '40px',
    width: '100%',
    backgroundColor: whiteSmokeColor,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: pattensBlueColor,
  },
  selectMenu: {
    paddingTop: 9,
    paddingBottom: 8,
    paddingLeft: 15,
    paddingRight: 15,
  },
  placeholderText: {
    ...defaultFont,
    fontSize: 13,
    color: lightSlateGreyColor,
  },
  arrowDownIcon: {
    right: 15,
  },
  menuListItem: {
    height: 'auto',
    '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.14)',
    },
    padding: '11px 16px',
  },
});

export default withStyles(styles)(CustomSelect);
